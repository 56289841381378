import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Stack,
  Menu,
  MenuItem,
  Switch,
  Grid,
  InputBase,
  Autocomplete,
  Divider,
  Pagination,
  PaginationItem,
  useTheme,
  Tooltip,
  Select,
  FormControl,
  Modal,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import WifiOffOutlinedIcon from "@mui/icons-material/WifiOffOutlined";
import PropTypes from "prop-types";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IconButton from "@mui/material/IconButton";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FigmaFilter from "../images/Filter.svg";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { TabsComponent } from "../UiComponents/util/components/Tabs";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import PieChartdata from "../UiComponents/Ticket/PieChartdata";
import BarChartdata from "../UiComponents/Ticket/BarChartdata";
// import Highcharts from 'highcharts';
import TemporaryDrawer from "./FilterDrawer";
import { filterRows } from "./FilterFunction";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Delete } from "@mui/icons-material";
import { FadeLoader } from "react-spinners";
import LoopIcon from "@mui/icons-material/Loop";
import { useLocation, useNavigate } from "react-router-dom";
import { SCBAccordian } from "../UiComponents/EquipmentFilter/SCBAccordian";
import pageNames from "../UiComponents/util/pages.json";
import CustomBreadcrumbs from "../UiComponents/util/components/CustomBread";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
  queryDecrypt,
} from "../UiComponents/util/security/Cipher";
// import { fetchSiteEquipmentFilterTable } from "../Api/EquipmentApi";
import CircularProgress from "@mui/material/CircularProgress";
import {
  extractAndSortString,
  isAnyDown,
} from "../UiComponents/EquipmentFilter/ScbUtils";
import TaskFields from "../UiComponents/TaskManager/taskFields";
import ColumnChart from "../UiComponents/Charts/DoubleBarChart";
import DonutChartRace from "../UiComponents/Charts/DonutChartRace";
import Accordian from "../UiComponents/util/components/Accordian";
import {
  getActivityPermissions,
  removeQuestionMark,
} from "../UiComponents/util/common/CommanFunction";
const TableTemplate = (props) => {
  const {
    keyId,
    uploadButton,
    addButton,
    SearchLabel,
    PageName,
    ExpectedYear,
    tableValues,
    tableToShow,
    header,
    rowsValue,
    handleyearPopupOpen,
    chartValue,
    handleUploadProp,
    handleAddPopupOpen,
    handlefilterPopupOpen,
    handleReportPopupOpen,
    handleclosepopupopen,
    handleEditPopupOpen,
    handledownloaddocument,
    handleDownloadExcel,
    handleDownloadTemplate,
    tabsData,
    handleChange,
    isSiteList,
    datavaluee,
    userRole,
    handleDelete,
    handledocumentdata,
    handleActive,
    handleCategory,
    CategoryValue,
    handleOpenConfigure,
    handleSiteNametoMaster,
    siteDropValue,
    handleEditOpenConfigure,
    handleAssignedPopupOpen,
    handleticketdownloadPopupOpen,
    handleticketdownloadReportPopupOpen,
    handleTicketPopupOpen,
    handleTicketCheckvalue,
    handleEquipmentPopupOpen,
    handleIsReplace,
    paths,
    filterData,
    handlechagevalue,
    barchart,
    filterstatus,
    handleCofigOpen,
    handleuploadPopupOpen,
    handleSiteChangeExpect,
    handleSitedropdownvalue,
    handleSitedropdownvaluee,
    dropData,
    siteNameList,
    equipmentTypeList,
    siteNameListEx,
    statuscall,
    sitevalue,
    typevalue,
    sitevalueEx,
    scbAPIResult,
    overviewData,
    fallbackText,
    handleUploadExcelpopup,
    taskOptions,
    taskHandler,
    rawData,
    fetchSiteEquipmentFilterTable,
    handleSiteFilterChange,
    handleEquipmentTypeChange,
    handleSiteFilterChangeExData,
    selectedSite,
  } = props;
  console.log(sitevalue);
  console.log(typevalue);
  console.log(datavaluee);

  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;

  const [searchInput, setSearchInput] = useState("");
  // const [selectedSite, setSelectedSite] = useState(null);
  console.log(searchInput);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageValue, setPageValue] = React.useState();
  const [siteName, setSiteName] = useState("Select Site");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [SanchorEl, setSAnchorEl] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [expanded, setExpanded] = useState({ open: false, id: "" });
  const [accordData, setAccordData] = useState(null);
  const [accordDataSCB, setAccordDataSCB] = useState(null);
  const [equipCat, setEquipCat] = useState(CategoryValue);
  const [columnVisibility, setColumnVisibility] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trueCount, setTrueCount] = useState(0);
  const [headVal, setHeadVal] = useState(2);
  console.log(headVal);

  const theme = useTheme();
  const [checked, setchecked] = useState();
  const [dgrdata, setdgrvalue] = useState(datavaluee);

  const [checkedState, setCheckedState] = useState({});
  const [rolesString, setRolesString] = useState("");
  const colorMode = theme.palette.mode;
  const label = { inputProps: { "aria-label": "Switch demo" } };

  function isPresent(object, id) {
    return object?.hasOwnProperty(id);
  }

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredTableValues, setFilteredTableValues] = useState(tableValues);

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 2009 },
    (_, i) => currentYear - i
  );

  // useEffect(() => {
  //   const filteredValues = tableValues?.filter(row => {
  //     // Assuming each row has a 'year' property. Adjust this based on your actual data structure.
  //     return row.year === selectedYear;
  //   });
  //   setFilteredTableValues(filteredValues);
  // }, [selectedYear, tableValues]);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(newValue);
    console.log(newValue);
    handleyearPopupOpen(newValue);
  };

  const generateYearOptions = (startYear = 2010) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  // Generate year options from 2010 to the current year
  // const yearOptions = generateYearOptions();

  // Set the default value for the Autocomplete
  const defaultYear =
    ExpectedYear !== "ExpectedData Year"
      ? ExpectedYear
      : yearOptions[yearOptions.length - 1]; // Use ExpectedYear if available; otherwise, use the current year

  console.log(accordData, "accord data");
  function handleTabChange(val) {
    setPage(0);
    handleChange(val);
  }
  useEffect(() => {
    // if (isFromSiteList && addButton === 'Ticket') {
    //   setSearchInput(params.name);
    // }
    if (isSiteList) {
      setRowsPerPage(15);
    }
    setLoading(true);
    setColumnVisibility(
      Object.fromEntries(
        header?.map((headCell) => [headCell.id, headCell.view])
      )
    );
    setLoading(false);
  }, [header, isSiteList, rowsValue, keyId]);

  useEffect(() => {
    const rolesString = queryDecrypt(sessionStorage.getItem("roles"));
    setRolesString(rolesString);
  }, []);
  const rolesArray = rolesString ? JSON.parse(rolesString) : [];

  const HasTicketing = getActivityPermissions(
    rolesArray,
    "ticketConfiguration"
  );

  const TicketIsActive =
    HasTicketing.isPresent && HasTicketing.view && HasTicketing.isActive;

  function naturalCompare(a, b) {
    const splitByNumbers = (str) => {
      return String(str)
        .split(/(\d+)/)
        .map((part) => (isNaN(part) ? part : Number(part)));
    };

    const partsA = splitByNumbers(a);
    const partsB = splitByNumbers(b);

    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        if (partsA[i] === undefined) return -1;
        if (partsB[i] === undefined) return 1;
        if (typeof partsA[i] === typeof partsB[i]) {
          return partsA[i] < partsB[i] ? -1 : 1;
        }
        return typeof partsA[i] < typeof partsB[i] ? -1 : 1;
      }
    }

    return 0;
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] === undefined || a[orderBy] === undefined) {
      return 0;
    }

    const order = naturalCompare(b[orderBy], a[orderBy]);

    if (order !== 0) {
      return order;
    }

    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const toolStyle = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  // let disableTest = ['action', 'firstName', 'emailId', 'customerName', 'stdParameterName', 'stdUom', 'roleName', 'activityName', 'value', 'siteName', 'siteType', 'displayName', 'errorMessage', 'errorType'];
  function getStatusIcon(status) {
    let backgroundColor, textColor;
    switch (status) {
      case "Active":
        backgroundColor = "rgba(11, 223, 42, 0.2)";
        textColor = "#013220";
        break;
      case "Offline":
      case "Inactive":
        backgroundColor = "rgba(96, 61, 47,0.2)";
        // textColor = '#603D2F';
        textColor = "black";
        break;
      case "Warning":
        backgroundColor = "rgba(255, 165, 0, 0.2)";
        textColor = "orange"; // Set the text color to the same color as the background
        break;
      case "Down":
        backgroundColor = "rgba(255, 0, 0, 0.2)";
        textColor = "red"; // Set the text color to the same color as the background
        break;
      default:
        backgroundColor = "rgba(0, 0, 0, 0.2)";
        textColor = "black"; // Set the text color to the same color as the background
        break;
    }
    return { backgroundColor, textColor };
  }
  function getStatusIcons(status) {
    switch (status) {
      case "Active":
        return (
          <CheckCircleOutlineOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Offline":
        return (
          <WifiOffOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Warning":
        return (
          <WarningAmberOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      case "Down":
      case "Inactive":
        return (
          <HighlightOffOutlinedIcon
            style={{
              fontSize: "16px",
              marginRight: "1px",
            }}
          />
        );
      default:
        break;
    }
  }
  const extractEndTime = (downTime) => {
    return downTime.split(",")[1].replace("End: ", "").trim();
  };

  function getStatusIconDark(status) {
    let backgroundColor, textColor;
    switch (status) {
      case "Active":
        backgroundColor = "rgba(114, 225, 40, 0.12)";
        textColor = "rgba(114, 225, 40, 1)"; // Set the text color to the same color as the background
        break;
      case "Inactive":
      case "Offline":
        backgroundColor = "rgba(133, 146, 163, 0.12)";
        textColor = "rgba(133, 146, 163, 1)"; // Set the text color to the same color as the background
        break;
      case "Warning":
        backgroundColor = "rgba(255, 173, 0, 0.12)";
        textColor = "rgba(255, 173, 0, 1)"; // Set the text color to the same color as the background
        break;
      case "Down":
        backgroundColor = "rgba(255, 25, 19, 0.12)";
        textColor = "rgba(255, 25, 19, 1)"; // Set the text color to the same color as the background
        break;
      default:
        backgroundColor = "rgba(0, 0, 0, 0.2)";
        textColor = "black"; // Set the text color to the same color as the background
        break;
    }
    return { backgroundColor, textColor };
  }

  const handleEquipment = (event, value) => {
    handleCategory(event);
    setEquipCat(event);
  };

  /*-------------------------*/

  const handleColumnFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnFilterClose = () => {
    setAnchorEl(null);
  };

  const handleSpecificFilterClick = (event) => {
    setSAnchorEl(true);
  };
  const navigate = useNavigate();
  const handleStatusRedirect = (Id, name) => {
    const dataToSend = { statusId: Id, StatusName: name };
    const queryParams = new URLSearchParams(dataToSend).toString();
    const encryptedQuery = paddedEncryptQuery(queryParams);
    navigate(`/menu/statusMaster?${encryptedQuery}`);
  };

  const handleSpecificFilterClose = () => {
    setSAnchorEl(false);
  };

  const handleToggleAllColumns = () => {
    const allVisible = Object.values(columnVisibility).every(
      (visible) => visible
    );

    setColumnVisibility((prevVisibility) =>
      Object.fromEntries(
        Object.keys(prevVisibility).map((key) => {
          if (
            header.find((column) => column.id === key)?.default &&
            header.find((column) => column.id === key)?.view
          ) {
            return [key, true];
          } else {
            return [key, !allVisible];
          }
        })
      )
    );
  };

  useEffect(() => {
    let trueNums = 0;
    for (const prop in columnVisibility) {
      if (columnVisibility[prop] === true) {
        trueNums++;
      }
    }
    setTrueCount(trueNums);
  }, [columnVisibility]);
  if (header[headVal]?.view === false) {
    setHeadVal((prev) => prev + 1);
  }
  const handleToggleColumn = (columnId) => {
    const isDefaultTrue = header.find(
      (column) => column.id === columnId
    )?.default;

    if (trueCount > 5) {
      if (!isDefaultTrue && columnVisibility[columnId] === false) {
        setColumnVisibility((prevVisibility) => ({
          ...prevVisibility,
          [header[headVal]?.id]: false,
        }));
        setHeadVal((prev) => prev + 1);
      }
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [columnId]: !prevVisibility[columnId],
      }));
    } else {
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [columnId]: !prevVisibility[columnId],
      }));
    }
  };

  const handleSelectiveFilter = (Value) => {
    setSelectedColumns(Value);
  };

  const handleReplceEquip = (row) => {
    handleIsReplace(row);
  };
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <TableRow>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                // headCell.id === 'siteName' ? 'left' :
                "center"
              }
              style={{
                // backgroundColor: "#E8E8EA",
                display: columnVisibility[headCell.id] ? "table-cell" : "none",
                lineHeight: "14.52px",
                color:
                  colorMode === "light" ? "rgba(113, 118, 111, 1)" : "#DBDBEB",
                fontSize: "12px",
                borderBottom: "transparent",
                paddingRight: "0",
                flex: 1,
              }}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {columnVisibility[headCell.id] ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsValue?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const goBack = () => {
    window.history.go(-1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.innerText, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsValue.length) : 0;
  /*----------------Search-----------------*/

  const getDataValues = (rawData) => {
    let FilteredValue;

    if (Object.keys(selectedColumns).length !== 0 || searchInput?.length > 0) {
      if (Object.keys(selectedColumns).length !== 0) {
        FilteredValue = filterRows(rawData, selectedColumns);
      }
      if (searchInput?.length > 0) {
        FilteredValue = rawData?.filter((row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchInput?.toLowerCase())
          )
        );
      }
    } else {
      FilteredValue = rawData?.length > 0 ? [...rawData] : [];
    }

    return FilteredValue;
  };

  const FilteredValue = getDataValues(rowsValue);
  let searchFallBack = "No results found";
  console.log(FilteredValue);

  const handleClickGo = () => {
    const pageNumber = parseInt(pageValue, 10);
    if (!isNaN(pageNumber)) {
      setPage(pageNumber - 1);
    }
  };
  const visibleRows = stableSort(
    FilteredValue,
    getComparator(order, orderBy)
  )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  /*----------------handle Upload --------------------------*/
  //Menu PopOver
  const [popAnchorEl, setPopAnchoEl] = useState(null);
  const handlePopOpen = (e) => setPopAnchoEl(e.currentTarget);
  const handleClose = () => setPopAnchoEl(null);

  const popOpen = Boolean(popAnchorEl);
  const ID = popOpen ? "simple-popover" : undefined;

  const fileInputRef = useRef(null);

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const handleInput = (e) => {
    handleUploadProp(e);
    e.target.value = null;
    handleClose();
  };
  /*----------------handle ADD --------------------------*/

  const handleAdd = () => {
    handleAddPopupOpen(true);
  };
  const handlefilter = () => {
    handlefilterPopupOpen(true);
  };
  const handlereport = () => {
    handleReportPopupOpen(true);
  };
  const handleTicketupload = () => {
    handleuploadPopupOpen(true);
  };

  /*-------------------------Edit popup------------------*/
  const handleEditOpen = (row) => {
    handleEditPopupOpen(row);
    console.log(row);
  };
  const handledownloadddata = (row) => {
    handledownloaddocument(row);
  };

  const handleassigned = (row) => {
    handleAssignedPopupOpen(row);
  };
  // === after demo remove this  ============
  const handledownloaddTicket = (row) => {
    handleticketdownloadPopupOpen(row);
  };
  const handledownloaddReport = (row) => {
    handleticketdownloadReportPopupOpen(row);
  };
  /*-------------------------Delete popup------------------*/
  const handleDeletedata = (row) => {
    handleDelete(row);
    console.log(row);
  };
  const handledocumentdddata = (row) => {
    handledocumentdata(row);
  };
  /*-------------------------Download Template------------------*/
  const handleTemplateDownload = () => {
    handleDownloadTemplate();
    setPopAnchoEl(null);
  };
  /*--------------------------config for status-------------------*/
  const handleConfigStatus = (row) => {
    handleCofigOpen(row);
  };

  /*--------------------------Download Excel ----------------------*/
  const handleDownload = async (row) => {
    // Filtering the headers based on column visibility
    const selectedHeadCells = header.filter(
      (headCell) => columnVisibility[headCell.id]
    );

    // Mapping over each row to construct the data object with formatted values
    const data = row.map((rows) => {
      const rowData = {};
      selectedHeadCells.forEach((headCell) => {
        const value = rows[headCell.id];
        if (headCell.id === "status") {
          rowData[headCell.label] = value === 1 ? "Active" : "Inactive";
        } else if (headCell.id === "priority") {
          rowData[headCell.label] =
            value === 3
              ? "High"
              : value === 2
              ? "Medium"
              : value === 1
              ? "Low"
              : "Unknown";
        } else if (headCell.id === "createdDate") {
          if (value) {
            const date = new Date(value);
            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
            rowData[headCell.label] = formattedDate;
          } else {
            rowData[headCell.label] = "Invalid Date";
          }
        } else if (headCell.id === "action") {
          return false;
        } else {
          rowData[headCell.label] = value !== null ? value : "0";
          // rowData[headCell.id] = value;
        }
      });
      return rowData;
    });

    // Function to handle downloading the data as an Excel file
    handleDownloadExcel(data);
  };
  const inputRootStyle = {
    minHeight: "5px",
  };

  const outlinedInputStyle = {
    padding: "2px",
  };
  const handleActivee = async (row) => {
    handleActive(row);
  };
  const extractStartTimes = (data) => {
    return data.invDownTimes.flatMap((downTime) =>
      downTime.downtimePeriods.map((period) => period.startTime)
    );
  };
  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={
        <ArrowBackIcon
          sx={{ color: colorMode === "light" ? "#484C46" : "#007AFF" }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );

  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          sx={{ color: colorMode === "light" ? "#484C46" : "#007AFF" }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );

  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  console.log(overviewData, "overviewData");
  const handleExpand = async (id) => {
    try {
      // setLoading(true);
      const matchingObject = overviewData.find((row) => row.siteId === id);
      setAccordData(matchingObject);
      setExpanded({
        open: expanded.id === id ? !expanded.open : true,
        id: id,
      });
    } catch (error) {
      console.error(error);
    }
    if (overviewData.length !== 0) {
      // setLoading(false);
    }
  };
  function handleExpandSCB(equipId) {
    setLoading(true);
    setAccordDataSCB();
    setExpanded({
      open: expanded.id === equipId ? !expanded.open : true,
      id: equipId,
    });
    setLoading(false);
  }
  function checkNullOrUndefined(value) {
    if (value === null || value === undefined) {
      return 0;
    } else {
      return value;
    }
  }
  function replacetodayEnergy(todayEnergy) {
    if (typeof todayEnergy === "string" && todayEnergy.includes("MWh")) {
      const numericValue = parseFloat(todayEnergy.replace("MWh", "").trim());
      return numericValue * 1000;
    } else {
      return todayEnergy;
      // return todayEnergy?.replace('kWh', '');
    }
  }
  // function replacetodayEnergy(todayEnergy) {

  //   if (todayEnergy?.includes('MWh')) {
  //     const numericValue = parseFloat(todayEnergy.replace('MWh', '').trim());
  //     return numericValue * 1000;
  //   } else {
  //     return todayEnergy?.replace('kWh', '');
  //   }
  // }

  function replaceTotalEnergy(totalEnergy) {
    if (totalEnergy?.includes("MWh")) {
      const numericValue = parseFloat(totalEnergy.replace("MWh", "").trim());
      return numericValue * 1000;
    } else {
      return totalEnergy?.replace("kWh", "");
    }
  }
  const handleConfigureOpen = (row) => {
    handleOpenConfigure(row);
  };
  const handleConfigureEditOpen = (row) => {
    handleEditOpenConfigure(row);
  };

  const handleTicketClick = (data, isFromSiteList) => {
    const obj = { data, isFromSiteList };
    handleTicketPopupOpen(obj);
    console.log(data, "data");
  };

  const handleEquipmentClick = (row) => {
    console.log(row, "equequipIdipId");
    handleEquipmentPopupOpen(row);
  };

  const handleChangeSite = (event) => {
    let data = event.target.value;
    setSiteName(data);
    handleSiteNametoMaster(data);
  };

  const handleSiteExpect = (event, value) => {
    console.log(value, "value");
    if (value !== null) {
      console.log(value, "value");
      handleSiteChangeExpect(value);
    }
  };
  const handleSitedropdown = (event, value) => {
    console.log(value, "value");
    if (value !== null) {
      console.log(value, "value");
      handleSitedropdownvalue(value);
    }
  };
  useEffect(() => {
    if (siteNameListEx && siteNameListEx?.length > 0 && !sitevalueEx) {
      handleSiteFilterChangeExData(siteNameListEx[0]?.siteName); // Set the default value as the first option
    }
  }, [siteNameListEx, sitevalueEx, handleSiteFilterChangeExData]);
  const handleSiteFilter = (event, value) => {
    if (value !== null) {
      handleSiteFilterChange(value);
    }
  };
  console.log(selectedSite);

  const filteredRows = selectedSite
    ? rowsValue.filter((row) => row.siteId === selectedSite.id)
    : rowsValue;
  console.log(rowsValue);

  const handleTicketClose = () => {
    handleclosepopupopen(true);
  };

  const handleCheckboxChange = (row) => (event) => {
    const isChecked = event.target.checked;

    setCheckedState((prevCheckedState) => ({
      ...prevCheckedState,
      [row.ticketCode]: isChecked,
    }));
    console.log(row);
    setchecked(isChecked);
    handleTicketCheckvalue(isChecked, row);
  };

  const canAdd = PageName !== "Equipment  History";
  const handleChildData = (value, name, dates) => {
    taskHandler(value, name, dates);
  };

  console.log(tabsData, "tabsDsata", fallbackText);
  const handleInputChange = (id, name, e, index, row) => {
    console.log(id, name, e, index, row);

    handlechagevalue(id, name, e, index, row);
  };
  const handleTxtChange = (index, n) => {
    console.log(index);
  };
  function updateBadgeCounts(
    total,
    active,
    inactive,
    offline,
    warning,
    down,
    created,
    assigned
  ) {
    return tabsData?.map((tab) => {
      switch (tab.value) {
        case "all":
          return { ...tab, badgeCount: total };
        case "Active":
          return { ...tab, badgeCount: active };
        case "Inactive":
          return { ...tab, badgeCount: inactive };
        case "offline":
          return { ...tab, badgeCount: offline };
        case "warning":
          return { ...tab, badgeCount: warning };
        case "down":
          return { ...tab, badgeCount: down };
        case "ticketTypeName":
          return { ...tab, badgeCount: created };
        case "ticketTypeOperation":
          return { ...tab, badgeCount: assigned };
        default:
          return tab;
      }
    });
  }

  const count =
    Math.ceil((FilteredValue?.length - rowsPerPage) / rowsPerPage) + 1;
  function PageChange(value) {
    if (value == null) {
      setPageValue();
    } else if (Number(value) < Number(count)) {
      setPageValue(value);
    } else {
      setPageValue(count);
    }
  }

  const tableData = getDataValues(rawData);

  const Total = useMemo(() => {
    const value =
      isFromSiteList && PageName === "Ticket Overview"
        ? tableData.filter((site) => site.siteName === params.name).length
        : tableData.length;
    return value;
  }, [tableData]);

  const Active = useMemo(() => {
    const value = isSiteList
      ? tableData.filter((site) => site.siteStatus === "Active").length
      : tableData.filter((site) => site.status == 1).length;
    return value || 0;
  }, [tableData, isSiteList]);

  const Inactive = useMemo(() => {
    return tableData.filter((site) => site.status == 0).length || 0;
  }, [tableData]);

  const Offline = useMemo(() => {
    return (
      tableData.filter((site) => site.siteStatus === "Offline").length || 0
    );
  }, [tableData]);

  const Warning = useMemo(() => {
    return (
      tableData.filter((site) => site.siteStatus === "Warning").length || 0
    );
  }, [tableData]);

  const Down = useMemo(() => {
    return tableData.filter((site) => site.siteStatus === "Down").length || 0;
  }, [tableData]);

  const created = useMemo(() => {
    return isFromSiteList
      ? tableData.filter(
          (site) =>
            site.statusName === "Created" && site.siteName === params.name
        ).length
      : tableData.filter((site) => site.statusName === "Created").length || 0;
  }, [tableData]);

  const assigned = useMemo(() => {
    return isFromSiteList
      ? tableData.filter(
          (site) =>
            site.statusName === "Assigned" && site.siteName === params.name
        ).length
      : tableData.filter((site) => site.statusName === "Assigned").length || 0;
  }, [tableData]);

  const actualBadge =
    PageName === "Ticket Overview"
      ? updateBadgeCounts(Total, 0, 0, 0, 0, 0, created, assigned)
      : PageName === "Site List"
      ? updateBadgeCounts(Total, Active, Inactive, Offline, Warning, Down)
      : updateBadgeCounts(Total, Active, Inactive);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <>
          {PageName !== "Equipment Overview" && addButton !== "datalogger" ? (
            <div>
              {PageName !== "downTime" && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    position:
                      PageName === "Ticket Configuration"
                        ? "fixed"
                        : "relative",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      width: "101%",
                      borderRadius: "2px 2px 0px 0px",
                      userSelect: "none",
                      height: "5vh",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="space-between"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={PageName === "Site List" ? 3 : 4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <CustomBreadcrumbs
                          paths={paths || []}
                          separatorSize="18px"
                          fontSize="14px"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        {actualBadge ? (
                          <TabsComponent
                            tabs={actualBadge}
                            onTabChange={(val) => handleTabChange(val)}
                          />
                        ) : PageName === "Task Manager" ? (
                          <div>
                            <TaskFields
                              options={taskOptions}
                              onChildData={(value, name, dates) =>
                                handleChildData(value, name, dates)
                              }
                            />
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              )}

              <div>
                {PageName !== "downTime" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        mr: 0,
                        mb: 1,
                        mt: 2,
                        borderRadius: 0,
                        width: "101%",
                        height: "4.8vh",
                      },
                    }}
                  >
                    <Paper
                      elevation={0}
                      style={{
                        width: "101%",
                        borderRadius: "2px 2px 0px 0px",
                        // backgroundColor: "#F2F2F2",
                        userSelect: "none",
                      }}
                      component="div"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "0.25%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {PageName === "Expected Data" ? (
                            <div style={{ marginRight: "5vw" }}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={siteNameListEx?.map(
                                  (option) => option.siteName
                                )}
                                onChange={(event, newValue) => {
                                  console.log(
                                    "Autocomplete onChange:",
                                    newValue
                                  );
                                  handleSiteFilterChangeExData(newValue);
                                  // handleSiteFilterChange(newValue);
                                }}
                                sx={{
                                  width: "12vw",
                                  ml: "20px",
                                  backgroundColor:
                                    colorMode === "light"
                                      ? "#F7F8F9"
                                      : "#38373C",
                                  "& .MuiInputBase-root": {
                                    display: "flex",
                                    alignItems: "center",
                                    height: "3.5vh",
                                    minHeight: "unset",
                                    fontSize: "0.875rem",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    padding: "4px 8px",
                                    display: "flex",
                                    alignItems: "center",
                                    "& fieldset": {
                                      border: "none",
                                    },
                                  },
                                  "& .MuiAutocomplete-inputRoot": {
                                    padding: 0,
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                  "& .MuiAutocomplete-input": {
                                    height: "100%",
                                    padding: "2px 8px",
                                    textAlign: "center",
                                    fontSize: "0.875rem",
                                    lineHeight: "4.5vh",
                                  },
                                  "& .MuiInputLabel-root": {
                                    top: "-5px",
                                    fontSize: "0.875rem",
                                  },
                                  "& .MuiAutocomplete-option": {
                                    textAlign: "center",
                                  },
                                }}
                                // onChange={handleSiteFilter}
                                // value={sitevalue}
                                value={
                                  sitevalueEx || siteNameListEx[0].siteName
                                } // Default to the first value
                                clearOnBlur
                                clearOnEscape
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    placeholder={
                                      sitevalueEx ? "" : "Select a site"
                                    }
                                  />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </div>
                          ) : (
                            <div style={{ marginRight: "5vw" }}>
                              <InputBase
                                size="small"
                                className="cus-search"
                                placeholder={SearchLabel}
                                // placeholder={sitevalue.length > 0 ? sitevalue : SearchLabel}
                                variant="outlined"
                                startAdornment={
                                  <InputAdornment
                                    position="start"
                                    style={{ width: "40px" }}
                                  >
                                    <IconButton>
                                      <SearchOutlinedIcon
                                        style={{ color: "#9EA4AE" }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                style={{
                                  width: "240px",
                                  height: "30px",
                                  backgroundColor:
                                    colorMode === "light"
                                      ? "#F7F8F9"
                                      : "#38373C",
                                  borderRadius: "8px",
                                  fontSize: "small",
                                  marginLeft: "1vw",
                                  marginRight: "5vw",
                                  marginTop: "-0.75%",
                                  borderColor: "transparent",
                                }}
                                value={searchInput}
                                // value={sitevalue?.length > 0 ? sitevalue : searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                              />
                            </div>
                          )}
                          {PageName === "Master Data" ? (
                            <div style={{ marginleft: "1vw" }}>
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={siteName}
                                  onChange={handleChangeSite}
                                  style={{ height: "30px", width: "10vw" }}
                                  size="small"
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value={null}>
                                    <em>None</em>
                                  </MenuItem>
                                  {siteDropValue?.map((item) => (
                                    <MenuItem
                                      key={item.siteName}
                                      value={item.siteName}
                                    >
                                      {item.siteName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          ) : null}
                        </div>
                        {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          // marginRight: "20vw"
                        }}
                      > */}
                        <div>
                          {ExpectedYear === "ExpectedData Year" ? (
                            // <div style={{ marginRight: '10vw' }}>
                            <div style={{ marginRight: "690px" }}>
                              <Autocomplete
                                disablePortal
                                id="year-combo-box"
                                size="small"
                                options={yearOptions}
                                value={selectedYear}
                                onChange={handleYearChange}
                                sx={{
                                  width: "12vw",
                                  ml: "20px",
                                  backgroundColor:
                                    colorMode === "light"
                                      ? "#F7F8F9"
                                      : "#38373C",
                                  "& .MuiInputBase-root": {
                                    display: "flex",
                                    alignItems: "center",
                                    height: "3.5vh",
                                    minHeight: "unset",
                                    fontSize: "0.875rem",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    padding: "4px 8px",
                                    display: "flex",
                                    alignItems: "center",
                                    "& fieldset": {
                                      border: "none",
                                    },
                                  },
                                  "& .MuiAutocomplete-inputRoot": {
                                    padding: 0,
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                  "& .MuiAutocomplete-input": {
                                    height: "100%",
                                    padding: "2px 8px",
                                    textAlign: "center",
                                    fontSize: "0.875rem",
                                    lineHeight: "4.5vh",
                                  },
                                  "& .MuiInputLabel-root": {
                                    top: "-5px",
                                    fontSize: "0.875rem",
                                  },
                                  "& .MuiAutocomplete-option": {
                                    textAlign: "center",
                                  },
                                }}
                                clearOnBlur
                                clearOnEscape
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    // placeholder={
                                    //   sitevalueEx ? "" : "Select a site"
                                    // }
                                    placeholder="Select a year"
                                  />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </div>
                          ) : null}
                        </div>

                        <div style={{ marginRight: "1%" }}>
                          <Stack direction="row" spacing={2}>
                            {equipCat && (
                              <div>
                                <div>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "50%",
                                    }}
                                  >
                                    <Grid Items item xs={10}>
                                      <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        size="small"
                                        options={[
                                          "Inverter",
                                          "Energy Meter",
                                          "SCB",
                                          "Sensor",
                                        ]}
                                        value={equipCat}
                                        onChange={(event, newValue) => {
                                          handleEquipment(newValue);
                                        }}
                                        sx={{
                                          width: "12vw",
                                          marginTop: "-2.3%",
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Select Site ..."
                                            InputProps={{
                                              ...params.InputProps,
                                              style: outlinedInputStyle,
                                              classes: {
                                                root: "input-root",
                                              },
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              style: inputRootStyle,
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Box>
                                </div>
                              </div>
                            )}

                            {/* <div> */}
                            {/* {addButton === 'Ticket' &&
                              isFromSiteList === false ? ( */}
                            {/* {(addButton === 'Ticket' && isFromSiteList === false) || addButton === 'Equipment' ? ( */}
                            {/* {addButton === 'Ticket' &&
                              isFromSiteList === false ? (
                              <div>
                                <Autocomplete
                                  disablePortal
                                  // disableClearable
                                  id="combo-box-demo"
                                  size="small"
                                  options={siteNameList.map(
                                    (option) => option.siteName
                                  )}
                                  value={sitevalue}
                                  sx={{
                                    width: '12vw',
                                    ml: '20px',
                                    // mt: -0.15,
                                    backgroundColor:
                                      colorMode === 'light'
                                        ? '#F7F8F9'
                                        : '#38373C',
                                    '& .MuiInputBase-root': {
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: '3.5vh',
                                      minHeight: 'unset',
                                      fontSize: '0.875rem',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      padding: '4px 8px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                    },
                                    '& .MuiAutocomplete-inputRoot': {
                                      padding: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                    '& .MuiAutocomplete-input': {
                                      height: '100%',
                                      padding: '2px 8px',
                                      textAlign: 'center',
                                      fontSize: '0.875rem',
                                      lineHeight: '4.5vh',
                                    },
                                    '& .MuiInputLabel-root': {
                                      top: '-5px',
                                      fontSize: '0.875rem',
                                    },
                                    '& .MuiAutocomplete-option': {
                                      textAlign: 'center',
                                    },
                                  }}
                                  onChange={(event, value) =>
                                    handleSitedropdown(event, value)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="E.g: IFP"
                                      size="small"
                                    />
                                  )}
                                  classes={{ option: 'autocomplete' }}
                                />
                              </div>
                            ) : null} */}
                            {/* </div> */}
                            <div>
                              {addButton === "Ticket" ? (
                                !isFromSiteList ? (
                                  <div>
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      options={siteNameList.map(
                                        (option) => option.siteName
                                      )}
                                      value={sitevalue}
                                      sx={{
                                        width: "12vw",
                                        ml: "20px",
                                        backgroundColor:
                                          colorMode === "light"
                                            ? "#F7F8F9"
                                            : "#38373C",
                                        "& .MuiInputBase-root": {
                                          display: "flex",
                                          alignItems: "center",
                                          height: "3.5vh",
                                          minHeight: "unset",
                                          fontSize: "0.875rem",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          padding: "4px 8px",
                                          display: "flex",
                                          alignItems: "center",
                                          "& fieldset": {
                                            border: "none",
                                          },
                                        },
                                        "& .MuiAutocomplete-inputRoot": {
                                          padding: 0,
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                        "& .MuiAutocomplete-input": {
                                          height: "100%",
                                          padding: "2px 8px",
                                          textAlign: "center",
                                          fontSize: "0.875rem",
                                          lineHeight: "4.5vh",
                                        },
                                        "& .MuiInputLabel-root": {
                                          top: "-5px",
                                          fontSize: "0.875rem",
                                        },
                                        "& .MuiAutocomplete-option": {
                                          textAlign: "center",
                                        },
                                      }}
                                      onChange={(event, value) =>
                                        handleSitedropdown(event, value)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="E.g: IFP"
                                          size="small"
                                        />
                                      )}
                                      classes={{ option: "autocomplete" }}
                                    />
                                  </div>
                                ) : null // Add any content or null for this case if needed
                              ) : addButton === "Equipment" ? (
                                <div style={{ marginRight: "5vw" }}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size="small"
                                    options={siteNameList?.map(
                                      (option) => option.siteName
                                    )}
                                    // onChange={(event, value) =>
                                    //   handleSiteFilter(event, value)
                                    // }
                                    onChange={(event, newValue) => {
                                      console.log(
                                        "Autocomplete onChange:",
                                        newValue
                                      );
                                      handleSiteFilterChange(newValue);
                                    }}
                                    sx={{
                                      width: "12vw",
                                      ml: "20px",
                                      backgroundColor:
                                        colorMode === "light"
                                          ? "#F7F8F9"
                                          : "#38373C",
                                      "& .MuiInputBase-root": {
                                        display: "flex",
                                        alignItems: "center",
                                        height: "3.5vh",
                                        minHeight: "unset",
                                        fontSize: "0.875rem",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        padding: "4px 8px",
                                        display: "flex",
                                        alignItems: "center",
                                        "& fieldset": {
                                          border: "none",
                                        },
                                      },
                                      "& .MuiAutocomplete-inputRoot": {
                                        padding: 0,
                                        display: "flex",
                                        alignItems: "center",
                                      },
                                      "& .MuiAutocomplete-input": {
                                        height: "100%",
                                        padding: "2px 8px",
                                        textAlign: "center",
                                        fontSize: "0.875rem",
                                        lineHeight: "4.5vh",
                                      },
                                      "& .MuiInputLabel-root": {
                                        top: "-5px",
                                        fontSize: "0.875rem",
                                      },
                                      "& .MuiAutocomplete-option": {
                                        textAlign: "center",
                                      },
                                    }}
                                    // onChange={handleSiteFilter}
                                    value={sitevalue}
                                    clearOnBlur
                                    clearOnEscape
                                    // renderInput={(params) => (
                                    //   <TextField
                                    //     {...params}
                                    //     size="small"
                                    //     placeholder="Select a site"
                                    //   />
                                    // )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        placeholder={
                                          sitevalue ? "" : "Select a site"
                                        }
                                      />
                                    )}
                                    classes={{ option: "autocomplete" }}
                                  />
                                  {/* <Autocomplete
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                size="small"
                                options={siteNameList}
                                getOptionLabel={(option) => option.siteName}
                                onChange={(event, value) =>
                                  handleSiteFilter(event, value)
                                }
                                value={
                                  siteNameList.length > 0
                                    ? siteNameList[0]
                                    : null
                                }
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                                classes={{ option: "autocomplete" }}
                              /> */}
                                </div>
                              ) : addButton === "Error" ? (
                                <div style={{ marginRight: "5vw" }}>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size="small"
                                    options={equipmentTypeList?.map(
                                      (option) => option.equipmentType
                                    )}
                                    onChange={(event, newValue) => {
                                      console.log(
                                        "Autocomplete onChange:",
                                        newValue
                                      );
                                      handleEquipmentTypeChange(newValue);
                                    }}
                                    sx={{
                                      width: "12vw",
                                      ml: "20px",
                                      backgroundColor:
                                        colorMode === "light"
                                          ? "#F7F8F9"
                                          : "#38373C",
                                      "& .MuiInputBase-root": {
                                        display: "flex",
                                        alignItems: "center",
                                        height: "3.5vh",
                                        minHeight: "unset",
                                        fontSize: "0.875rem",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        padding: "4px 8px",
                                        display: "flex",
                                        alignItems: "center",
                                        "& fieldset": {
                                          border: "none",
                                        },
                                      },
                                      "& .MuiAutocomplete-inputRoot": {
                                        padding: 0,
                                        display: "flex",
                                        alignItems: "center",
                                      },
                                      "& .MuiAutocomplete-input": {
                                        height: "100%",
                                        padding: "2px 8px",
                                        textAlign: "center",
                                        fontSize: "0.875rem",
                                        lineHeight: "4.5vh",
                                      },
                                      "& .MuiInputLabel-root": {
                                        top: "-5px",
                                        fontSize: "0.875rem",
                                      },
                                      "& .MuiAutocomplete-option": {
                                        textAlign: "center",
                                      },
                                    }}
                                    value={typevalue}
                                    clearOnBlur
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        placeholder={
                                          typevalue ? "" : "Select a site"
                                        }
                                      />
                                    )}
                                    classes={{ option: "autocomplete" }}
                                  />
                                </div>
                              ) : null}
                            </div>

                            <div>
                              <div>
                                {addButton && canAdd ? (
                                  <Tooltip
                                    title={`Add ${addButton}`}
                                    userSelect="none"
                                    followCursor
                                    PopperProps={toolStyle}
                                  >
                                    <IconButton
                                      variant="contained"
                                      disabled={
                                        userRole?.create == 1 ? false : true
                                      }
                                      onClick={() => {
                                        handleAdd();
                                      }}
                                      style={{
                                        textTransform: "capitalize",
                                        color: "white",
                                        padding: "0",
                                        // backgroundColor: "#1C243C",
                                        borderRadius: "20px 20px 20px 20px",
                                      }}
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      <AddCircleOutlineSharpIcon
                                        style={{
                                          color:
                                            userRole?.create == 1
                                              ? "#004AFF"
                                              : "rgba(0, 0, 0, 0.26)",
                                          fontSize: "20px",
                                          backgroundColor:
                                            userRole?.create == 1
                                              ? "none"
                                              : "transparent",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                            <div>
                              <Tooltip
                                title="Hide Columns"
                                userSelect="none"
                                followCursor
                                PopperProps={toolStyle}
                              >
                                <IconButton
                                  variant="text"
                                  disabled={rowsValue?.length === 0}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "white",
                                    padding: "0",
                                    borderRadius: "20px 20px 20px 20px",
                                  }}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  onClick={handleColumnFilterClick}
                                >
                                  <VisibilityOutlinedIcon
                                    style={{
                                      color:
                                        rowsValue.length === 0
                                          ? "grey"
                                          : "#004AFF",
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleColumnFilterClose}
                                style={{ height: "50vh" }}
                              >
                                <MenuItem
                                  style={{
                                    paddingTop: "0",
                                    paddingBottom: "0",
                                  }}
                                >
                                  <Switch
                                    checked={Object.values(
                                      columnVisibility
                                    ).every((visible) => visible)}
                                    onChange={handleToggleAllColumns}
                                  />
                                  Select All
                                </MenuItem>
                                {header?.map((headCell) => (
                                  <MenuItem
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "0",
                                    }}
                                    key={headCell.id}
                                  >
                                    {headCell.id !== "action" && (
                                      <Switch
                                        checked={columnVisibility[headCell.id]}
                                        disabled={headCell.default}
                                        onChange={() =>
                                          handleToggleColumn(headCell.id)
                                        }
                                      />
                                    )}
                                    {headCell.id !== "action"
                                      ? headCell.label
                                      : null}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </div>
                            {/* {PageName === 'Ticket Overview' && (
                            <div> */}
                            {/* <Tooltip
                            title="Filter Columns"
                            userSelect="none"
                            followCursor
                          >
                            <Badge
                              color="primary"
                              badgeContent={
                                Object.keys(selectedColumns).length > 0 ? 1 : 0
                              }
                              variant="dot"
                            >
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: 'capitalize',
                                  color: 'white',
                                  padding: '0',
                                  // backgroundColor: "#1C243C",
                                  borderRadius: '20px 20px 20px 20px',
                                }}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'transparent',
                                  },
                                }}
                                onClick={handleSpecificFilterClick}
                              >
                                <img
                                  src={FigmaFilter}
                                  alt="filter"
                                  height="20px"
                                  width="20px"
                                />
                              </IconButton>
                            </Badge>
                          </Tooltip> */}
                            {/* <FileUploadOutlinedIcon
                                style={{ color: '#004AFF', fontSize: '23px' }}
                                onClick={() => {
                                  handleTicketupload();
                                }}
                              /> */}
                            {/* </div>
                          )} */}
                            {uploadButton ? (
                              <div>
                                <Tooltip
                                  title={"Upload Excel"}
                                  userSelect="none"
                                  followCursor
                                  PopperProps={toolStyle}
                                >
                                  <IconButton
                                    variant="contained"
                                    disabled={
                                      userRole?.create == 1 ? false : true
                                    }
                                    onClick={handlePopOpen}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "white",
                                      padding: "0",
                                      borderRadius: "20px 20px 20px 20px",
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <FileUploadOutlinedIcon
                                      style={{
                                        color: "#004AFF",
                                        fontSize: "23px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : null}
                            <Menu
                              id={ID}
                              open={popOpen}
                              anchorEl={popAnchorEl}
                              onClose={handleClose}
                              style={{ marginLeft: "-0.5%", marginTop: "0.5%" }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleTemplateDownload();
                                }}
                                style={{
                                  textTransform: "capitalize",
                                  padding: "15px",
                                  color: "#1976D2",
                                }}
                              >
                                <SaveAltIcon fontSize="small" />
                                <Typography
                                  style={{ marginLeft: "3%", fontSize: "14px" }}
                                >
                                  Download Template
                                </Typography>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  addButton === "Ticket"
                                    ? (handleUploadExcelpopup(), handleClose())
                                    : handleUpload()
                                }
                                style={{
                                  textTransform: "capitalize",
                                  padding: "15px",
                                  color: "#d32f2f",
                                }}
                              >
                                <FileUploadOutlinedIcon fontSize="small" />
                                <Typography
                                  style={{ marginLeft: "3%", fontSize: "14px" }}
                                >
                                  Upload Excel
                                </Typography>
                              </MenuItem>
                              <input
                                hidden
                                type="file"
                                ref={fileInputRef}
                                required
                                onChange={handleInput}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              />
                            </Menu>

                            {PageName !== "User Role" &&
                            PageName !== "Company List" ? (
                              <div>
                                <Tooltip
                                  title="Download Data"
                                  userSelect="none"
                                  followCursor
                                  PopperProps={toolStyle}
                                >
                                  <IconButton
                                    disabled={rowsValue.length === 0}
                                    variant="text"
                                    onClick={() => handleDownload(rowsValue)}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "white",
                                      padding: "0",
                                      borderRadius: "20px 20px 20px 20px",
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <SaveAltIcon
                                      style={{
                                        color:
                                          rowsValue.length === 0
                                            ? "grey"
                                            : "#004AFF",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : null}

                            {PageName === "Ticket Overview" ? (
                              <div>
                                <Tooltip
                                  title=" Filter Data"
                                  userSelect="none"
                                  followCursor
                                  PopperProps={toolStyle}
                                >
                                  <IconButton
                                    variant="text"
                                    onClick={() => {
                                      handlefilter();
                                    }}
                                    valuee={filterstatus}
                                    filter={filterData}
                                    bardata={barchart}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "white",
                                      padding: "0",
                                      borderRadius: "20px 20px 20px 20px",
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <FilterAltOutlinedIcon
                                      style={{
                                        color: "#004AFF",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : null}
                            {/* {PageName === 'Report' ? (
                            <div>
                              <Tooltip
                                title=" Report"
                                userSelect="none"
                                followCursor
                                PopperProps={toolStyle}
                              >
                                <IconButton
                                  variant="text"
                                  onClick={() => {
                                    handlereport();
                                  }}
                                  // valuee={filterstatus}
                                  // filter={filterData}
                                  // bardata={barchart}
                                  style={{
                                    textTransform: 'capitalize',
                                    color: 'white',
                                    padding: '0',
                                    borderRadius: '20px 20px 20px 20px',
                                  }}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                  }}
                                >
                                  <AddBoxOutlinedIcon
                                    style={{
                                      color: '#004AFF',
                                      fontSize: '20px',
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : null} */}

                            {PageName === "Ticket Overview" &&
                            Object.values(checkedState).includes(true) ? (
                              <div>
                                <Tooltip
                                  title="Ticket Close"
                                  userSelect="none"
                                  followCursor
                                  PopperProps={toolStyle}
                                >
                                  <IconButton
                                    variant="text"
                                    onClick={() => {
                                      handleTicketClose();
                                    }}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "white",
                                      padding: "0",
                                      borderRadius: "20px 20px 20px 20px",
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <SubtitlesOffIcon
                                      style={{
                                        color: "#004AFF",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : null}
                          </Stack>
                        </div>
                      </div>
                    </Paper>
                  </Box>
                )}
              </div>

              {(rowsValue?.length === 0 || rowsValue === undefined) &&
              !loading ? null : (
                <div>
                  {chartValue === "Ticket Overview" ? (
                    <div style={{ marginBottom: "1.3%" }}>
                      <div
                        elevation={0}
                        style={{
                          width: "100%",
                          borderRadius: "2px 2px 0px 0px",
                          // userSelect: 'none',
                          // height: '58vh',
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <PieChartdata
                              sitevalue={sitevalue}
                              filterData={filterData}
                              filterstatus={filterstatus}
                              statuscall={statuscall}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <BarChartdata
                              sitevalue={sitevalue}
                              barchart={barchart}
                              filterstatus={filterstatus}
                              statuscall={statuscall}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : PageName === "Task Manager" && rowsValue?.length > 0 ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ColumnChart
                          date={[]}
                          chart={"Task Manager"}
                          firstBar={[]}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DonutChartRace
                          projectData={rowsValue}
                          dataval={taskOptions.dates}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <div>
            <Box
              key={keyId}
              sx={{
                width: "99%",
                minHeight: "65vh",
                maxHeight: "max-content",
              }}
            >
              {!loading && FilteredValue.length === 0 ? (
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "101%",
                    minHeight: "65vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    lineHeight: "14.52px !important",
                    color: "rgb(113, 118, 111)",
                  }}
                >
                  {(rowsValue?.length === 0 || rowsValue === undefined) &&
                  searchInput.length === 0 ? (
                    <>
                      {tableToShow === "equipment" &&
                        "Currently, there are no equipment configured to display."}
                      {tableToShow === "Energy Meter" &&
                        "At this moment, there are no energy meter devices to display."}
                      {tableToShow === "SCB" &&
                        "Currently, there are no SCB configured to display."}
                      {tableToShow === "Sensor" &&
                        "At this time, there are no sensors configured to display."}
                      {tableToShow === "equipmetName" &&
                        "Currently, there are no alarms configured to display."}
                      {tableToShow === "userdefine" &&
                        "There are currently no alarms to display at this moment"}
                      {!tableToShow && fallbackText}
                    </>
                  ) : (
                    FilteredValue.length === 0 &&
                    searchInput.length > 0 &&
                    searchFallBack
                  )}
                </Box>
              ) : (
                <>
                  <Paper
                    sx={{ mb: 1, width: "101%" }}
                    style={{ userSelect: "none" }}
                    elevation={0}
                  >
                    <TableContainer
                      sx={{
                        height: "65vh",
                        overflow: "auto",
                        scrollBehavior: "smooth",
                        scrollbarGutter: "stable",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "0.4em",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#888",
                          borderRadius: "20px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#555",
                        },
                      }}
                    >
                      <Table
                        sx={{ minWidth: 650, maxWidth: "94vw" }}
                        aria-labelledby="tableTitle"
                        stickyHeader
                        size="small"
                        aria-label="sticky table"
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rowsValue?.length}
                        />
                        {visibleRows?.map((row, mainindex) => {
                          return (
                            <>
                              <TableBody>
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleClick(event, row.id)
                                  }
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                  // sx={{ cursor: 'pointer' }}
                                >
                                  {header?.map((headCell, index) => (
                                    <TableCell
                                      key={headCell.id}
                                      align={
                                        isSiteList && index === 0
                                          ? //  || headCell.id === 'siteName'
                                            "left"
                                          : "center"
                                      }
                                      style={{
                                        userSelect: "none",
                                        display: columnVisibility[headCell.id]
                                          ? "table-cell"
                                          : "none",
                                        // fontWeight: "500",
                                        paddingRight: "20px",
                                        lineHeight: "16px",
                                        zIndex: "0px",
                                        flex: 1,
                                        height: "6vh",
                                      }}
                                    >
                                      {headCell.id === "action" ? (
                                        <>
                                          {PageName === "Docuemnt" && (
                                            <Tooltip
                                              title="download"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="download"
                                                onClick={() =>
                                                  handledownloadddata(row)
                                                }
                                              >
                                                <SaveAltIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {PageName === "Docuemnt" ? (
                                            <Tooltip
                                              title="Delete"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="delete"
                                                disabled={
                                                  userRole.edit == 1
                                                    ? false
                                                    : true
                                                }
                                                onClick={() =>
                                                  handleDeletedata(row)
                                                }
                                              >
                                                <Delete />
                                              </IconButton>
                                            </Tooltip>
                                          ) : PageName !== "Subscription" ? (
                                            <Tooltip
                                              title="Edit"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="edit"
                                                disabled={
                                                  userRole.edit !== 1 ||
                                                  (addButton === "Parameter" &&
                                                    row.paramEditFlag === "No")
                                                }
                                                style={{ padding: 0 }}
                                                onClick={() =>
                                                  handleEditOpen(row)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Tooltip>
                                          ) : PageName === "Subscription" ? (
                                            <Tooltip
                                              title="Edit"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="edit"
                                                disabled={
                                                  userRole.edit !== 1 ||
                                                  row?.stateName === "Closed"
                                                }
                                                style={{ padding: 0 }}
                                                onClick={() =>
                                                  handleEditOpen(row)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Tooltip>
                                          ) : null}
                                          {PageName === "Ticket Overview" && (
                                            <Tooltip
                                              title="download"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="download"
                                                disabled={
                                                  userRole.edit !== 1 ||
                                                  row?.statusName ===
                                                    "Created" ||
                                                  row?.statusName ===
                                                    "Assigned" ||
                                                  row?.statusName ===
                                                    "Inprogress" ||
                                                  row?.statusName === "Hold"
                                                  //  row?.stateName === 'Closed'
                                                }
                                                onClick={() =>
                                                  handledownloaddTicket(row)
                                                }
                                              >
                                                <SaveAltIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {PageName === "Report" && (
                                            <Tooltip
                                              title="download"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="download"
                                                onClick={() =>
                                                  handledownloaddReport(row)
                                                }
                                              >
                                                <SaveAltIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {addButton === "Site Map" && (
                                            <Tooltip
                                              title="Delete"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="delete"
                                                disabled={
                                                  userRole.edit == 1
                                                    ? false
                                                    : true
                                                }
                                                onClick={() =>
                                                  handleDeletedata(row)
                                                }
                                              >
                                                <Delete />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {PageName ===
                                            "Site Configuration" && (
                                            <Tooltip
                                              title="Document Upload"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="download"
                                                disabled={row?.status === 0}
                                                onClick={() =>
                                                  handledocumentdddata(row)
                                                }
                                              >
                                                <UploadFileOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {PageName === "Equipment Details" && (
                                            <Tooltip
                                              title="Replace"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="replace"
                                                onClick={() =>
                                                  handleReplceEquip(row)
                                                }
                                              >
                                                <LoopIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {addButton === "Status" && (
                                            <Tooltip
                                              title="Config"
                                              userSelect="none"
                                              PopperProps={toolStyle}
                                              followCursor
                                            >
                                              <IconButton
                                                aria-label="config"
                                                onClick={() =>
                                                  handleConfigStatus(row)
                                                }
                                              >
                                                <CreateNewFolderOutlinedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </>
                                      ) : headCell.id === "value" ||
                                        headCell.id === "value" ? (
                                        <Switch
                                          {...label}
                                          checked={
                                            row["status"] == 1 ? true : false
                                          }
                                          onChange={() => handleActivee(row)}
                                        />
                                      ) : headCell.id === "createdDate" ||
                                        headCell.id === "creationDate" ? (
                                        row[headCell.id] ? (
                                          new Date(
                                            row[headCell.id]
                                          ).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) : headCell.id === "poToDate" ||
                                        headCell.id === "poToDate" ? (
                                        row[headCell.id] ? (
                                          new Date(
                                            row[headCell.id]
                                          ).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) : headCell.id === "poFromDate" ||
                                        headCell.id === "poFromDate" ? (
                                        row[headCell.id] ? (
                                          new Date(
                                            row[headCell.id]
                                          ).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) : headCell.id === "performanceRatio" ? (
                                        row[headCell.id] ? (
                                          row[headCell.id]
                                        ) : (
                                          "-"
                                        )
                                      ) : headCell.id ===
                                          "lastlastUpdatedDate" ||
                                        headCell.id === "lastUpdatedDate" ? (
                                        row[headCell.id] ? (
                                          new Date(
                                            row[headCell.id]
                                          ).toLocaleDateString()
                                        ) : (
                                          ""
                                        )
                                      ) : headCell.id ===
                                        "isDirectoryCreated" ? (
                                        row[headCell.id] === 1 ? (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                              textTransform: "capitalize",
                                              borderRadius: "19px",
                                            }}
                                            onClick={() => {
                                              handleConfigureEditOpen(row);
                                            }}
                                            startIcon={<EditIcon />}
                                            size="small"
                                          >
                                            Edit
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                              textTransform: "capitalize",
                                              borderRadius: "19px",
                                            }}
                                            onClick={() => {
                                              handleConfigureOpen(row);
                                            }}
                                            startIcon={
                                              <AddCircleOutlineSharpIcon />
                                            }
                                            size="small"
                                          >
                                            Configure
                                          </Button>
                                        )
                                      ) : ((isSiteList &&
                                          headCell.id === "siteStatus") ||
                                          tableToShow === "equipment" ||
                                          tableToShow === "SCB") &&
                                        (headCell.id === "label" ||
                                          headCell.id === "status" ||
                                          headCell.id === "derivedStatusName" ||
                                          headCell.id === "siteStatus") ? (
                                        tableToShow === "SCB" &&
                                        !isPresent(
                                          scbAPIResult,
                                          row.transactionid
                                        ) &&
                                        row.transactionid != null ? (
                                          <div>
                                            <CircularProgress
                                              size={15}
                                              sx={{ marginRight: 2 }}
                                            />
                                          </div>
                                        ) : (
                                          <span
                                            style={{
                                              backgroundColor:
                                                colorMode === "light"
                                                  ? getStatusIcon(
                                                      (isSiteList &&
                                                        row[headCell.id]) ||
                                                        (tableToShow ===
                                                        "equipment"
                                                          ? row[headCell.id]
                                                            ? row[headCell.id]
                                                            : "Offline"
                                                          : tableToShow ===
                                                            "energyCounter"
                                                          ? row[
                                                              "energyCounter"
                                                            ] == 1
                                                            ? "Yes"
                                                            : "No"
                                                          : tableToShow ===
                                                              "SCB" &&
                                                            row.transactionid &&
                                                            isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Down"
                                                          : !isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Active"
                                                          : "Offline")
                                                    ).backgroundColor
                                                  : getStatusIconDark(
                                                      (isSiteList &&
                                                        row[headCell.id]) ||
                                                        (tableToShow ===
                                                        "equipment"
                                                          ? row[headCell.id]
                                                            ? row[headCell.id]
                                                            : "Offline"
                                                          : tableToShow ===
                                                              "SCB" &&
                                                            row.transactionid &&
                                                            isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Down"
                                                          : !isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Active"
                                                          : "Offline")
                                                    ).backgroundColor,
                                              color:
                                                colorMode === "light"
                                                  ? getStatusIcon(
                                                      (isSiteList &&
                                                        row[headCell.id]) ||
                                                        (tableToShow ===
                                                        "equipment"
                                                          ? row[headCell.id]
                                                            ? row[headCell.id]
                                                            : "Offline"
                                                          : tableToShow ===
                                                              "SCB" &&
                                                            row.transactionid &&
                                                            isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Down"
                                                          : !isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Active"
                                                          : "Offline")
                                                    ).textColor
                                                  : getStatusIconDark(
                                                      (isSiteList &&
                                                        row[headCell.id]) ||
                                                        (tableToShow ===
                                                        "equipment"
                                                          ? row[headCell.id]
                                                            ? row[headCell.id]
                                                            : "Offline"
                                                          : tableToShow ===
                                                              "SCB" &&
                                                            row.transactionid &&
                                                            isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Down"
                                                          : !isAnyDown(
                                                              scbAPIResult[
                                                                row
                                                                  .transactionid
                                                              ],
                                                              Number(
                                                                row.components
                                                              ) - 1
                                                            )
                                                          ? "Active"
                                                          : "Offline")
                                                    ).textColor,
                                              padding: "5px 10px",
                                              borderRadius: "50px",
                                              display: "flex",
                                              cursor: "default",
                                              whiteSpace: "nowrap",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {isSiteList ? (
                                              <>
                                                {getStatusIcons(
                                                  row[headCell.id]
                                                )}
                                                {row[headCell.id]}
                                              </>
                                            ) : tableToShow === "SCB" ? (
                                              <>
                                                {getStatusIcons(
                                                  row.transactionid &&
                                                    isAnyDown(
                                                      scbAPIResult[
                                                        row.transactionid
                                                      ],
                                                      Number(row.components) - 1
                                                    )
                                                    ? "Down"
                                                    : row.timestamp &&
                                                      !isAnyDown(
                                                        scbAPIResult[
                                                          row.transactionid
                                                        ],
                                                        Number(row.components) -
                                                          1
                                                      )
                                                    ? "Active"
                                                    : "Inactive"
                                                )}
                                                {row.transactionid &&
                                                isAnyDown(
                                                  scbAPIResult[
                                                    row.transactionid
                                                  ],
                                                  Number(row.components) - 1
                                                )
                                                  ? "Down"
                                                  : row.timestamp &&
                                                    !isAnyDown(
                                                      scbAPIResult[
                                                        row.transactionid
                                                      ],
                                                      Number(row.components) - 1
                                                    )
                                                  ? "Active"
                                                  : "Inactive"}
                                              </>
                                            ) : (
                                              <>
                                                {tableToShow === "equipment" ? (
                                                  <>
                                                    {getStatusIcons(
                                                      row[headCell.id]
                                                        ? row[headCell.id]
                                                        : "Offline"
                                                    )}
                                                    {row[headCell.id]
                                                      ? row[headCell.id]
                                                      : "Offline"}
                                                  </>
                                                ) : null}
                                              </>
                                            )}
                                          </span>
                                        )
                                      ) : headCell.id === "status" ? (
                                        row[headCell.id] ||
                                        row["networkStatus"] == 1 ? (
                                          "Active"
                                        ) : (
                                          "Inactive"
                                        )
                                      ) : headCell.id === "priority" ? (
                                        row[headCell.id] == 1 ? (
                                          "Low"
                                        ) : row[headCell.id] == 2 ? (
                                          "Medium"
                                        ) : (
                                          "High"
                                        )
                                      ) : headCell.id === "status" ? (
                                        row[headCell.id] === 1 ? (
                                          "Active"
                                        ) : (
                                          "inActive"
                                        )
                                      ) : headCell.id === "energyCounter" ? (
                                        row[headCell.id] === 1 ? (
                                          "Yes"
                                        ) : (
                                          "No"
                                        )
                                      ) : headCell.id === "assignedBy" ? (
                                        row[headCell.id] === "Assign" ? (
                                          <div
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleassigned(row);
                                            }}
                                          >
                                            {" "}
                                            Assign
                                          </div>
                                        ) : (
                                          <div>{row.assignedBy}</div>
                                        )
                                      ) : (headCell.id === "withAcUnits" ||
                                          headCell.id === "withDcUnits") &&
                                        PageName === "Equipment Detail" ? (
                                        row[headCell.id] === null ||
                                        row[headCell.id] === undefined ? (
                                          "-"
                                        ) : (
                                          row[headCell.id]
                                        )
                                      ) : headCell.id === "ticketCode" ? (
                                        PageName === "Ticket Overview" ? (
                                          <div
                                            key={row.id}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div key={row.ticketId}>
                                              <Checkbox
                                                disabled={
                                                  userRole.edit !== 1 ||
                                                  row?.statusName ===
                                                    "Created" ||
                                                  row?.statusName ===
                                                    "Assigned" ||
                                                  row?.statusName ===
                                                    "Inprogress" ||
                                                  row?.statusName === "Hold" ||
                                                  row?.stateName === "Closed"
                                                }
                                                checked={
                                                  checkedState[
                                                    row.ticketCode
                                                  ] || false
                                                }
                                                onChange={handleCheckboxChange(
                                                  row
                                                )}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                color: "blue",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleTicketClick(
                                                  row,
                                                  isFromSiteList
                                                )
                                              }
                                            >
                                              {row.ticketCode}
                                            </div>
                                          </div>
                                        ) : (
                                          <div></div>
                                        )
                                      ) : headCell.id === "ticketCode" ? (
                                        PageName === "Ticket Overview" ? (
                                          <div
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                              marginTop: "5%",
                                            }}
                                            onClick={() =>
                                              handleTicketClick(
                                                row,
                                                isFromSiteList
                                              )
                                            }
                                          >
                                            {row.ticketCode}
                                          </div>
                                        ) : (
                                          <div></div>
                                        )
                                      ) : headCell.label === "Remarks" ? (
                                        PageName === "downTime" ? (
                                          <div>
                                            <TextField
                                              defaultValue={row.remarks || ""}
                                              onChange={(e) => {
                                                handleInputChange(
                                                  row.downTimeId,
                                                  "remarks",
                                                  e.target.value,
                                                  mainindex,
                                                  row
                                                );
                                                handleTxtChange(
                                                  index,
                                                  "remarks"
                                                );
                                              }}
                                              key={row.uniqueId}
                                              name={"remark"}
                                              inputProps={{
                                                style: {
                                                  textTransform: "capitalize",
                                                  lineHeight: "14.52px",
                                                  color: "rgb(113, 118, 111)",
                                                  // color:"black",
                                                  fontSize: "13px",
                                                },
                                              }}
                                              style={{ color: "gray" }}
                                              size="small"
                                              width="35px"
                                            />
                                          </div>
                                        ) : (
                                          <div></div>
                                        )
                                      ) : headCell.id === "displayName" &&
                                        tableToShow === "equipment" ? (
                                        <div
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleEquipmentClick(row)
                                          }
                                        >
                                          {row.displayName}
                                        </div>
                                      ) : headCell.id === "activityName" ? (
                                        pageNames[row[headCell.id]] ===
                                        undefined ? (
                                          row[headCell.id]
                                        ) : (
                                          pageNames[row[headCell.id]]
                                        )
                                      ) : headCell.id === "todayEnergy" ? (
                                        PageName === "Site List" ? (
                                          row.todayEnergy !== undefined ||
                                          row ? (
                                            row.todayEnergy
                                          ) : (
                                            0
                                          )
                                        ) : (
                                          replacetodayEnergy(
                                            Number(row.todayEnergy)
                                          ).toFixed(2)
                                        )
                                      ) : headCell.id === "totalEnergy" ? (
                                        PageName === "Site List" ? (
                                          row.totalEnergy !== undefined ||
                                          row.totalEnergy !== null ? (
                                            row.totalEnergy
                                          ) : (
                                            0
                                          )
                                        ) : (
                                          replaceTotalEnergy(
                                            Number(row.totalEnergy).toFixed(2)
                                          )
                                        )
                                      ) : // : headCell.id === 'equipmentCount' ? (
                                      //   `${row.activeInverterCount} / ${row.inverterCount}`
                                      // )

                                      headCell.id === "inverterCount" ? (
                                        row.inverterCount === undefined ||
                                        row.inverterCount === null ? (
                                          "0/0"
                                        ) : (
                                          `${row.activeInverterCount} / ${row.inverterCount}`
                                        )
                                      ) : (isSiteList &&
                                          headCell.id === "siteName") ||
                                        (tableToShow === "SCB" &&
                                          headCell.id === "customernaming") ? (
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          // onMouseEnter={() => {
                                          //   setIsMouseOverIcon(true);
                                          //   setSiteHover(row[headCell.id]);
                                          // }}
                                          // onMouseLeave={() => {
                                          //   setIsMouseOverIcon(false);
                                          //   setSiteHover("");
                                          // }}
                                          onClick={() => {
                                            isSiteList ? (
                                              handleExpand(row.siteId)
                                            ) : !isPresent(
                                                scbAPIResult,
                                                row.transactionid
                                              ) &&
                                              row.transactionid !== null ? (
                                              <></>
                                            ) : (
                                              handleExpandSCB(row.equipmentid)
                                            );
                                          }}
                                        >
                                          {expanded.open &&
                                          (row.siteId === expanded.id ||
                                            row.equipmentid === expanded.id) ? (
                                            <KeyboardArrowUpOutlinedIcon
                                              style={{
                                                padding: "0",
                                                marginRight: "2px",
                                              }}
                                            />
                                          ) : !isPresent(
                                              scbAPIResult,
                                              row.transactionid
                                            ) && row.transactionid !== null ? (
                                            <KeyboardArrowDownOutlinedIcon
                                              style={{
                                                padding: "0",
                                                marginRight: "2px",
                                                color: "darkgrey",
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowDownOutlinedIcon
                                              style={{
                                                padding: "0",
                                                marginRight: "2px",
                                              }}
                                            />
                                          )}
                                          {headCell.id === "siteName"
                                            ? // ? nameMapping[row[headCell.id]]
                                              row[headCell.id]
                                            : row[headCell.id]}
                                        </span>
                                      ) : headCell.id === "statusMasterName" &&
                                        PageName === "Status" ? (
                                        <Grid
                                          style={{
                                            color:
                                              row.statusParameterMaping
                                                .length !== 0
                                                ? "blue"
                                                : null,
                                            cursor:
                                              row.statusParameterMaping
                                                .length !== 0
                                                ? "pointer"
                                                : "default",
                                          }}
                                          onClick={() =>
                                            row.statusParameterMaping.length !==
                                            0
                                              ? handleStatusRedirect(
                                                  row.statusMasterId,
                                                  row.statusMasterName
                                                )
                                              : null
                                          }
                                        >
                                          {row[headCell.id]}
                                        </Grid>
                                      ) : row[headCell.id] !== null ? (
                                        (() => {
                                          return row[headCell.id];
                                        })()
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                              {(isSiteList &&
                                expanded.open &&
                                expanded.id === row.siteId) ||
                              (tableToShow === "SCB" &&
                                expanded.open &&
                                expanded.id === row.equipmentid) ? (
                                <TableBody>
                                  <TableRow
                                    sx={{
                                      backgroundColor:
                                        colorMode === "light"
                                          ? "#F2F1EB"
                                          : "grey",
                                    }}
                                  >
                                    <TableCell colSpan={header?.length + 1}>
                                      {isSiteList ? (
                                        <Accordian
                                          overview={overviewData.filter(
                                            (e) => e.siteId === row.siteId
                                          )}
                                          TicketIsActive={TicketIsActive}
                                          siteId={row.siteId}
                                          siteName={row.siteName}
                                          specificYield={row.specificYield}
                                          irradiation={row.irradiation}
                                        />
                                      ) : (
                                        <SCBAccordian
                                          data={
                                            extractAndSortString(
                                              scbAPIResult[row.transactionid]
                                            ) || {}
                                          }
                                          count={row.components}
                                          statusDown={
                                            !isPresent(
                                              scbAPIResult,
                                              row.transactionid
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ) : null}
                            </>
                          );
                        })}
                      </Table>
                    </TableContainer>
                  </Paper>

                  {rowsValue?.length > 0 && (
                    <Paper
                      component="div"
                      elevation={0}
                      style={{
                        display: "flex",
                        width: "101%",
                        padding: "8px 16px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "4px",
                        userSelect: "none",
                        height: "4.8vh",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        ></Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Stack spacing={2} direction="row">
                            <Pagination
                              count={count}
                              shape="rounded"
                              color="primary"
                              onChange={handleChangePage}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: renderCustomPrevious,
                                    next: renderCustomNext,
                                  }}
                                  {...item}
                                />
                              )}
                            />
                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{
                                color: "#E6E7E9",
                                height: "30px",
                                marginRight: "16px",
                              }}
                            />
                          </Stack>
                          <Stack
                            direction="row"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              marginLeft: "1%",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "12px",
                                lineHeight: "14.52px",
                                color:
                                  colorMode === "light" ? "#9DA19B" : "#DBDBEB",
                                fontWeight: "400",
                                // marginLeft: '10px',
                              }}
                            >
                              Go to page
                            </Typography>
                            <TextField
                              variant="standard"
                              style={{ width: "24px", marginLeft: "5px" }}
                              value={pageValue != 0 ? pageValue : ""}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue !== "0") {
                                  PageChange(newValue);
                                }
                              }}
                            />
                            <Button
                              style={{
                                padding: "0",
                                width: "12px",
                                fontSize: "14px",
                              }}
                              onClick={handleClickGo}
                              endIcon={
                                <ArrowForwardIcon
                                  style={{ fontSize: "16px" }}
                                />
                              }
                            >
                              GO
                            </Button>
                            <Autocomplete
                              disablePortal
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { label: "10", value: 10 },
                                { label: "15", value: 15 },
                                { label: "20", value: 20 },
                              ]}
                              sx={{ width: 20 }}
                              style={{
                                marginLeft: "10px",
                                marginRight: "30px",
                              }}
                              defaultValue={isSiteList ? 15 : rowsPerPage}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={handleChangeRowsPerPage}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  sx={{
                                    fontFamily: "Inter, sans-serif",
                                    width: 20,
                                  }}
                                />
                              )}
                              clearIcon={null}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  )}

                  <TemporaryDrawer
                    state={SanchorEl}
                    handleClose={handleSpecificFilterClose}
                    rowsValue={rowsValue}
                    dropData={dropData}
                    header={header}
                    pageName={PageName}
                    onApplyFilter={handleSelectiveFilter}
                  />
                </>
              )}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};
export default TableTemplate;
