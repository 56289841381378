import React, { useEffect, useState } from 'react';
/*-------------------------------------- Mui components -------*/
import {
  Grid,
  Box,
  Paper,
  Typography,
  TextField,
  Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  InputLabel,
  Autocomplete,
  MenuItem,
  FormControl,
  Select,
  Button,
} from '@mui/material';
import CustomSnackbar from '../../util/components/CustomSnackbar';
import TableTemplate from '../../../Template/TableTemplate';
import '../../../Common.css';
/*---------------------------------- Mui icons ------------------------*/
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  getFileModeApi,
  getFileTypeApi,
  createFtpDirect,
  getSiteConfigBySiteId,
  getFileFrequency,
  UpdateFTPConfig,
} from '../../../Api/FTPAPI';
import { Equipmentsitedropdown } from '../../../Api/EquipmentApi';
import { useNavigate } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import { CheckForLight } from '../../util/common/CommanFunction';

const style = {
  position: 'absolute',
  top: '50%',
  // left: "50%",
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Ftp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetchsitedrop();
  }, []);
  let initalState = {
    siteName: '',
    siteCode: '',
    installationCapacity: '',
    modeOfDataTransfer: '',
    fileType: '',
  };
  const [mode, setMode] = React.useState('');
  const [filetype, setFiletype] = React.useState('');
  const [folderName, setFolderName] = React.useState('');

  const [fileMode, setFileMode] = useState([]);
  const [fileType, setFileType] = useState([]);
  const [siteDropDown, setSitedropvalue] = useState([]);
  const [sitename, setSiteName] = useState(null);
  const [siteCode, setSiteCode] = useState(null);
  const [instalationCapacity, setInstalationCapacity] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [siteDetails, setSiteDetails] = useState(initalState);
  const [fileFrequency, setFileFrequency] = useState([]);
  const [fileFrequencyArray, setFileFrequencyArray] = useState([]);

  const [open, setOpen] = useState(false);
  const [siteError, setsiteError] = useState(false);
  const [filetypeError, setFileTypeError] = useState(false);
  const [modeError, setModeError] = useState(false);
  const [fileFreqError, setFileFreqError] = useState(false);
  const [fileFrequencyData, setFileFrequencyData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const [showFileDirectory, setShowFileDirectory] = useState(false);

  const listItem = [
    { name: 'CONFIG', upload: true, create: true },
    { name: 'DEF', upload: true, create: true },
    { name: 'DATA', upload: true, create: true },
  ];

  const headCells = [
    { id: 'siteCode', label: 'Site Code', view: true },
    { id: 'siteName', label: 'Site Name', view: true, default: true },
    { id: 'installationCapacity', label: 'Installation Capacity', view: true },
    { id: 'isDirectoryCreated', label: 'Action', view: true, default: true },
  ];

  useEffect(() => {
    getFileType();
    getFileMode();
    // getsiteConfigFtp();
    // getFileFrequencyData();
  }, []);

  const getFileMode = async () => {
    let data = await getFileModeApi();
    setFileMode(data);
  };
  const getFileType = async () => {
    let data = await getFileTypeApi();
    setFileType(data);
  };
  const getFileFrequencyData = async (siteCode) => {
    try {
      const data = await getFileFrequency(siteCode);
      setFileFrequencyData(data);
    } catch (error) {
      console.error('Error fetching file frequency:', error);
    }
  };
  useEffect(() => {
    if (siteDetails?.siteCode) {
      getFileFrequencyData(siteDetails.siteCode);
    }
  }, [siteDetails?.siteCode]);
  const getsiteConfigFtp = async () => {
    let data = await getSiteConfigBySiteId();
  };

  // const handleOpen = () =>{
  //   setOpen(true);
  //   setMode('');
  //   setFiletype([]);setFileFrequency(null);setSiteName(null);
  //   setSiteCode(null);setInstalationCapacity(null)
  // }
  const handleClose = () => {
    console.log('dasfsd');
    setOpen(false);
    setEdit(false);
    setSiteDetails(null);
    setMode(null);
  };

  const handleChange = (event) => {
    let data = event.target.value;
    console.log(data, 'data');
    setSiteDetails({ ...data, siteName: data });
    getDetails(data);
  };
  const handleChangeFileFrequency = (event) => {
    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      fileFrequency: event.target.value,
    }));
  };

  const handleChangeMode = (event) => {
    setSiteDetails({ ...siteDetails, modeOfDataTransfer: event.target.value });
  };
  const handleChangeFile = (event) => {
    setSiteDetails({ ...siteDetails, fileType: event.target.value });
  };

  const fetchsitedrop = async () => {
    const data = await Equipmentsitedropdown();
    if (Array.isArray(data)) {
      setSitedropvalue(data);
      setLoading(false);
    } else if (typeof data === 'object' && data !== null) {
      setSitedropvalue([data]);
      setLoading(false);
    }
  };

  const getDetails = (dataValue) => {
    let arr = siteDropDown.filter((data) => {
      return data.siteName === dataValue;
    });
    setSiteDetails({
      ...siteDetails,
      siteCode: arr[0].siteCode,
      siteId: arr[0].siteId,
      installationCapacity: arr[0].installationCapacity,
    });
    setSiteCode(arr[0].siteCode);
    setSiteId(arr[0].siteId);
    setInstalationCapacity(arr[0].installationCapacity);
  };

  const handleEditOpen = async (val) => {
    console.log(val, 'open');
    const responseData = await getSiteConfigBySiteId(val?.siteId);
    console.log(responseData, 'responseData');

    setEdit(true);
    setSiteDetails(responseData);
    setOpen(true);
  };
  const handleSubmit = async (data) => {
    if (siteDetails?.siteName === null) {
      setsiteError(true);
      setFileFreqError(false);
      setModeError(false);
      setFileTypeError(false);
    } else if (fileFrequency === null) {
      setFileFreqError(true);
      setsiteError(false);
      setModeError(false);
      setFileTypeError(false);
    } else if (siteDetails?.siteName === null) {
      setModeError(true);
      setFileTypeError(false);
      setFileFreqError(false);
      setsiteError(false);
    } else if (mode === 'FTP' && filetype === '') {
      setFileTypeError(true);
      setFileFreqError(false);
      setsiteError(false);
      setModeError(false);
    } else {
      setsiteError(false);
      setFileFreqError(false);
      setFileTypeError(false);
      setModeError(false);
      let obj = {
        siteId: siteDetails?.siteId,
        siteCode: siteDetails?.siteCode,
        fileType: siteDetails.fileType,
        modeOfTransfer: siteDetails.modeOfDataTransfer,
        fileFrequency: Number(fileFrequency),
        createdBy: Number(sessionStorage.getItem('id')),
        ftpFolder: folderName
      };
      console.log(obj);

      try {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: 'success',
            message: 'Data Saved Succesfully',
          };
        });
        setSiteId(null);
        setSiteDetails(initalState);
        handleClose();
        const redirectTimeout = setTimeout(() => {
          navigate('/menu/datalogger');
          clearTimeout(redirectTimeout);
        }, 1000);
      } catch (err) {
        console.error(err.message);
      }

      try {
        let responseData = await createFtpDirect(obj);

        if (responseData.status === 200) {
          setSitedropvalue((prevState) =>
            prevState.map((site) =>
              site.siteId === obj.siteId
                ? { ...site, isDirectoryCreated: 1 }
                : site
            )
          );
          setSnack((prev) => {
            return {
              ...prev,
              open: true,
              severity: 'success',
              message: responseData.data,
            };
          });
          setSiteId(null);
          setFiletype('');
          setMode('');
          setSiteName(null);
          handleClose();
        }
      } catch (err) {
        console.error(err.message);
      }
    }
  };
  const handleUpdate = async () => {
    console.log(siteDetails, 'siteDetails');

    const Uploaddata = {
      alarm_Dir: '',
      config_Dir: `Webdyn/${siteDetails.siteCode}/CONFIG`,
      bin_Dir: '',
      cert_Dir: '',
      cmd_Dir: '',
      data_Dir: '',
      log_Dir: `Webdyn/${siteDetails.siteCode}/LOG`,
      script_Dir: `Webdyn/${siteDetails.siteCode}/SCRIPT`,
      def_Dir: `Webdyn/${siteDetails.siteCode}/DEF`,
      modeOfDataTransfer: siteDetails.modeOfDataTransfer,
      fileType: siteDetails.fileType,
      fileFrequency: parseInt(siteDetails.fileFrequency),
      lastUpdatedBy: parseInt(sessionStorage.getItem('id')),
      siteId: siteDetails.siteId,
      site_Configurationid: siteDetails.site_Configurationid,
      ftpFolder: folderName
    };
    try {
      let responseData = await UpdateFTPConfig(Uploaddata);

      if (responseData && responseData.status === 200) {
        setSnack((prev) => ({
          ...prev,
          open: true,
          severity: 'success',
          message: 'Data Updated Successfully',
        }));

        setSiteId(null);
        setFiletype('');
        setSiteDetails(initalState);
        handleClose();

        const redirectTimeout = setTimeout(() => {
          navigate('/menu/datalogger');
          clearTimeout(redirectTimeout);
        }, 1000);
      } else {
        throw new Error('Update failed');
      }
    } catch (err) {
      console.error(err.message);
      setSnack((prev) => ({
        ...prev,
        open: true,
        severity: 'error',
        message: 'Update failed: ' + err.message,
      }));
    }
  };
  // const handleUpdate = async () => {
  //   console.log(siteDetails, "siteDetails");
  //   let responseDate= await UpdateFTPConfig(siteDetails)
  //   try {
  //     setSnack((prev) => {
  //       return {
  //         ...prev,
  //         open: true,
  //         severity: "success",
  //         message: "Data Updated Succesfully",
  //       };
  //     });
  //     setSiteId(null);
  //     setFiletype("");
  //     setSiteDetails(initalState);
  //     handleClose();
  //     const redirectTimeout = setTimeout(() => {
  //       navigate("/menu/datalogger");
  //       clearTimeout(redirectTimeout);
  //     }, 1000);
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const handleConfigureModalOpen = (val) => {
    console.log(val);
    setSiteDetails(val);
    setOpen(true);
  };

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'FTP  Configure', path: 'ftpConfiguration' },
  ];

  const handlefoldername = (event) => {
    // console.log(event);
    setFolderName(event.target.value)

  }

  return (
    <div>
      {' '}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />

          <div>
            <div style={{ marginTop: '-0.5%' }}>
              <TableTemplate
                key={sitename}
                PageName={'FTP  Configure'}
                // addButton={'Add New Ftp'}
                SearchLabel={'Search FTP Here... '}
                header={headCells}
                rowsValue={siteDropDown}
                // handleAddPopupOpen={(val) => handleOpen(val)}
                // handleEditPopupOpen={(val) => handleEditOpen(val)}
                // userRole={ftpConfiguration[0]}
                handleOpenConfigure={(val) => handleConfigureModalOpen(val)}
                handleEditOpenConfigure={(val) => handleEditOpen(val)}
                paths={paths}
                rawData={siteDropDown}
              />
            </div>
          </div>

          {/* --------------------------------Modal popup ------------------------------ */}
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                <Box
                  className={'styleModal'}
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '3%',
                      }}
                    >
                      <Paper
                        elevation={0}
                        style={{
                          width: '101%',
                          borderRadius: '2px 2px 0px 0px',
                          userSelect: 'none',
                          // height: "5vh",
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          container
                          spacing={3}
                          justifyContent="space-between"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Grid
                            item
                            xs={4}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Typography
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '16px',
                                marginLeft: null,
                                userSelect: 'none',
                              }}
                            >
                              FTP configure
                            </Typography>
                          </Grid>
                          <Grid>
                            <div
                              className="header-button"
                              style={{
                                marginTop: '15%',
                              }}
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: '10px',
                                  textTransform: 'capitalize',
                                  borderRadius: '19px',
                                }}
                                onClick={handleClose}
                              >
                                Close
                              </Button>

                              <Button
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: '10px',
                                  textTransform: 'capitalize',
                                  borderRadius: '19px',
                                }}
                                onClick={() => {
                                  edit === true
                                    ? handleUpdate()
                                    : handleSubmit();
                                }}
                              >
                                {edit === true ? 'Update' : 'Save'}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  </>
                  {/* <Divider /> */}

                  <div style={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Grid container spacing={2} rowSpacing={2} columns={16}>
                      <Grid xs={4} md={4}>
                        <div>
                          <Typography>Site Name </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            // value={siteCode}
                            value={siteDetails?.siteName}
                            aria-readonly
                            style={{
                              width: '12vw',
                              backgroundColor: CheckForLight()
                                ? '#f8f8f8'
                                : 'black',
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid xs={4} md={4}>
                        <div>
                          <Typography>Site Code </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            // value={siteCode}
                            value={siteDetails?.siteCode}
                            aria-readonly
                            style={{
                              width: '12vw',
                              backgroundColor: CheckForLight()
                                ? '#f8f8f8'
                                : 'black',
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid xs={4} md={4}>
                        <div>
                          <Typography>Site Capacity </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            value={siteDetails?.installationCapacity}
                            // value={instalationCapacity}
                            style={{
                              width: '12vw',
                              backgroundColor: CheckForLight()
                                ? '#f8f8f8'
                                : 'black',
                            }}
                          />
                        </div>
                      </Grid>
                      {/* <Grid xs={4} md={4}>
                        <div>
                          <Typography>File Frequency </Typography>
                         
                          <FormControl
                            variant="outlined"
                            size="small"
                            style={{ width: "12vw" }}
                          >
                            <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              name="siteName"
                              value={siteDetails?.fileFrequency}
                              onChange={handleChangeFileFrequency}
                              error={siteError}
                            >
                              <MenuItem key={10} value={10}>
                               10
                              </MenuItem>
                            </Select>
                            {siteError && (
                              <Typography variant="caption" color="error">
                                Please select a site
                              </Typography>
                            )}
                          </FormControl>
                        </div>
                      </Grid> */}
                    </Grid>
                  </div>
                  <Divider style={{ marginTop: '2%', marginBottom: '1%' }} />
                  <div style={{ marginTop: '3%', marginLeft: '2%' }}>
                    <Grid
                      container
                      spacing={2}
                      columns={16}
                      style={{ marginBottom: 2 % '' }}
                    >
                      <Grid xs={4} md={4}>
                        <div>
                          <Typography>Mode </Typography>
                          <FormControl
                            variant="outlined"
                            size="small"
                            style={{ width: '12vw' }}
                          >
                            <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={
                                siteDetails?.modeOfDataTransfer
                                  ? siteDetails?.modeOfDataTransfer
                                  : mode
                              }
                              onChange={handleChangeMode}
                              error={modeError}
                              disabled={
                                siteDetails?.modeOfDataTransfer
                                  ? false
                                  : siteDetails?.siteName !== null
                                    ? false
                                    : true
                              }
                            >
                              {fileMode.map((item) => (
                                <MenuItem
                                  key={item.fileModeId}
                                  value={item.fileMode}
                                >
                                  {item.fileMode}
                                </MenuItem>
                              ))}
                            </Select>
                            {modeError && (
                              <Typography variant="caption" color="error">
                                Please select a Mode
                              </Typography>
                            )}
                          </FormControl>
                        </div>
                      </Grid>
                      {mode === 'FTP' ||
                        siteDetails?.modeOfDataTransfer === 'FTP' ? (
                        <>
                          <Grid xs={4} md={4}>
                            <div>
                              <Typography>File Type </Typography>
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{ width: '12vw' }}
                              >
                                <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                                <Select
                                  labelId="demo-simple-select-filled-label"
                                  id="demo-simple-select-filled"
                                  value={siteDetails?.fileType}
                                  onChange={handleChangeFile}
                                >
                                  {fileType.map((item) => (
                                    <MenuItem
                                      key={item.fileTypeId}
                                      value={item.fileType}
                                    >
                                      {item.fileType}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {filetypeError && (
                                  <Typography variant="caption" color="error">
                                    Please select a File Type
                                  </Typography>
                                )}
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <div>
                              <Typography>FTP User Name </Typography>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={'eira_hs_qa'}
                                style={{
                                  width: '12vw',
                                  backgroundColor: CheckForLight()
                                    ? '#f8f8f8'
                                    : 'black',
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <div>
                              <Typography>FTP Password </Typography>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={'hs@123'}
                                style={{
                                  width: '12vw',
                                  backgroundColor: CheckForLight()
                                    ? '#f8f8f8'
                                    : 'black',
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <div>
                              <Typography>FTP Host Url </Typography>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={'http://3.109.2.47'}
                                style={{
                                  width: '12vw',
                                  backgroundColor: CheckForLight()
                                    ? '#f8f8f8'
                                    : 'black',
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <div>
                              <Typography>FTP Folder Name </Typography>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"

                                onChange={(e) => handlefoldername(e)}
                                // defaultValuevalue={folderName}
                                style={{
                                  width: '12vw',

                                }}
                              />
                            </div>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </div>

                  {/* ---------------------------------------- FTP configure ----------------------------------- */}
                  <div style={{ marginTop: '3%', marginLeft: '2%' }}>
                    {siteDetails?.modeOfDataTransfer === 'FTP' ||
                      mode === 'FTP' ? (
                      <div>
                        <Paper elevation={0} style={{ padding: '1%' }}>
                          <Box
                            sx={{ flexGrow: 1, marginLeft: '5%', width: '55%' }}
                          >
                            <div>
                              <List>
                                {listItem?.map((item, index) => (
                                  <ListItem
                                    disablePadding
                                    key={index}
                                    style={{ marginBottom: '2%' }}
                                  >
                                    <ListItemText primary={item.name} />
                                    <ListItemText style={{ marginLeft: '5%' }}
                                      primary={`${siteDetails?.siteName} / ${item.name}`}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Box>
                        </Paper>
                      </div>
                    ) : null}
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ftp;
