import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  CircularProgress,
  Card,
  CardHeader,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import "../../Common.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { CheckForLight } from "../util/common/CommanFunction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { alermsview } from "../util/TextField";
// ========Modal================
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TableTemplate from "../../Template/TableTemplate";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { FadeLoader } from "react-spinners";
import { AlarmsSchema } from "../util/ValidateSchema";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

/*------------api call-----------------*/
import {
  fetchSiteEquipment,
  fetchSiteAlerms,
  fetchSiteAlermsfilter,
} from "../../Api/SiteListApi";
import {
  EquipmentlistDropdown,
  EquipmentTypelistDropdown,
  fetchSiteDetails,
  fetchTicketCategory,
  fetchTicketState,
  CategorylistDropdown,
  AssignlistDropdown,
  postticketdata,
  fetchSiteDetail,
  fetchAssignDetail,
} from "../../Api/TicketApi";
import Download from "../../Template/Excel/Download";
import errorMsg from "../util/errorMessage.json";
import CustomSnackbar from "../util/components/CustomSnackbar";
import {
  getFormatDate,
  removeQuestionMark,
  roundedUp,
} from "../util/common/CommanFunction";
import { addDays } from "date-fns";
import { decryptPaddedQuery } from "../util/security/Cipher";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ticketConfigField } from "../util/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export default function Alerms({ Multisite }) {
  const initialState = {
    siteName: "",
    siteId: ",",
    mobile: "",
    postalCode: "",
    siteCode: "",
    priority: "",
    equipmentType: "",
    equipmentName: "",
    equipmentCategory: "",
  };
  const equipmentHeadCells = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
    },
    {
      label: "Error Code",
      id: "errorCode",
      view: true,
    },
    {
      label: "Error Message",
      id: "errorMessage",
      view: true,
    },
    {
      label: "Event Time",
      id: "lastOccurence",
      view: true,
    },
    {
      label: "Occurrence",
      id: "eventOccurence",
      view: true,
    },
    {
      label: "Priority",
      id: "priority",
      view: true,
    },
    {
      label: "Capacity",
      id: "dcCapacity",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];

  const userDefinedHeadCells = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
    },
    {
      label: "Error Code",
      id: "errorCode",
      view: true,
    },
    {
      label: "Error Message",
      id: "errorMessage",
      view: true,
    },
    {
      label: "Event Time",
      id: "lastOccurence",
      view: true,
    },
    {
      label: "Occurrence",
      id: "eventOccurence",
      view: true,
    },
    {
      label: "Priority",
      id: "priority",
      view: true,
    },
    {
      label: "Capacity",
      id: "dcCapacity",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`${queryParam}`);
  const params = {};

  for (const [key, value] of queryParams) {
    params[key] = value;
  }

  let SiteId = location?.state?.id,
    flog;

  const [loading, setLoading] = useState(true);
  const [headcells, setHeadCells] = useState(equipmentHeadCells);
  const [selectedTab, setSelectedTab] = useState("all");
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [siteEquipment, setSiteEquipment] = useState([]);
  const [siteAlarms, setSiteAlerms] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [value, setvalue] = useState(siteEquipment);
  const [snack, setSnack] = useState(initialSnack);
  const [openfilter, setopenfilter] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [toBackend, setToBackend] = useState(false);
  const [tableToShow, setTableToShow] = useState("equipmetName");
  const [text, setTextField] = useState([]);
  const [textt, setTextFieldd] = useState([]);
  const [Data, setData] = useState(true);
  const [stdField, setstdField] = useState({});
  const [todate, setTodate] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState();
  console.log(fromDate);
  console.log(todate);

  const [Sitefilter, setSitefilter] = useState();
  const [filter, setfilter] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [equipmevalue, setEquipmevalue] = useState([]);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [Sitecategory, setSitecategory] = useState([]);
  const [state, setstate] = useState([]);
  const [Assign, setAssignevalue] = useState([]);
  const [ticketstatus, setAssignuser] = useState([]);

  const [EquipmetId, setEquipmentId] = useState([]);
  const [equipmentName,setEquipmentName]=useState([])
  console.log(equipmentName);
  
  const [ticketFields, setTicketFields] = useState(initialState);

  const handleMultipleChange = (event, value, name) => {
    setTicketFields({ ...ticketFields, [name]: value });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "15ch",
      },
    },
  };

  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
  };

  const Item = styled(Paper)(({ theme }) => ({}));
  const hasNonEmptyValue = Object.values(ticketFields).some(
    (value) => value !== ""
  );

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    setvalue(siteEquipment);
  }, [siteEquipment]);
  console.log(todate ? addDays(todate, 1).toISOString().substring(0, 10) : "");
  const handleChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (ticketFields.ticketTypeName !== '') {
      let NameID = equipmevalue?.filter((data) => {
        return data.ticketTypeName === ticketFields?.ticketTypeName;
      });
      getEquipmentcategory(NameID[0]?.ticketTypeId);
    }
  }, [ticketFields.ticketTypeName]);

  const handleTxtChange = (event, name) => {
    console.log(name, event);
    setTicketFields({ ...ticketFields, [name]: event.target.value });
  };
console.log(ticketFields);


  useEffect(() => {
    let data = ticketConfigField();
    setTextField(Data);
  });

  useEffect(() => {
    if (ticketFields.equipmentType === 'Maintenance') {
      getEquipmentcategory(2);
    } else if (ticketFields.equipmentType === 'Operation') {
      getEquipmentcategory(1);
    }
  }, [ticketFields]);

  const handleDropdownChange = (event, value, name) => {
    console.log(value);
    
    if (name==="siteName"){
      handleassignvalue( value)
    }
  
    console.log(name==="siteName",value);
    if (name === 'equipmentType') {
      setTicketFields({
        ...ticketFields,
        equipmentType: value,
        equipmentName: null,
        equipementArray: null,
      });
    } else {
      setTicketFields({ ...ticketFields, [name]: value });
    }
  };

  // const handleDropdownChange = (event, value, name) => {
  //   if (name === "equipmentType") {
  //     setTicketFields({
  //       ...ticketFields,
  //       equipmentType: value,
  //       equipmentName: null,
  //       equipementArray: null,
  //     });
  //   } else {
  //     setTicketFields({ ...ticketFields, [name]: value });
  //   }
  // };

  const handledatepicchange = (date, name) => {
    console.log(date.toString());
    setFromDate(date.toString());
  };

  const handledatepic = (date, name) => {
    console.log(date.toString());
    setTodate(date.toString());
  };

  useEffect(() => {
    handlefetchData();
    getEquipment()
  }, []);

  /*------------------------- Api --------------------------------------------*/

  useEffect(() => {
    getSiteCall();
    fetchEquipmenttype();
    fetchstateCall();
    fetchCategoryCall();
    setLoading(false);
    fetchCategoryCall();
    // fetchAssignDetails()

    handlefetchData(params.id);
  }, [params.id]);

  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchCategoryCall = async (id) => {
    try {
      let data = await fetchTicketCategory(id);
      setSitecategory(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchstateCall = async (id) => {
    try {
      let data = await fetchTicketState(id);
      setstate(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchEquipmenttype = async () => {
    try {
      let data = await EquipmentTypelistDropdown();
      setEquipmevalue(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getEquipmentcategory = async (Id) => {
    try {
      let data = await CategorylistDropdown(Id);
      setCategoryvalue(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleassignvalue=async(value)=>{
    console.log(value);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === value;
    });
    let siteId=siteIdFilter[0]?.siteId
    try {
      let data = await AssignlistDropdown(siteId);
      setAssignevalue(data);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (ticketFields.siteName !== "") {
      let SitenameID = siteIdName?.filter((data) => {
        return data.siteName === ticketFields?.siteName;
      });

      getEquipmentCall(SitenameID[0]?.siteId);
    }
  }, [ticketFields.siteName]);

  const getEquipmentCall = async (Id) => {
    try {
      let data = await EquipmentlistDropdown(Id);
      setEquipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const getEquipment = async () => {
    try {
      let data = await EquipmentlistDropdown(params.id);
      setEquipmentName(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handlefetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchSiteEquipment(params.id);
      console.log(data);

      data?.forEach((val) => {
        if (val?.lastOccurence) {
          const date = new Date(val?.lastOccurence);
          val.lastOccurence = date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
            .replace(/\//g, "-")
            .replace(/,/g, " ");
        }
      });

      if (Array.isArray(data)) {
        setSiteEquipment(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteEquipment([data]);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false); // Ensure loading is stopped even on error
    }
  };

  console.log(siteEquipment, "siteEquipment");
  /*-------------------------Table get call --------------------------------------------*/
  console.log(location, "location");

  console.log(siteEquipment, "dfghyujik");
  console.log(ticketFields?.priority);

  const handleAlermsfilter = async () => {
    let PostData = {
      siteId: params.id,
      fromDate: fromDate
        ? addDays(fromDate, 1).toISOString().substring(0, 10)
        : "",
      toDate: todate ? addDays(todate, 1).toISOString().substring(0, 10) : "",
      Priority:
        ticketFields?.priority === "Medium"
          ? "2"
          : ticketFields?.priority === "Low"
          ? "1"
          : ticketFields?.priority === "High"
          ? "3"
          : "",
    };
    console.log(PostData);
    
    try {
      const data = await fetchSiteAlermsfilter(PostData);
      data?.forEach((val) => {
        if (val?.lastOccurence) {
          const date = new Date(val?.lastOccurence);
          val.lastOccurence = getFormatDate(date);
        }
        if (val?.dcCapacity) {
          val.dcCapacity = roundedUp(val?.dcCapacity);
        }
      });
      if (Array.isArray(data)) {
        setSitefilter(data);
        setfilter(true);
      } else if (typeof data === "object" && data !== null) {
        setSitefilter([data]);
      }

      handlefilterClose();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchDataTable = async () => {
      try {
        const data = await fetchSiteAlerms(params.id);
        if (Array.isArray(data)) {
          setSiteAlerms(data);
        } else if (typeof data === "object" && data !== null) {
          setSiteAlerms([data]);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchDataTable();
  }, []);
  console.log(siteAlarms, "siteAlarms");

/*------------------handleSave---------------------*/
const handleSave = async (value) => {
  let siteIdFilter = siteIdName?.filter((data) => {
    return data.siteName === ticketFields.siteName;
  });

  let equipmentnameIdFilter = EquipmetId?.filter((data) => {
    return data.displayName === ticketFields.equipmentName;
  });

  let equipmenttypeIdFilter = equipmevalue?.filter((data) => {
    return data.ticketTypeName === ticketFields.equipmentType;
  });

  let categoryIdFilter = categoryvalue?.filter((data) => {
    return data.categoryName === ticketFields?.equipmentCategory;
  });

  let siteIds = [];

  if (ticketFields.equipmentType === 'Maintenance') {
    ticketFields.equipementArray.forEach((name) => {
      let equipmentnameIdFilter = EquipmetId.filter((data) => {
        if (data.displayName === name) {
          siteIds.push(data.equipmentId);
        }
      });
    });
  }
  let data = {
    siteId: isFromSiteList ? params.id : siteIdFilter[0]?.siteId,
    equipmentId:
      ticketFields.equipmentType !== 'Maintenance'
        ? [equipmentnameIdFilter[0]?.equipmentId]
        : siteIds,
    ticketTypeId: equipmenttypeIdFilter[0]?.ticketTypeId,
    ticketCategory: categoryIdFilter[0]?.categoryId,
    // subject: value?.subject,
    subject: ticketFields?.subject,
    priority:
      ticketFields.priority === 'Medium'
        ? 2
        : ticketFields.priority === 'High'
        ? 3
        : 1,
    description: ticketFields?.description,
    createdBy: Number(sessionStorage.getItem('id')),
  };

  try {
    setToBackend(true);
    const responseData = await postticketdata(data);
console.log(responseData);

    if (responseData.status === 200) {
      handleAlermsfilter();
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.data.statusDescription,
      });
      handleClose();
      setToBackend(false);
      setOpen(false);
      return 1;
    }
  } catch (e) {
    setSnack(errorMsg.failure);
    console.error('Error:', e.response.status);
    setToBackend(false);
    return 0;
  }
};


  /*---------------------------------- New Report Call -----------------------*/

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "Alarms");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const handlefilter = () => {
    setopenfilter(true);
    setFromDate();
    setTodate();
  };

  const handleOpenModal = () => {
    // setOpen(true);
    setTimeout(() => {
      setOpen(true);
    }, 0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlefilterClose = () => {
    // setOpen(false);
    setAnchorEl(null); // Clear the anchor when closing
    setOpenTicket(false);
    setopenfilter(false);
    setFromDate();
    setTodate();
  };

  const handleEditOpen = async (alarm) => {
    setSelectedAlarm(alarm);
    setOpenTicket(true);
  
    // Find the matching site
    const matchingSite = siteIdName.find(site => site.siteId === alarm.siteId);
    
    if (matchingSite) {
      setTicketFields(prevFields => ({
        ...prevFields,
        siteName: matchingSite.siteName,
        // Add other fields from the alarm object as needed
        equipmentType: alarm.equipmentType,
        equipmentName: alarm.equipmentName,
        // priority: alarm.priority,
      }));
    }
  
    // Log for debugging
    console.log("Opening edit for alarm:", alarm);
  };


  // const handleEditOpen = async (siteId) => {
  //   setOpenTicket(true);
  // };

  const handleChangeTab = (newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case "all":
        if (siteEquipment.length > 0) {
          setHeadCells(equipmentHeadCells);
          setvalue(siteEquipment);
        } else {
          setHeadCells([]);
          setvalue([]);
          setTableToShow("equipmetName");
        }

        break;
      case "active":
        if (siteAlarms.length === 0) {
          setHeadCells([]);
          setvalue([]);
          setTableToShow("userdefine");
        } else {
          setHeadCells(userDefinedHeadCells);
          setvalue(siteAlarms);
        }
        break;
      default:
        setHeadCells(equipmentHeadCells);
        setvalue(siteEquipment);
        break;
    }
  };
  console.log(selectedTab, "selectedTab");
  const Total = siteEquipment.length;

  const alarmCount = siteAlarms.length;
  console.log(alarmCount, "alermCountsss");

  const tabsData = [
    { name: "Equipment ", value: "all", badgeCount: Total },
    { name: "User Defined", value: "active", badgeCount: alarmCount },
  ];

  // console.log(tabsData, 'tvalueb');

  const isFromSiteList = Object.keys(params).length > 0;

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Site List", path: -1 },
    { label: "Alarms", path: "alarmsconfig" },
  ];
  useEffect(() => {
    let data = alermsview();
    setTextFieldd(data);
  }, []);
  // function getOptions(propName) {
  //   let option = [];
  //   switch (propName) {
  //     case "priority":
  //       return (option = Priority?.map((option) => option.label));
  //   }
  // }
  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "equipmentCategory":
        return categoryvalue?.map((option) => option.categoryName);
      case "equipmentType":
        return (option = equipmevalue?.map((option) => option.ticketTypeName));
      case "equipmentName":
        return (option = EquipmetId?.map((option) => option.displayName));
        case "equipment":
          return (option = equipmentName?.map((option) => option.displayName));
      case "priority":
        return (option = Priority?.map((option) => option.label));

      case "ticketcategory":
        return (option = Sitecategory?.map((option) => option.categoryName));
      case "ticketstate":
        return (option = state?.map((option) => option.stateName));
      case "assignedTo":
        return (option = Assign?.map((option) => option.userName));
      case "ticketStatus":
        return (option = ticketstatus?.map((option) => option.statusName));
      default:
    }
    return option;
  }
  const Priority = [
    { label: "High", value: "3" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "1" },
  ];

  const table = filter === true ? Sitefilter : value;
  console.log(table);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* ---------------pass props value----------- */}

          <div>
            <Formik
              key={"edit"}
              enableReinitialize={true}
              initialValues={ticketFields}
              // validationSchema={AlarmsSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = {
                  siteName: values.siteName,
                  reportName: values.reportName,
                  mailId: values.mailId,
                  status: values.status,
                  timePeriod: values.timePeriod,
                };
                if (edit) {
                  // const result = await handleUpdateForm(submitValue);

                  // if (result === 1) {
                  //   resetForm();
                  // }
                } else {
                  const result = await handleSave(submitValue);

                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  {openfilter ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openfilter}
                      closeAfterTransition
                    >
                      <Fade in={openfilter}>
                        <Box
                          className={"styleModalSmall"}
                          style={{ height: "50vh" }}
                        >
                          <Card
                            sx={{
                              borderRadius: "20px",
                              height: "fit-content",
                            }}
                          >
                            <CardHeader
                              sx={{
                                padding: "20px 20px 20px 20px",
                                background: CheckForLight()
                                  ? "rgb(246, 248, 252)"
                                  : "#4f4f4f",
                              }}
                              action={
                                <Stack spacing={2} direction="row">
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    startIcon={<SaveIcon />}
                                    onClick={handleAlermsfilter}
                                    disabled={!(fromDate && todate)}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={handlefilterClose}
                                    startIcon={<CancelIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                              }
                              title={"Customize Filters"}
                            />
                            <Divider sx={{ borderColor: "#888" }} />
                            <div
                              style={{
                                height: "27vh",
                                padding: "20px",
                              }}
                            >
                              <Grid container spacing={2} columns={16}>
                                {textt?.length > 0
                                  ? textt?.map((data, index) => (
                                      <Grid item xs={4}>
                                        <div key={index}>
                                          {data.type === "textField" ? (
                                            <>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <Field
                                                as={TextField}
                                                disabled={toBackend}
                                                id={`outlined-basic-${index}`}
                                                size="small"
                                                variant="outlined"
                                                name={data.name}
                                                inputProps={{
                                                  maxLength: data.length,
                                                }}
                                                placeholder={data.placeholder}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  handleTxtChange(e);
                                                }}
                                                sx={{ width: "12vw" }}
                                              />
                                              <ErrorMessage
                                                name={data.name}
                                                component="div"
                                                className="error"
                                                style={{
                                                  color: "red",
                                                  marginTop: "1%",
                                                  textAlign: "left",
                                                  marginLeft: "0%",
                                                }}
                                              />
                                            </>
                                          ) : data.type === "dropdown" ? (
                                            <>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <Tooltip>
                                                <Field
                                                  render={({ field, form }) => (
                                                    <Tooltip>
                                                      <Autocomplete
                                                        options={getOptions(
                                                          data.name
                                                        )}
                                                        size="small"
                                                        id={`combo-box-demo-${index}`}
                                                        onChange={(
                                                          event,
                                                          value
                                                        ) =>
                                                          handleDropdownChange(
                                                            event,
                                                            value,
                                                            data.name
                                                          )
                                                        }
                                                        sx={{ width: "12vw" }}
                                                        ListboxProps={{
                                                          style: {
                                                            maxHeight: "200px", // Set your desired height here
                                                          },
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                          />
                                                        )}
                                                      />
                                                    </Tooltip>
                                                  )}
                                                />
                                              </Tooltip>
                                              <ErrorMessage
                                                name={data.name}
                                                component="div"
                                                className="error"
                                                style={{
                                                  color: "red",
                                                  marginTop: "1%",
                                                  textAlign: "left",
                                                  marginLeft: "0%",
                                                }}
                                              />
                                            </>
                                          ) : data.type === "multidropdown" ? (
                                            <>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={getOptions(data.name)} 
                                                onChange={(event, value) => {
                                                  handleDropdownChange(
                                                    event,
                                                    value,
                                                    data.name
                                                  );
                                                  if (
                                                    value.includes("Select All")
                                                  ) {
                                                    const allOptions =
                                                      getOptions(data.name); 
                                                    const newValue =
                                                      value.length ===
                                                      allOptions.length
                                                        ? []
                                                        : allOptions;
                                                    handleDropdownChange(
                                                      event,
                                                      newValue,
                                                      data.name
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  display: "flex",
                                                }}
                                                disableCloseOnSelect
                                                size="small"
                                                sx={{
                                                  width: "12vw",
                                                  height: "40px",
                                                  marginRight: 8,
                                                }}
                                                // defaultValue={
                                                //   edit && data.name === "siteName"
                                                //     ? editcall?.siteName
                                                //       ? [editcall.siteName]
                                                //       : []
                                                //     : edit && data.name === "parameterName"
                                                //     ? Array.isArray(editcall?.parameterName)
                                                //       ? editcall.parameterName
                                                //       : editcall?.parameterName
                                                //       ? [editcall.parameterName]
                                                //       : []
                                                //     : []
                                                // }
                                                renderOption={(
                                                  props,
                                                  option,
                                                  { selected }
                                                ) => {
                                                  const {
                                                    key,
                                                    ...optionProps
                                                  } = props;
                                                  return (
                                                    <li
                                                      key={key}
                                                      {...optionProps}
                                                    >
                                                      <Checkbox
                                                        icon={icon}
                                                        checkedIcon={
                                                          checkedIcon
                                                        }
                                                        checked={selected}
                                                      />
                                                      {option}
                                                    </li>
                                                  );
                                                }}
                                                renderInput={(params) => {
                                                  console.log(
                                                    params.inputProps
                                                  );

                                                  return (
                                                    <TextField
                                                      {...params}
                                                      size="small"
                                                      placeholder={
                                                        params.inputProps
                                                          .value === 0
                                                          ? data.name
                                                          : ""
                                                      }
                                                      InputProps={{
                                                        ...params.InputProps,
                                                        style: {
                                                          height: "100%",
                                                        },
                                                      }}
                                                      onChange={(event) => {
                                                        console.log(
                                                          "Input value on change:",
                                                          event.target.value
                                                        );
                                                        if (
                                                          params.InputProps
                                                            .onChange
                                                        ) {
                                                          params.InputProps.onChange(
                                                            event
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  );
                                                }}
                                                renderTags={(value) => {
                                                  if (value.length === 0)
                                                    return null;
                                                  const truncatedValue =
                                                    value[0].length > 15
                                                      ? value[0].substring(
                                                          0,
                                                          15
                                                        ) + "..."
                                                      : value[0];
                                                  return (
                                                    <div
                                                      style={{
                                                        maxWidth: "12vw",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        padding: "5px",
                                                        marginRight: 5,
                                                      }}
                                                    >
                                                      {truncatedValue}{" "}
                                                      {value.length > 1
                                                        ? `+${value.length - 1}`
                                                        : ""}
                                                    </div>
                                                  );
                                                }}
                                                noOptionsText="No options"
                                              />

                                              <ErrorMessage
                                                name={data.name}
                                                component="div"
                                                className="error"
                                                style={{
                                                  color: "red",
                                                  marginTop: "1%",
                                                  textAlign: "left",
                                                  marginLeft: "0%",
                                                }}
                                              />
                                            </>
                                          ) : data.type === "date" ? (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <DatePicker
                                                size="small"
                                                sx={{
                                                  width: "12vw",
                                                }}
                                                name={data.name}
                                                onChange={(e) => {
                                                  handledatepicchange(
                                                    e,
                                                    data.name
                                                  );
                                                }}
                                                slotProps={{
                                                  textField: {
                                                    size: "small",
                                                  },
                                                }}
                                              />
                                            </LocalizationProvider>
                                          ) : data.type === "datee" ? (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>

                                              <DatePicker
                                                size="small"
                                                sx={{
                                                  width: "12vw",
                                                }}
                                                // minDate={fromDate}

                                                shouldDisableDate={(date) =>
                                                  dayjs(date).isBefore(
                                                    dayjs(fromDate),
                                                    "day"
                                                  )
                                                }
                                                name={data.name}
                                                onChange={(e) => {
                                                  handledatepic(e, data.name);
                                                }}
                                                slotProps={{
                                                  textField: {
                                                    size: "small",
                                                  },
                                                }}
                                              />
                                            </LocalizationProvider>
                                          ) : null}
                                        </div>
                                      </Grid>
                                    ))
                                  : null}
                              </Grid>
                            </div>
                          </Card>
                        </Box>
                      </Fade>
                    </Modal>
                  ) : null}

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalstyle}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "30px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h4"
                              className="modal-head-alarms"
                              style={{ marginTop: "2%" }}
                            >
                              {edit ? " Update Ticket " : "Add New Ticket"}
                            </Typography>
                          </div>
                          <div>
                            <Stack spacing={2} direction="row">
                              <CloseIcon
                                onClick={() => handleClose()}
                                style={{ cursor: "pointer" }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <Divider sx={{ borderColor: "#888" }} />
                        <div style={{ padding: "20px", marginLeft: "14%" }}>
                          <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            style={{ marginTop: "2%" }}
                          >
                            <Grid item xs={8}>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Site Name *
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                <Autocomplete
  disableClearable
  disablePortal
  size="small"
  options={siteIdName.map(site => site.siteName)}
  value={ticketFields.siteName || ''}
  onChange={(event, value) => handleDropdownChange(event, value, "siteName")}
  sx={{ width: "15vw" }}
  disabled={true}  // Always disabled as we're forcing a single option
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder={"E.g: IFP"}
      value={ticketFields.siteName || ''}
    />
  )}
  classes={{
    option: "autocomplete",
  }}
/>
                                  {/* <Autocomplete
                                    disableClearable
                                    disablePortal
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("siteName")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "siteName"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    disabled={edit ? ticketFields.siteName : ""}
                                    value={ticketFields.siteName}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: IFP"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  /> */}
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Ticket Type *
                                  </Typography>
                                </Grid>

                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    size="small"
                                    options={getOptions("equipmentType")}
                                    disabled={edit ? ticketFields.siteName : ""}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "equipmentType"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    value={ticketFields.equipmentType}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"E.g: Operation"}
                                      />
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              {/* Operation & Maintenance */}
                              {ticketFields?.equipmentType === "Operation" && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Grid xs={6}>
                                    <Typography
                                      style={{
                                        textTransform: "capitalize",
                                        opacity: "0.8",
                                      }}
                                    >
                                      Equipment Name *
                                    </Typography>
                                  </Grid>
                                  <Grid xs={2}>
                                    <Autocomplete
                                      size="small"
                                      disableClearable
                                      disabled={
                                        edit ? ticketFields.equipmentName : ""
                                      }
                                      value={ticketFields.equipmentName}
                                      options={getOptions("equipmentName")}
                                      onChange={(event, value) =>
                                        handleDropdownChange(
                                          event,
                                          value,
                                          "equipmentName"
                                        )
                                      }
                                      sx={{ width: "15vw" }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: INV-01"}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </div>
                              )}
                              {ticketFields?.equipmentType ===
                                "Maintenance" && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Grid xs={6}>
                                    <Typography
                                      style={{
                                        textTransform: "capitalize",
                                        opacity: "0.8",
                                      }}
                                    >
                                      Equipment Name *
                                    </Typography>
                                  </Grid>

                                  <Grid xs={2}>
                                    <Select
                                      sx={{ width: "15vw" }}
                                      size="small"
                                      disabled={!ticketFields.siteName}
                                      // labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={ticketFields.equipementArray || []}
                                      onChange={(event) =>
                                        handleMultipleChange(
                                          event,
                                          event.target.value,
                                          "equipementArray"
                                        )
                                      }
                                      input={<OutlinedInput />}
                                      renderValue={(selected) => {
                                        if (selected.length === 0) {
                                          return (
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                color: "lightgray",
                                              }}
                                            >
                                              E.g: INV-01
                                            </Typography>
                                          );
                                        }
                                        return selected.join(", ");
                                      }}
                                      displayEmpty
                                      MenuProps={MenuProps}
                                    >
                                      {EquipmetId.length !== 0 ? (
                                        EquipmetId.map((data) => (
                                          <MenuItem
                                            key={data.displayName}
                                            value={data.displayName}
                                          >
                                            <Checkbox
                                              checked={
                                                ticketFields.equipementArray?.indexOf(
                                                  data.displayName
                                                ) > -1
                                              }
                                            />
                                            <ListItemText
                                              primary={data.displayName}
                                            />
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem disabled>
                                          <Typography
                                            variant="body1"
                                            sx={{ color: "gray" }}
                                          >
                                            No Options
                                          </Typography>
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </Grid>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Ticket Category *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    disabled={!ticketFields.equipmentType}
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("equipmentCategory")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "equipmentCategory"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    // value={values[data.name]}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: Inverter Down"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Subject *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <TextField
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Subject"
                                    variant="outlined"
                                    sx={{ width: "15vw" }}
                                    // error={!validEmail}
                                    value={ticketFields.subject}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleTxtChange(e, "subject");
                                    }}
                                  />
                                  <ErrorMessage
                                    name={"subject"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Priority *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("priority")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "priority"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    value={ticketFields.priority}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: High"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Description *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <div>
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      placeholder="Description"
                                      variant="outlined"
                                      sx={{ width: "15vw" }}
                                      // error={!validEmail}
                                      value={ticketFields.description}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleTxtChange(e, "description");
                                      }}
                                    />
                                    <ErrorMessage
                                      name={"description"}
                                      component="div"
                                      className="errorStyle"
                                      style={{
                                        color: "red",
                                        marginTop: "1%",
                                        textAlign: "left",
                                        marginLeft: "0%",
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{
                              justifyContent: "flex-end",
                            }}
                          >
                            <Item>
                              {edit ? (
                                <div>
                                  <Button
                                    style={{ textTransform: "capitalize" }}
                                    variant="contained"
                                    disabled={
                                      !(
                                        ticketFields?.description &&
                                        ticketFields?.subject
                                      )
                                    }
                                    onClick={handleSubmit}
                                  >
                                    Update
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    style={{ textTransform: "capitalize" }}
                                    disabled={
                                      !(
                                        ticketFields?.siteName &&
                                        ticketFields?.priority &&
                                        ticketFields?.equipmentCategory &&
                                        ticketFields?.description &&
                                        ticketFields?.subject &&
                                        (ticketFields.equipmentType !==
                                          "Maintenance" ||
                                          ticketFields.equipementArray) &&
                                        (ticketFields.equipmentType !==
                                          "Operation" ||
                                          ticketFields.equipmentName)
                                      )
                                    }
                                    variant="contained"
                                    onClick={handleSubmit}
                                  >
                                    {toBackend ? "Creating..." : "Create "}
                                  </Button>
                                </div>
                              )}
                            </Item>
                            {edit ? (
                              <Item></Item>
                            ) : (
                              <Item>
                                <Button
                                  style={{ textTransform: "capitalize" }}
                                  variant="contained"
                                  disabled={!hasNonEmptyValue}
                                  onClick={() => {
                                    handleReset();
                                    setTicketFields(initialState);
                                  }}
                                >
                                  Reset
                                </Button>
                              </Item>
                            )}
                          </Stack>
                        </div>
                      </div>
                    </Box>
                  </Modal>

                  <Menu
                    open={openTicket}
                    anchorEl={anchorEl} // Set the anchor element to position the menu
                    onClose={handlefilterClose}
                    anchorOrigin={{
                      vertical: "bottom", // Adjust the vertical positioning (below the icon)
                      horizontal: "right", // Adjust the horizontal positioning (right aligned)
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleOpenModal();
                        handlefilterClose();
                      }}
                      style={{
                        textTransform: "capitalize",
                        // padding: "15px",
                        color: "#1976D2",
                      }}
                    >
                      <Typography
                        style={{ marginLeft: "3%", fontSize: "14px" }}
                      >
                        Ticket
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                        // padding: "15px",
                        color: "#d32f2f",
                      }}
                    >
                      <Typography
                        style={{ marginLeft: "3%", fontSize: "14px" }}
                      >
                        Suppress
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Form>
              )}
            </Formik>
          </div>

          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <div>
              <TableTemplate
                PageName={"Alarms"}
                // addButton={'Alarme'}
                SearchLabel={"Search Alarms Here... "}
                header={headcells}
                rowsValue={table}
                handleChange={handleChangeTab}
                userRole={Multisite[0]}
                // handleEditPopupOpen={(val) => handleEditOpen(val)}
                handleEditPopupOpen={(alarm) => handleEditOpen(alarm)}
                handleDownloadExcel={(val) => {
                  handleDownloadTable(val);
                }}
                handlefilterPopupOpen={(val) => handlefilter(val)}
                tabsData={tabsData}
                rawData={siteEquipment}
                tableToShow={tableToShow}
                paths={paths}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
