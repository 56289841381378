import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  Divider,
  Stack,
  Typography,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import Apexchart from "../../Components/AnalyticsDashboard/ApexexChart";
import "../Style.css";
import {
  ActualVsExpected,
  fetchDailyGenerationEira,
  fetchSpecificYieldEira,
  GetSpecificYieldVsIrradiationEqp,
} from "../../../Api/DataAnalystApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CheckForLight,
  roundedUp,
  sortedDataByMonth,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import CombinedChart from "../../Charts/CombainedChart";
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import BarChart from "../../Charts/BarChart";

const timer = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" }, // { label: 'This Month', value: 'This Month' },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
];

export const ContactAnalytics = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [date, setDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [timerOptions, setTimerOptions] = useState(timer);
  const [timerValue, setTimerValue] = useState(5);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Daily Generation");
    setSelectedOption("Daily Generation");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setAnchorTimerEl(event.currentTarget);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };
  const handleMenuItemClick = async (value) => {
    setTimerValue(value);
    ApplyChange({ time: value });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setDate(today);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchDailyGenerationEira(dataModel);

    setLoading(false);
    setTableData(responseData);
    if (dataModel?.range === "daily") {
      let seriesData = [];
      const irradiationSeries = {
        name: "irradiation",
        data: [],
      };
      const todayEnergySeries = {
        name: "todayenergy",
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timeStamp = new Date(dataPoint.timeStamp).getTime();
        irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
        todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
    }
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const dataFrequency = value.time ? value.time : timerValue;
    const data = {
      fromDate: value.date ? value.date : date,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.date ? value.date : date,
      range: "daily",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(data);
    setLoading(true);
    const responseData = await fetchDailyGenerationEira(data);
    setLoading(false);
    setTableData(responseData);
    let seriesData = [];
    const irradiationSeries = {
      name: "irradiation",
      data: [],
    };
    const todayEnergySeries = {
      name: "todayenergy",
      data: [],
    };
    responseData.forEach((dataPoint) => {
      const timeStamp = new Date(dataPoint.timeStamp).getTime();
      irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
      todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
    });
    seriesData.push(irradiationSeries);
    seriesData.push(todayEnergySeries);
    setMultichartData(seriesData);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (data) => {
    let newDate;
    const currentDate = moment(date);

    if (data === "left") {
      newDate = currentDate.subtract(1, "days");
    } else if (data === "right") {
      newDate = currentDate.add(1, "days");
    } else {
      return; // Do nothing if data is not 'left' or 'right'
    }

    const today = moment().startOf("day");

    if (newDate.isAfter(today)) {
      // If the new date is greater than today, set the date to today
      newDate = today;
    }

    const formattedDateTime = newDate.format("YYYY-MM-DD");
    setDate(formattedDateTime);
    ApplyChange({ date: formattedDateTime });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Daily Generation
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {moment(date).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(date).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event)}
                  disabled={chartName !== "Daily Generation"}
                  sx={{
                    width: "40px",
                  }}
                >
                  <Badge
                    badgeContent={timerValue}
                    color="default"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                        opacity: chartName !== "Daily Generation" ? 0.5 : 1,
                        pointerEvents:
                          chartName !== "Daily Generation" ? "none" : "auto",
                        fontSize: "20px",
                      }}
                    />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorTimerEl}
                  open={Boolean(anchorTimerEl)}
                  onClose={handleCloseTimer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {timerOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleMenuItemClick(option.value, "Timer")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 || multiChartData.length === 0 ? (
          fallBack
        ) : (
          <Apexchart
            chartName={"Daily Generation"}
            tableData={tableData}
            multiChartData={multiChartData}
            key="1"
            name="chartData"
            widgetName={` `}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics2 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const chartType = [
    { label: "Daily Generation", value: "daily_generation" },
    { label: "Specific Yield", value: "specific_yield" },
  ];

  useEffect(() => {
    setSite(siteName);
    setChartName("Daily Generation");
    setSelectedOption("Daily Generation");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    let weeklyDate = moment(date).subtract(7, "days").format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(weeklyDate);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: weeklyDate,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchDailyGenerationEira(dataModel);

    setLoading(false);
    setTableData(responseData);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range: "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    const responseData = await fetchDailyGenerationEira(data);
    setLoading(false);
    setTableData(responseData);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChart = (data, event) => {
    setChartName(event);
    setSelectedOption(event);
    ApplyChange({ chartName: event });
  };

  const handleChangeDate = (data) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);

    if (data === "left") {
      newToDate = currentToDate.subtract(7, "days");
      newFromDate = currentFromDate.subtract(7, "days");
    } else if (data === "right") {
      newToDate = currentToDate.add(7, "days");
      newFromDate = currentFromDate.add(7, "days");
    } else {
      return; // Do nothing if data is not 'left' or 'right'
    }

    const today = moment().startOf("day");

    if (newToDate.isAfter(today)) {
      // If the new date is greater than today, set the date to today
      newToDate = today;
    }

    const formattedToDateTime = newToDate.format("YYYY-MM-DD");
    const formattedFromDateTime = newFromDate.format("YYYY-MM-DD");
    setToDate(formattedToDateTime);
    setFromDate(formattedFromDateTime);
    ApplyChange({
      toDate: formattedToDateTime,
      fromDate: formattedFromDateTime,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Daily Generation - Last 7 Days</Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "12px",
                    }}
                  >
                    {`${moment(fromDate).format("DD/MM")} - ${moment(
                      toDate
                    ).format("DD/MM ")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            barchartdata={barchartdata}
            widgetName={` `}
            fallBack={fallBack}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics3 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Specific Yield");
    setSelectedOption("Specific Yield");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    setRange("Daily");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Specific Yield",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchSpecificYieldEira(dataModel);
    responseData = sortedDataByMonth(responseData);

    function transformToChartData(initialData) {
      const equipmentMap = new Map();
      equipments.forEach((item) => {
        equipmentMap.set(item.equipmentId, item);
      });

      // Transform the initialData array
      const transformedData = initialData.map((item) => {
        const equipment = equipmentMap.get(item.equipmentId);
        return {
          todayEnergy: roundedUp(item.todayEnergy),
          timeStamp: item.timestamp || item.timeStamp,
          specificYield: roundedUp(item.specificYield),
          equipmentid: item.equipmentId,
          equipmentName: equipment ? equipment.displayName : "Unknown",
        };
      });

      return transformedData;
    }
    const result = transformToChartData(responseData);
    setLoading(false);
    setTableData(result);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range: rangeValue == "Yearly" ? "yearly" : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await fetchSpecificYieldEira(data);
    responseData = sortedDataByMonth(responseData);

    function transformToChartData(initialData) {
      const equipmentMap = new Map();
      equipments.forEach((item) => {
        equipmentMap.set(item.equipmentId, item);
      });

      // Transform the initialData array
      const transformedData = initialData.map((item) => {
        const equipment = equipmentMap.get(item.equipmentId);
        return {
          todayEnergy: roundedUp(item.todayEnergy),
          timeStamp: item.timestamp || item.timeStamp,
          specificYield: roundedUp(item.specificYield),
          equipmentid: item.equipmentId,
          equipmentName: equipment ? equipment.displayName : "Unknown",
        };
      });

      return transformedData;
    }
    const result = transformToChartData(responseData);
    setLoading(false);
    setTableData(result);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);

    const dateDiff = currentToDate.diff(currentFromDate, "days"); // Calculate the date range difference

    if (direction === "left") {
      newToDate = currentToDate.subtract(dateDiff === 0 ? 1 : dateDiff, "days");
      newFromDate = currentFromDate.subtract(
        dateDiff === 0 ? 1 : dateDiff,
        "days"
      );
    } else if (direction === "right") {
      newToDate = currentToDate.add(dateDiff === 0 ? 1 : dateDiff, "days");
      newFromDate = currentFromDate.add(dateDiff === 0 ? 1 : dateDiff, "days");
    } else {
      return; // Do nothing if direction is not 'left' or 'right'
    }

    const today = moment().startOf("day");

    if (newToDate.isAfter(today)) {
      // If the new "to" date is in the future, reset it to today
      newToDate = today;
    }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem", fontWeight: 400 }}>
                Specific Yield
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range == "Daily"
                      ? `${moment(fromDate).format("DD/MM/YYYY")}`
                      : range == "Yearly"
                      ? `${moment(fromDate).format("YYYY")}`
                      : `${moment(fromDate).format("DD/MM")} - ${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime
                    .filter((option) => option.value !== "Yearly")
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) =>
                          handleMenuItemClick(option.value, "Time")
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <CombinedChart
            tableData={tableData}
            customView={customView}
            WidgetName={` `}
            chartName={`${chartName}`}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics4 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Actual Vs Expected");
    setSelectedOption("Actual Vs Expected");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate, range;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    let weeklyDate = moment(date).subtract(7, "days").format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(weeklyDate);
    setRange("Last 7 days");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: weeklyDate,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await ActualVsExpected(dataModel);

    setLoading(false);
    setTableData(responseData);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range: rangeValue == "Yearly" ? "yearly" : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await ActualVsExpected(data);
    responseData = sortedDataByMonth(responseData);
    console.log(responseData, "responseData");

    setLoading(false);
    setTableData(responseData);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);

    const dateDiff = currentToDate.diff(currentFromDate, "days"); // Calculate the date range difference

    if (direction === "left") {
      newToDate = currentToDate.subtract(dateDiff === 0 ? 1 : dateDiff, "days");
      newFromDate = currentFromDate.subtract(
        dateDiff === 0 ? 1 : dateDiff,
        "days"
      );
    } else if (direction === "right") {
      newToDate = currentToDate.add(dateDiff === 0 ? 1 : dateDiff, "days");
      newFromDate = currentFromDate.add(dateDiff === 0 ? 1 : dateDiff, "days");
    } else {
      return; // Do nothing if direction is not 'left' or 'right'
    }

    const today = moment().startOf("day");

    if (newToDate.isAfter(today)) {
      // If the new "to" date is in the future, reset it to today
      newToDate = today;
    }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Actual Vs Expected
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range == "Daily"
                      ? `${moment(fromDate).format("DD/MM/YYYY")}`
                      : range == "Yearly"
                      ? `${moment(fromDate).format("YYYY")}`
                      : `${moment(fromDate).format("DD/MM")} - ${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) =>
                        handleMenuItemClick(option.value, "Time")
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <CombinedChart
            tableData={tableData}
            customView={customView}
            WidgetName={` `}
            chartName={`${chartName}`}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics5 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Daily Generation");
    setSelectedOption("Daily Generation");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    let weeklyDate = moment(date).subtract(7, "days").format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(weeklyDate);
    setRange("Last 7 days");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: weeklyDate,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchDailyGenerationEira(dataModel);

    setLoading(false);
    setTableData(responseData);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range: rangeValue == "Yearly" ? "yearly" : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await fetchDailyGenerationEira(data);
    responseData = sortedDataByMonth(responseData);

    setLoading(false);
    let seriesData = [];
    const irradiationSeries = {
      name: "irradiation",
      data: [],
    };
    const todayEnergySeries = {
      name: "todayenergy",
      data: [],
    };
    responseData.forEach((dataPoint) => {
      const timeStamp = new Date(dataPoint.timeStamp).getTime();
      irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
      todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
    });
    seriesData.push(irradiationSeries);
    seriesData.push(todayEnergySeries);
    setMultichartData(seriesData);
    setTableData(responseData);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);

    const dateDiff = currentToDate.diff(currentFromDate, "days"); // Calculate the date range difference

    if (direction === "left") {
      newToDate = currentToDate.subtract(dateDiff, "days");
      newFromDate = currentFromDate.subtract(dateDiff, "days");
    } else if (direction === "right") {
      newToDate = currentToDate.add(dateDiff, "days");
      newFromDate = currentFromDate.add(dateDiff, "days");
    } else {
      return; // Do nothing if direction is not 'left' or 'right'
    }

    const today = moment().startOf("day");

    if (newToDate.isAfter(today)) {
      // If the new "to" date is in the future, reset it to today
      newToDate = today;
    }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem", fontWeight: 400 }}>
                Energy Generation
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range == "Daily"
                      ? `${moment(fromDate).format("DD/MM/YYYY")}`
                      : range == "Yearly"
                      ? `${moment(fromDate).format("YYYY")}`
                      : `${moment(fromDate).format("DD/MM")} - ${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime
                    .filter((option) => option.value !== "Daily")
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) =>
                          handleMenuItemClick(option.value, "Time")
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            barchartdata={barchartdata}
            widgetName={` `}
            fallBack={fallBack}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};
