import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const Equipmentsitedropdowns = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGRVALUE_EQUIPMENTLIST}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
// export const Equipmentsitedropdowns = async (id) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITELIST_BYUSER
//       }/${id}`
//     );
//     console.log(response, "resssss");
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };
export const fetchSiteandDateGet = async (id, date) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_GET_TODAY_SITE_DATE}=${id}&downTimeDate=${date}`
    );
    // const response = await axios.get(
    //   `${process.env.REACT_APP_GET_SITE_DATE}?siteId=${id}&downTimeDate=${date}`
    // );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetchSitestartTime = async (id, date) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DGR_STARTTIME_ENDTIME}=${id}&date=${date}`
    );
    // const response = await axios.get(
    //   `${process.env.REACT_APP_GET_SITE_DATE}?siteId=${id}&downTimeDate=${date}`
    // );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetchTodaySiteandDateGet = async (siteId, date) => {
  // "2024-09-04"
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_GET_TODAY_SITE_DATE}=${siteId}&downTimeDate=${date}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*------------------------- getDownTime Api -----------------------*/
export const getDownTimeMinutes = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_DOWNTIME}/${id}`
    );
    console.log('Raw API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*---------------------- POST---------------------*/
export const postDgrUpdate = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_POSTAPI}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*---------------------- GET Remark ---------------------*/
export const RemarksField = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_REMARK}/${id}`
    );
    console.log(response, 'dgrresponse');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*---------------------- POST---------------------*/
export const postDgrValueSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH_OLD}${process.env.REACT_APP_DGR_VALUE_POSTAPI}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*-------------------- Non-Communicate Sites dropdown ---------------------*/
// export const getNonCommunicateSites = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${
//         isOEM
//           ? process.env.REACT_APP_SITE_DROP_DOWN_BYCOMPANY
//           : process.env.REACT_APP_DGRVALUE_NONCOMMUNICATE
//       }/${
//         isOEM
//           ? sessionStorage.getItem('companyId')
//           : sessionStorage.getItem('customerId')
//       }`
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
export const getNonCommunicateSites = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_DGRVALUE_NONCOMMUNICATE
      }/${sessionStorage.getItem('id')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const getDgrValuesByDate = async (siteId, date) => {
  try {
    const response = await axios.get(
      // `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_DGRVALUE_GETBY_DATE}/${Id}`
      `${process.env.REACT_APP_CHART_API_PATH_OLD}${process.env.REACT_APP_DGRVALUE_GETBY_DATE}?siteId=${siteId}&timestamp=${date}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
