import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Card,
  TableContainer,
  Tabs,
  Autocomplete,
  TextField,
  Pagination,
  PaginationItem,
  useTheme,
  Divider,
  TableSortLabel 
} from "@mui/material";
import "./SiteList.css";
import Tab from "@mui/material/Tab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CheckForLight } from "../util/common/CommanFunction";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FadeLoader } from "react-spinners";
import {
  fetchSiteEquipment,
  fetchSiteAlerms,
  fetchSiteAlermsfilter,
} from "../../Api/SiteListApi";
import {
  EnergymeterTables,
  ScbParams,
  ScbTables,
  SensorTables,
  fetchEquipmentlistTable,
} from "../../Api/SiteListApi";

export default function Equipmenthome(props) {
  const { SiteId, irradiation } = props;
 

  const theme = useTheme();
  const [siteValue, setSiteValue] = useState([]);
  const [tablevalue, setTableValue] = useState("Equipment");
  const [page, setPage] = useState(0);
  const [siteAlarms, setSiteAlerms] = useState([]);
  const [equimentalarms, setEquimentAlarms] = useState([]);
  const [lodervalue, setLodervalue] = useState(true);
  const [activeSection, setActiveSection] = useState("Equipment");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageValue, setPageValue] = useState(0);
  const [valuedata, setValueData] = useState([]);
  const [equipment, setEquipment] = useState(true);
  console.log(valuedata);
  const colorMode = theme.palette.mode;
  useEffect(() => {
    fetchDatadataTable();
    fetchDataEquipment();
  }, []);
  const fetchDataTable = async () => {
    setLodervalue(true);
    try {
      const data = await fetchSiteAlerms(SiteId);
      if (Array.isArray(data)) {
        setSiteAlerms(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteAlerms([data]);
      }
    } catch (e) {
      console.error(e);
    }
    setLodervalue(false);
  };

  const fetchDatadataTable = async () => {
    setLodervalue(true);
    try {
    
      const data = await fetchSiteEquipment(SiteId);
      if (Array.isArray(data)) {
        setEquimentAlarms(data);
      } else if (typeof data === "object" && data !== null) {
        setEquimentAlarms([data]);
      }
      setLodervalue(false);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchDataEquipment = async () => {
    setLodervalue(true);
    try {
      const data = await fetchEquipmentlistTable(`${SiteId}/${irradiation}`);
      if (Array.isArray(data)) {
        setValueData(data);
      } else if (typeof data === "object" && data !== null) {
        setValueData([data]);
      }
    } catch (e) {
      console.error(e);
    }
    setLodervalue(false);
  };

  let backgroundColor;
  if (CheckForLight()) {
    backgroundColor = activeSection === "Equipment" ? "" : "rgb(246, 248, 252)";
  } else {
    backgroundColor = activeSection === "Equipment" ? "" : "#4f4f4f";
  }

  let backgroundColorr;
  if (CheckForLight()) {
    backgroundColorr = activeSection === "Alarms" ? "" : "white";
  } else {
    backgroundColorr = activeSection === "Alarms" ? "" : "#4f4f4f";
  }
  const handleSectionClick = (value) => {
    setActiveSection(value);

    if (value === "Alarms") {
      fetchDataTable();
      setEquipment(false);
      fetchDatadataTable();
      setValue(0)
    }
    if (value === "Equipment") {
      setEquipment(true);
    }
  };
  const [value, setValue] = React.useState(0);
  const [categoryvalue, setCategoryValue] = useState("Inverter");
  console.log(categoryvalue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value === 1) {
      fetchDatadataTable();
    }
  };
  console.log(value);
  const Tablevalues = value === 0 ? equimentalarms : siteAlarms;
  const Tabledata = equipment === true ? valuedata : Tablevalues;
  console.log(Tabledata);

  const handleEquipment = async (event, value) => {
    setLodervalue(true);
    setEquipment(true);
    setCategoryValue(event);
    setSiteAlerms([]);
    setEquimentAlarms([]);
    let data = [];
    if (event === "Inverter") {
      data = await fetchEquipmentlistTable(`${SiteId}/${irradiation}`);
      setLodervalue(false);
    } else if (event === "Energy Meter") {
      data = await EnergymeterTables(SiteId);
      setLodervalue(false);
    } else if (event === "SCB") {
      data = await ScbTables(SiteId);
      setLodervalue(false);
    } else if (event === "Sensor") {
      data = await SensorTables(SiteId);
      setLodervalue(false);
    } else {
      console.error("Invalid category value:", categoryvalue);
      setLodervalue(false);
    }
    setValueData(data);
  };

  const rowsPerPageOptions = [5, 10, 15, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleClickGo = () => {
    if (
      pageValue > 0 &&
      pageValue <= Math.ceil(Tabledata.length / rowsPerPage)
    ) {
      setPage(pageValue - 1);
    }
  };

  const PageChange = (value) => {
    const newPageValue = parseInt(value, 5);
    if (!isNaN(newPageValue)) {
      setPageValue(newPageValue);
    }
  };

  const paginatedData = Tabledata.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          // sx={{ color: colorMode === 'light' ? '#484C46' : '#007AFF' }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );
 
  const tableConfigs = {};

  if (value === 0) {
    if (equimentalarms.length === 0) {
      tableConfigs.table1 = {
        message: "Currently, there are no alarms configured to display.",
      };
    } else {
      tableConfigs.table1 = {
        headers: [
          "Equipment Name",
          "Error Code",
          "Error Message",
          "Event Time",
          "Occurrence",
          "Priority",
          "Capacity",
        ],
        keys: [
          "displayName",
          "errorCode",
          "errorMessage",
          "lastOccurence",
          "eventOccurence",
          "priority",
          "dcCapacity",
        ],
      };
    }
  // } else if (value === 1) {
  //   if (equimentalarms.length === 0) {
  //     tableConfigs.table1 = {
  //       message: "There are currently no alarms to display at this moment.",
  //     };
  //   } else {
  //     tableConfigs.table1 = {
  //       headers: ["Another Header 1", "Another Header 2", "Another Header 3"],
  //       keys: ["field1", "field2", "field3"],
  //     };
  //   }
  }
  


  
  switch (categoryvalue) {
    case "Inverter":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'Currently, there are no equipment configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Today Energy (kWh)",
            "Total Energy (MWh)",
            "Specific Yield",
            "PR (%)",
            "Active Power (kW)",
            "Last Updated",
            "Status",
          ],
          keys: [
            "displayName",
            "serialNo",
            "todayEnergy",
            "totalEnergy",
            "specificYield",
            "performanceRatio",
            "activePower",
            "energyLastUpdate",
            "derivedStatusName",
          ],
        };
      }
      break;
  
    case "Energy Meter":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'At this moment, there are no energy meter devices to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Active Power (kWh)",
            "Reactive Power (kVAr)",
            "Import Energy (kWh)",
            "Export Energy (kWh)",
            "Power Factor",
            "Last Updated",
          ],
          keys: [
            "displayName",
            "serialNo",
            "activePower",
            "reactivePower",
            "importEnergy",
            "exportEnergy",
            "powerFactor",
            "lastUpdated",
          ],
        };
      }
      break;
  
    case "SCB":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'Currently, there are no SCB configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Equipment Code",
            "Capacity",
            "Components",
            "Last Uploaded",
            "Status",
          ],
          keys: [
            "customernaming",
            "primaryserialnumber",
            "equipmentcode",
            "capacity",
            "components",
            "timestamp",
            "status",
          ],
        };
      }
      break;
  
    case "Sensor":
      if (valuedata.length === 0) {
        tableConfigs.table2 = {
          message: 'At this time, there are no sensors configured to display.',
        };
      } else {
        tableConfigs.table2 = {
          headers: [
            "Equipment Name",
            "Serial No",
            "Ambient Temperature (°C)",
            "Module Temperature (°C)",
            "Irradiance (W/m2)",
            "Wind Speed (°)",
            "Wind Direction (m/s)",
            "Last Updated",
          ],
          keys: [
            "displayName",
            "serialNo",
            "ambientTemperature",
            "moduleTemperature",
            "irradiation",
            "windSpeed",
            "windDirection",
            "lastUpdated",
          ],
        };
      }
      break;
  
    default:
      tableConfigs.table2 = {
        message: 'No data available for the selected category.',
      };
      break;
  }
  
  const currentTable = equipment === true ? "table2" : "table1";
  const currentConfig = tableConfigs[currentTable];
  const badge = {
    borderRadius: "10px 10px 10px 10px",
    backgroundColor: "#1976d2",
    color: "white",
    fontSize: "11px",
    lineHeight: "18px",
    width: "30px",
    marginLeft: "2%",
  };


  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return Tabledata;
  
    return [...Tabledata].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
  
      // Handle ascending sort based on starting value of 1
      if (aValue === 1 && bValue !== 1) {
        return -1; // a comes before b
      } 
      if (bValue === 1 && aValue !== 1) {
        return 1; // b comes before a
      }
  
      // For other values, perform standard comparison
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0; // equal values
    });
  }, [Tabledata, sortConfig]);
  return (
    <div style={{ marginTop: "2%" }}>
      <div style={{ display: "flex" }}>
        <div className="Button-ticket">
          <Stack direction="column" spacing={2}>
            <Button
              elevation={22}
              variant="contained"
              style={{
                textTransform: "capitalize",
                width: "13vw",
                height: "6vh",
                color: activeSection === "Equipment" ? "white" : "black",
                background: backgroundColor,
              }}
              onClick={() => handleSectionClick("Equipment")}
            >
              Equipment
            </Button>
            <Button
              variant="contained"
              elevation={22}
              style={{
                textTransform: "capitalize",
                width: "13vw",
                height: "6vh",
                color: activeSection === "Alarms" ? "white" : "black",
                background: backgroundColorr,
              }}
              onClick={() => handleSectionClick("Alarms")}
            >
              Alarms
            </Button>
          </Stack>
        </div>

        <div style={{ marginLeft: "2%", width: "100%" }}>
          <Stack direction="column" spacing={2}>
            <Card
              elevation={1}
              style={{ height: "6vh", width: "100%", overflow: "auto" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {activeSection === "Alarms" ? (
                    <div>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{ minHeight: "32px" }}
                        TabIndicatorProps={{ style: { height: "4px" } }}
                      >
                        <Tab
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "none",
                                  color: value === 0 ? "black" : "",
                                }}
                              >
                                Equipment
                              </Typography>
                              <div style={badge}>{equimentalarms.length}</div>
                            </div>
                          }
                          sx={{ minHeight: "32px" }}
                        />
                        <Tab
                          label={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textTransform: "none",
                                  color: value === 1 ? "black" : "",
                                }}
                              >
                                UserDefine
                              </Typography>
                              <div style={badge}>{siteAlarms.length}</div>
                            </div>
                          }
                          sx={{ minHeight: "32px" }}
                        />
                      </Tabs>
                    </div>
                  ) : (
                    <div style={{ marginLeft: "2%", marginTop:"1%"}}>
                      <Autocomplete
                        name="status"
                        disablePortal
                        id="combo-box-demo"
                        options={["Inverter", "Energy Meter", "SCB", "Sensor"]}
                        value={categoryvalue}
                        onChange={(event, newValue) => {
                          handleEquipment(newValue);
                        }}
                        size="small"
                        sx={{ width: "14vw" }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder=" Jane" />
                        )}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Card>

            <Card
              elevation={1}
              style={{ height: "38vh", width: "100%" }}
            >
              {lodervalue ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop:"5%"
                  }}
                >
                  <FadeLoader color="#000440" loading={true} />
                </div>
              ) : (
                <div className="table-container-wrapper">
    <TableContainer style={{ maxHeight: '400px' }}>
      <Table aria-label="a dense table">
        <TableRow>
          {currentConfig?.headers?.length > 0 ? (
            currentConfig.headers.map((header, index) => (
              <TableCell key={index} align="center" style={{ color: "rgb(113, 118, 111)", position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <TableSortLabel
                  active={sortConfig.key === currentConfig.keys[index]}
                  direction={sortConfig.key === currentConfig.keys[index] ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort(currentConfig.keys[index])}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))
          ) : (
            <div style={{ textAlign: "center", marginTop: "16vh" }}>
              {currentConfig?.message || 'No data available.'}
            </div>
          )}
        </TableRow>

        <TableBody>
          {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {currentConfig?.keys?.map((key, keyIndex) => (
                <TableCell key={keyIndex} align="center">
                  {key === "lastOccurence" && row[key]
                    ? new Date(row[key])
                        .toLocaleDateString("en-GB", {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })
                        .replace(/\//g, "-")
                    : row[key]?.length === 0 || !row[key]
                    ? "-"
                    : row[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>



                </div>
              )}
            </Card>
            {lodervalue ? (
              <div></div>
            ) : (
              <div style={{marginTop:"1%"}}>
                {Tabledata.length > 0 && (
                  <Card style={{ height: "4vh", width: "100%" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item xs={2}></Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Stack spacing={2} direction="row">
                          <Pagination
                            count={Math.ceil(Tabledata.length / rowsPerPage)}
                            shape="rounded"
                            color="primary"
                            page={page + 1}
                            onChange={handleChangePage}
                            renderItem={(item) => (
                              <PaginationItem
                                slots={{
                                  previous: renderCustomPrevious,
                                  next: renderCustomNext,
                                }}
                                {...item}
                              />
                            )}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{
                              color: "#E6E7E9",
                              height: "30px",
                              marginRight: "16px",
                            }}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginLeft: "1%",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              lineHeight: "14.52px",
                              fontWeight: "400",
                            }}
                          >
                            Go to page
                          </Typography>
                          <TextField
                            variant="standard"
                            style={{
                              width: "24px",
                              marginLeft: "5px",
                            }}
                            value={pageValue !== 0 ? pageValue : ""}
                            onChange={(e) => PageChange(e.target.value)}
                          />
                          <Button
                            style={{
                              padding: "0",
                              width: "12px",
                              fontSize: "14px",
                            }}
                            onClick={handleClickGo}
                            endIcon={
                              <ArrowForwardIcon style={{ fontSize: "16px" }} />
                            }
                          >
                            GO
                          </Button>
                          <Autocomplete
                            options={rowsPerPageOptions}
                            getOptionLabel={(option) => option.toString()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                sx={{
                                  fontFamily: "Inter, sans-serif",
                                  width: 40,
                                }}
                              />
                            )}
                            value={rowsPerPage}
                            onChange={handleChangeRowsPerPage}
                            disableClearable
                            style={{ width: 120 }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </div>
            )}

            <div></div>
          </Stack>
        </div>
      </div>
    </div>
  );
}
