import React, { useState, useEffect, useMemo } from "react";
import "./AnalyticStyle.css";
import moment from "moment";
import {
  fetchDailyGenerationEira,
  fetchEnergyPerformanceEira,
  fetchParameterComparisonEira,
  fetchSpecificYieldEira,
  fetchPvDgGridEira,
  DeemedGenrator,
  GetSetPoint,
  ActualVsExpected,
  GetSpecificYieldVsIrradiationSite,
  GetSpecificYieldVsIrradiationEqp,
} from "../../../Api/DataAnalystApi";
import CombinedChart from "../../Charts/CombainedChart";
import FadeLoader from "react-spinners/FadeLoader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Menu,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  Divider,
  MenuItem,
  ListItemText,
  Checkbox,
  Grid,
  IconButton,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Radio,
  Tooltip,
  Badge,
  Popover,
} from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import dayjs from "dayjs";
/*------------------------- External compoenents ---------------------------*/
import Apexchart from "./ApexexChart";
import BarChart from "../../Charts/BarChart";
import AreaChart from "../../Charts/AreaChart";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import {
  removeAfterTime,
  removeQuestionMark,
  roundedUp,
  getUniqueDataLoggerIds,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import {
  adjustDateRange,
  convertLast7DaysFlagToDateRange,
  convertMonthFlag,
  convertWeekFlagToDateRange,
  getLast7DaysFlag,
  getMonthFlag,
  getWeekOfMonth,
  getYearFlag,
  setYearlyRange,
} from "../../util/CustomDateRange";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from "../../util/security/Cipher";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import { HeatmapChart } from "../../Charts/HeatmapChart";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CheckForLight } from "../../util/common/CommanFunction";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DateRange } from "react-date-range";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ConstructionIcon from "@mui/icons-material/Construction";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timer = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  // { label: 'Today', value: 'Today' },
  // { label: 'Yesterday', value: 'Yesterday' },
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" }, // { label: 'This Month', value: 'This Month' },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
  { label: "Custom range", value: "custom" },
];
const dailychartType = [
  { label: "Line Chart", value: "daily" },
  { label: "Bar Chart", value: "custom" },
];
const chartType = [
  { label: "Daily Generation", value: "daily_generation" },
  { label: "Specific Yield", value: "specific_yield" },
  { label: "Specific Yield - Heatmap", value: "specific_yield_heatmap" },
  {
    label: "Specific Yield Vs Irradiation",
    value: "specific_yield_vs_irradiation",
  },
  { label: "Energy Performance", value: "energy_performance" },
  { label: "Actual Vs Expected", value: "ActualVsExcepted" },
  { label: "DG PV Grid Management", value: "dgpv" },
  { label: "Deemed Generation", value: "deemed_generation" },
  { label: "Set Point", value: "setpoint" },
  { label: "String Current", value: "string_current" },
  { label: "Active Power", value: "active_power" },
];

const basedOnOptions = [
  { label: "Site", value: "site" },
  { label: "Equipment", value: "equipment" },
];

const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  lineHeight: "14.52px !important",
  color: " rgb(113, 118, 111)",
};

export const ViewAnalytics = () => {
  const [site, setSite] = React.useState("");
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setEquipmentParamName] = React.useState([]);
  const [equipmentParamwithUnits, setParameter] = useState([]);
  const [axisData, setAxisData] = useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [chartName, setChartName] = useState("");
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [ToDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [range, setRange] = useState("");
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [selectAllEquipments, setSelectAllEquipments] = useState(false);
  const [selectAllEquipmentParam, setSelectAllEquipmentParam] = useState(false);
  const [chartFromDate, setChartFromDate] = useState("");
  const [chartToDate, setChartToDate] = useState("");
  const [value, setValue] = useState(0);
  const [customView, setCustomView] = useState();
  const [timevalue, setTimeValue] = useState("Daily");
  const [chartSite, setChartSite] = useState();
  const [dataModel, setDataModel] = useState();
  const [fromApply, setApply] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [sdaysFlag, setSdaysFlag] = useState(getLast7DaysFlag(new Date()));
  const [WeeklyFlag, setWeeklyFlag] = useState(getWeekOfMonth(new Date()));
  const [MonthlyFlag, setMonthlyFlag] = useState(getMonthFlag(new Date()));
  const [YearlyFlag, setYearlyFlag] = useState(getYearFlag(new Date()));
  const [loading, setLoading] = useState(false);
  const [minToDate, setMinToDate] = useState([]);
  const [chartDropdown, setChartDropdown] = useState(true);
  const [fromTabSwitch, setFromTabSwitch] = useState(true);
  const [componentLoading, setComponentLoading] = useState(false);
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [menuOpen, setOpenMenu] = useState(false);
  const [menuOpenn, setOpennMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [timerValue, setTimerValue] = useState(5);
  const [timerOptions, setTimerOptions] = useState(timer);
  const [optionalCharts, setOptionalCharts] = useState(false);
  const [buttonLock, setButtonLock] = useState(true);
  const [calenderAnchor, setCalenderAnchor] = useState(null);
  const [rangeLevel, setRangeLevel] = useState("today");
  const [RangeState, setRangeState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [chartAnchor, setChartAnchor] = useState(null);
  const [dailyChartType, setDailyChartType] = useState("daily");
  const [customChart, setCustomChart] = useState(false);
  const [basedOn, setBasedOn] = useState("Site");
  function openCalender(event, from) {
    // console.log(event, from);

    if (from === "fromCalender") {
      setCalenderAnchor(event.currentTarget);
    } else {
      setCalenderAnchor(anchorEl);
      setOpenMenu(false);
      setAnchorEl(null);
    }
  }

  function closeCalender() {
    setCalenderAnchor(null);
  }

  const isCalendarOpen = Boolean(calenderAnchor);
  const calenderId = isCalendarOpen
    ? "simple-popover-date-range-picker"
    : undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;
  const paths = isFromSiteList
    ? [
        { label: "Home", path: "/menu" },
        { label: "Site List", path: -1 },
        { label: "View Analytics", path: "view-analyst" },
      ]
    : [
        { label: "Home", path: "/menu" },
        { label: "View Analytics", path: "view-analyst" },
      ];
  function ResetDateRange() {
    setTimerValue("5");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  }
  //Components list
  // State to manage which inv and params are checked
  const [checkedItems, setCheckedItems] = useState({});

  // State to manage open/close state for both categories and equipment
  const [openCategoryLists, setOpenCategoryLists] = useState({});
  const [openEquipmentLists, setOpenEquipmentLists] = useState({});

  // Function to toggle the open/close state of a category list
  const toggleCategoryListOpen = (category) => {
    setOpenCategoryLists((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  // Function to toggle the open/close state of an equipment list within a category
  const toggleEquipmentListOpen = (equ) => {
    setOpenEquipmentLists((prevState) => ({
      ...prevState,
      [equ]: !prevState[equ],
    }));
  };

  //chart Type list
  const [selectedOptions, setSelectedOptions] = useState("Daily Generation");
  //Equipment list
  const [selectedEquipments, setSelectedEquipments] = useState([]);

  const handleChartCheckboxChange = (event, option) => {
    const Predefined = [
      "Daily Generation",
      "Energy Performance",
      "Specific Yield",
      "Specific Yield - Heatmap",
      "DG PV Grid Management",
      "Deemed Generation",
      "Set Point",
    ];
    if (event.target.checked) {
      // setBasedOn('Site');
      setSelectedOptions(option);
      ResetDateRange();
      setChartName(option);
      setSelectedOption(option);
      setFromTabSwitch(false);
      setChartDropdown(false);
      setCustomChart(false);
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setTimeValue("Daily");
      setDailyChartType(
        chartName === "Specific Yield" ||
          option === "Specific Yield Vs Irradiation"
          ? "custom"
          : "daily"
      );
      setSelectedEquipments([]);
      setOptionalCharts(
        !Predefined?.includes(option) && option !== "Active Power"
      );
      let customData;
      if (option === "Energy Performance") {
        let displayName = equipments
          .filter((data) => {
            return data.equipmentCategory.includes("Inverter");
          })
          .map((datav) => {
            return datav.displayName;
          })
          .sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });
        setEquipmentData(displayName);
        setSelectedEquipments(displayName);
      } else if (!Predefined?.includes(option)) {
        customData = customCharts(option);
        if (option === "String Current") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName);
        } else if (option === "Active Power") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName);
        }
      }
      setDatalogValue([]);
      setTableData([]);
      setValue();
      handleApplyChanges("Daily", false, "timeValue", option);
    } else {
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setTimeValue("Daily");
      setSelectedOptions("Daily Generation");
      setChartName("Daily Generation");
      setSelectedOption("Daily Generation");
      handleApplyChanges("Daily", false, "timeValue", "Daily Generation");
    }
  };

  function customCharts(event) {
    if (event === "String Current" || event === "Active Power") {
      console.log(groupByCategory(equpAndParams), equpAndParams);
      // Retrieve the keys from grouped data
      const categories = Object.keys(groupByCategory(equpAndParams));

      let SortedInvNames = [];
      categories.forEach((key) => {
        // Check if the key contains 'inverter'

        if (
          key.toLocaleLowerCase()?.includes("inverter") ||
          key.toLocaleLowerCase()?.includes("inv")
        ) {
          // Get the list of inverters in this category
          const invList = groupByCategory(equpAndParams)[key];
          const InvNames = [];

          invList.forEach((inv) => {
            InvNames.push(inv.equ);
          });

          // Sort the inverter names
          const sortedNames = InvNames.sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });

          // Accumulate the sorted names
          SortedInvNames = SortedInvNames.concat(sortedNames);
        }
      });

      // Set the equipment data with the sorted inverter names
      setEquipmentData(SortedInvNames);

      return SortedInvNames;
    }
  }

  const [panelIndex, setPanelIndex] = useState("1");
  const [equpAndParams, setEqupAndParams] = useState([]);

  const handleChangePanel = (event, newValue) => {
    if (newValue === "1") {
      setChartName("Daily Generation");
      setSelectedOptions("Daily Generation");
      setSelectedOption("Daily Generation");
      handleTabChange(site, "Daily", "daily", "Daily Generation");
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      ResetDateRange();
    } else {
      setChartName("Parameter Comparision");
      setDate(moment().format("YYYY-MM-DD"));
      setTimeValue("Daily");
      setSelectedOptions("Parameter Comparision");
      setSelectedOption("Parameter Comparision");
      setDatalogValue([]);
      ResetDateRange();
    }
    setPanelIndex(newValue);
  };

  const handleCheckboxChange = (equ) => (event) => {
    setCheckedItems((prevState) => {
      // Check if the equipment already exists in the state
      if (!prevState[equ]) {
        return {
          ...prevState,
          [equ]: {
            checked: false, // Initialize the checkbox as unchecked
            params: [], // Initialize params as an empty array
          },
        };
      }

      // If it already exists, return the previous state without making any changes
      return prevState;
    });
  };

  // Function to handle toggling of params
  const handleParamToggle = (equ, param) => () => {
    const selectedParams = checkedItems[equ]?.params || [];
    const currentIndex = selectedParams.indexOf(param);
    const newChecked = [...selectedParams];

    if (currentIndex === -1) {
      newChecked.push(param);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems((prevState) => ({
      ...prevState,
      [equ]: {
        checked: newChecked.length > 0, // Checkbox is checked if at least one param is selected
        params: newChecked,
      },
    }));
  };

  function groupByCategory(arr) {
    return arr.reduce((acc, item) => {
      let { category, equ, params } = item;

      // Normalize the category name to lower case
      let cat = category.toLowerCase();

      // Group by common equipment types
      if (cat?.includes("meter")) {
        category = "Meters";
      } else if (cat?.includes("transformer")) {
        category = "Transformers";
      } else if (cat?.includes("inverter")) {
        category = "Inverters";
      } else if (cat?.includes("panel")) {
        category = "Panels";
      } else if (cat?.includes("alarm") || category?.includes("fire")) {
        category = "Alarm Systems";
      } else if (cat?.includes("ups")) {
        category = "UPS";
      }

      // Initialize category array if it doesn't exist
      if (!acc[category]) {
        acc[category] = [];
      }

      // Add the equipment to the appropriate category
      acc[category].push({ equ, params });

      return acc;
    }, {});
  }

  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    if (site) {
      handleTabChange(site, timevalue, range, chartSite);
    }
  }, [site]);

  async function createEquipmentParamsArray(siteId) {
    const id = params.id ? params.id : siteId;
    try {
      setComponentLoading(true);
      // Fetch the equipment dropdown data for the site
      const equip = await fetchEquipDropdownBySite(id);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultArray = [];

      // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        // Fetch parameters for the current dataLoggerId
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });

        // For each equipment, create an object with `equ` and `params` keys
        equipments.forEach((equipment) => {
          resultArray.push({
            equ: equipment.displayName,
            params: params
              .filter((param) => param.stdParameterName !== "Dummy")
              .map((param) => `${param.stdParameterName}(${param.stdUom})`),
            category: equipment.equipmentCategory,
          });
        });
      }

      console.log("resultArray", resultArray);

      // Update the state with the resulting array
      setEqupAndParams(resultArray);
      setComponentLoading(false);
      return resultArray;
    } catch (e) {
      console.error(e);
      setEqupAndParams([]);
      setComponentLoading(false);
    }
  }

  useEffect(() => {
    if (!params.id || !params.name) {
      if (siteIdName.length > 0) {
        const siteNameFromSession = queryParam
          ?.split("=")[1]
          ?.replaceAll("+", " ");

        const name = siteNameFromSession
          ? siteNameFromSession
          : siteIdName[0].siteName;
        setSite(name);

        const id = siteNameFromSession
          ? siteIdName.filter(
              (item) => item.siteName === siteNameFromSession
            )[0].siteId
          : siteIdName[0].siteId;

        const energyGen = siteNameFromSession
          ? siteIdName.filter(
              (item) => item.siteName === siteNameFromSession
            )[0].energyGenBasedOn
          : siteIdName[0].energyGenBasedOn;

        createEquipmentParamsArray(id);
        let date = new Date();
        let fromDate = moment(date).format("YYYY-MM-DD");
        const EiraDataModel = {
          fromDate: fromDate,
          GraphType: "Daily Generation",
          siteId: id,
          toDate: fromDate,
          range: "daily",
          timeperiod: "Daily",
          energyFlag: 0,
          energyGenBasedOn: energyGen,
          equipmentIdAndCapacity: [],
        };
        setCustomView(EiraDataModel);
        fetchEquipmentDetails(name);

        setChartSite(EiraDataModel.GraphType);

        fetchApplyChange();
      }
    } else {
      const getGen = siteIdName?.filter((item) => item.siteId === params.id);
      createEquipmentParamsArray(siteIdName[0]?.siteId);
      setSite(params.name);
      let date = new Date();
      let fromDate = moment(date).format("YYYY-MM-DD");
      const EiraDataModel = {
        fromDate: fromDate,
        GraphType: "Daily Generation",
        siteId: params.id,
        toDate: fromDate,
        range: "daily",
        timeperiod: "Daily",
        energyFlag: 0,
        energyGenBasedOn: getGen[0]?.energyGenBasedOn,
        equipmentIdAndCapacity: [],
      };

      setCustomView(EiraDataModel);
      fetchEquipmentDetails(params.id);

      setChartSite(EiraDataModel.GraphType);
      fetchApplyChange();
    }
  }, [siteIdName]);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParamsDropdownByLogger(id);
      let ParamsName = data
        .filter((item) => item.stdParameterName !== "Dummy")
        .map((item) => item.stdParameterName);
      setParameter(data);
      setParameterSite(ParamsName);
      return data;
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
      ResetDateRange();
    } else {
      setSite(val);
      const params = new URLSearchParams();
      params.set("siteName", val);
      setSelectedOptions("Daily Generation");
      setRangeLevel("daily");
      setTimeValue("Daily");
      ResetDateRange();
      setEquipmentName([]);
      setPanelIndex("1");
      setEquipmentData([]);
      setSelectedEquipments([]);
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      await fetchEquipmentDetails(val);
      let siteIdFilter = siteIdName?.filter((data) => {
        return data.siteName === val;
      });
      const dataFrequency = siteIdFilter[0].dataFrequency ?? "5";
      setTimerValue(dataFrequency);
      setTimerOptions(timer.filter((data) => +data.value >= +dataFrequency));
      await createEquipmentParamsArray(siteIdFilter[0]?.siteId);
      const encryptedParams = paddedEncryptQuery(params.toString());
      // console.log(encryptedParams.toString());
      navigate(`?${encryptedParams.toString()}`);
      setSelectedOption("");
    }
  };
  function checkArray(arr) {
    // Function to check if all values in an object are null
    function allValuesNull(obj) {
      return Object.values(obj).every((value) => value === null);
    }

    // Check each object in the array
    for (let obj of arr) {
      if (!allValuesNull(obj)) {
        return arr; // Return the original array if any object has non-null values
      }
    }

    return []; // Return an empty array if all objects have all null values
  }

  //--------------------range control functions starts -------------------------------//
  function UpdateFlag(data, flag) {
    let result;
    switch (data) {
      case "Last 7 days":
        result = adjustDateRange(data, sdaysFlag, flag);
        setSdaysFlag(result);
        break;
      case "Weekly":
        result = adjustDateRange(data, WeeklyFlag, flag);
        setWeeklyFlag(result);
        break;
      case "Monthly":
        result = adjustDateRange(data, MonthlyFlag, flag);
        setMonthlyFlag(result);
        break;
      case "Yearly":
        result = adjustDateRange(data, YearlyFlag, flag);
        setYearlyFlag(result);
        break;
      default:
        break;
    }
    return result;
  }

  const handleChangeDate = async (data) => {
    let newDate;
    let result;
    setTableData([]);
    if (!chartName) {
      setSelectedOption(chartSite);
      setChartName(chartSite);
    }
    const currentDate = moment(date);
    // console.log(timevalue);

    if (data === "left") {
      result = UpdateFlag(timevalue, "left");
      newDate = currentDate.subtract(1, "days");
    } else if (data === "right") {
      result = UpdateFlag(timevalue, "right");
      newDate = currentDate.add(1, "days");
    } else {
      return;
    }

    const today = moment().startOf("day");

    if (newDate.isAfter(today)) {
      newDate = today;
    }

    const formattedDateTime = newDate.format("YYYY-MM-DD");
    setDate(formattedDateTime);
    // console.log(formattedDateTime);
    await handleApplyChanges(
      {
        date: formattedDateTime,
        ref: result,
        chartName: chartSite,
      },
      "ref"
    );
  };
  //--------------------range control functions ends -------------------------------//

  const handleChange = (event) => {
    const { value } = event.target;
    let equip = [];
    if (value?.includes("Select All")) {
      if (selectAllEquipments) {
        setSelectAllEquipments(false);
        setEquipmentName([]);
      } else {
        setSelectAllEquipments(true);
        setEquipmentName([...equipmentData]);
      }
    } else {
      const updatedEquipmentName = value.filter(
        (item) => item !== "Select All"
      );
      setEquipmentName(updatedEquipmentName);
      setSelectAllEquipments(
        updatedEquipmentName.length === equipmentData.length
      );
    }
    if (value?.includes("Select All")) {
      equip = [...equipments];
    } else {
      equip = equipments.filter((data) =>
        value.some((val) => data.displayName?.includes(val))
      );
    }

    if (value.length !== 0 && equip.length !== 0) {
      const ids = getUniqueDataLoggerIds(equip);
      fetchParams({ dataloggerId: ids });
    }
  };

  const handleChangeEquipment = (event) => {
    const { value } = event.target;
    if (value?.includes("Select All")) {
      if (selectAllEquipmentParam) {
        setSelectAllEquipmentParam(false);
        setEquipmentParamName([]);
      } else {
        setSelectAllEquipmentParam(true);
        setEquipmentParamName([...equipmentParameter]);
      }
    } else {
      const updatedEquipmentParam = value.filter(
        (item) => item !== "Select All"
      );
      setEquipmentParamName(updatedEquipmentParam);
      setSelectAllEquipmentParam(
        updatedEquipmentParam.length === equipmentParameter.length
      );
    }
  };

  /*--------------------------------Equipment Get  ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      setEquipments(data);
      let displayName = data.map((datav) => {
        return datav.displayName;
      });
      setEquipmentData(
        displayName.sort((a, b) => {
          if (a?.includes("-") && b?.includes("-")) {
            return +a.split("-").pop() - +b.split("-").pop();
          } else if (a?.includes("_") && b?.includes("_")) {
            return +a.split("_").pop() - +b.split("_").pop();
          }
          return a.localeCompare(b);
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const dataFrequency = data[0].siteFrequency ?? 5;
      setTimerValue(dataFrequency);
      setTimerOptions(timer.filter((data) => +data.value >= +dataFrequency));
      setSiteId([...new Set(data)]);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleChart = (data, event) => {
    setFromTabSwitch(false);
    setChartDropdown(false);
    setSelectedOption(event);
    setDate(moment().format("YYYY-MM-DD"));
    setSdaysFlag(getLast7DaysFlag(new Date()));
    setMonthlyFlag(getMonthFlag(new Date()));
    setWeeklyFlag(getWeekOfMonth(new Date()));
    setTimeValue("Daily");
    setDatalogValue([]);
    setTableData([]);
    setValue();

    if (event === "Daily Generation") {
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    } else if (event === "Energy Performance") {
      fetchEquipmentDetails(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    } else if (event === "Parameter Comparision") {
      fetchEquipmentDetails(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
      setCustomView(false);
    }
  };
  const handleTimeChange = async (item) => {
    const { startDate, endDate } = item.selection;
    // Handle the change only if startDate and endDate are selected
    if (
      dayjs(startDate) &&
      dayjs(endDate) &&
      (dayjs(startDate).format("YYYY-MM-DD") !==
        dayjs(endDate).format("YYYY-MM-DD") ||
        dayjs(endDate).format("YYYY-MM-DD") ===
          dayjs(RangeState[0].endDate).format("YYYY-MM-DD"))
    ) {
      // console.log('Selected Date Range:', {
      //   startDate: dayjs(startDate).format('YYYY-MM-DD'),
      //   endDate: dayjs(endDate).format('YYYY-MM-DD'),
      //   condition:
      //     dayjs(startDate).format('YYYY-MM-DD') !==
      //     dayjs(endDate).format('YYYY-MM-DD'),
      // });

      handleChangeFromDate(dayjs(startDate));
      handleChangeToDate(dayjs(endDate));
      setRangeState([item.selection]);
      closeCalender();
    } else {
      setRangeState([item.selection]); // Update range without closing the calendar
    }
  };

  /*--------------handle----------- Custom range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setFromDate(formattedDateTime);
    }
  };
  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setToDate(formattedDateTime);
    }
  };
  useEffect(() => {
    setMinToDate(FromDate);
  }, [FromDate]);

  /*--------------------- Time range ---------------------*/

  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Today":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Last 7 days":
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("custom");
        setRangeLevel("last7days");
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange("custom");
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange("custom");
        break;
      case "custom":
        setRange("custom");
        break;
      default:
        break;
    }
  }, [timevalue]);

  /*------------------------------------------  Apply changes ----------------------*/
  async function createEquipmentMap(input) {
    const result = {};

    Object.entries(input).forEach(([key, value]) => {
      if (value.checked) {
        // Process each param by splitting and taking the first part
        const processedParams = value.params.map(
          (param) => param.split("(")[0]
        );
        // Use a Set to ensure unique processed params
        result[key] = [...new Set(processedParams)];
      }
    });
    setEqupParamMapValue(result);
    return result;
  }

  const formatDate = (inputDate) => {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = inputDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const calculateDateRange = (timevalue, refVal) => {
    let date = new Date();
    let fromDate = "";
    let toDate = "";
    let range = "";

    switch (timevalue) {
      case "Today":
        fromDate = toDate = formatDate(date);
        range = "today";
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        fromDate = toDate = formatDate(date);
        range = "daily";
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        fromDate = toDate = formatDate(date);
        range = "yesterday";
        setRange("daily");
        setRangeLevel("yesterday");
        break;

      case "Last 7 days":
        const SRef = convertLast7DaysFlagToDateRange(refVal || sdaysFlag, 2024);
        // console.log(sdaysFlag);

        if (SRef) {
          const [startDay, endDay] = SRef.split(" - ").map(
            (part) => new Date(`${part} ${date.getFullYear()}`)
          );

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
        } else {
          const pastDate = new Date(date);
          pastDate.setDate(date.getDate() - 6);
          fromDate = formatDate(pastDate);
          toDate = formatDate(date);
        }
        range = "last7days";
        setRange("custom");
        setRangeLevel("last7days");
        break;

      case "This Month":
        fromDate = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
        toDate = formatDate(date);
        range = "thismonth";
        setRange("custom");
        setRangeLevel("thismonth");
        break;

      case "Last Month":
        fromDate = formatDate(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        );
        toDate = formatDate(new Date(date.getFullYear(), date.getMonth(), 0));
        range = "lastmonth";
        setRange("custom");
        setRangeLevel("lastmonth");
        break;

      case "Monthly":
        const Mref = convertMonthFlag(refVal || MonthlyFlag);
        if (Mref) {
          const [monthName, year] = Mref.split(" ");
          const monthMap = {
            January: 0,
            February: 1,
            March: 2,
            April: 3,
            May: 4,
            June: 5,
            July: 6,
            August: 7,
            September: 8,
            October: 9,
            November: 10,
            December: 11,
          };
          const month = monthMap[monthName];
          const startDate = new Date(year, month, 1);
          const endDate = new Date(year, month + 1, 0);
          fromDate = formatDate(startDate);
          toDate = formatDate(endDate);
          range = "monthly";
          setRange("custom");
          setRangeLevel("monthly");
        }
        break;

      case "Weekly":
        const Wref = convertWeekFlagToDateRange(refVal || WeeklyFlag, 2024);
        if (Wref) {
          const [startDay, endDay] = Wref.split(" - ").map(
            (part) => new Date(`${part} ${date.getFullYear()}`)
          );

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
          range = "weekly";
          setRange("custom");
          setRangeLevel("weekly");
        }
        break;

      case "Yearly":
        const Yref = refVal || YearlyFlag;
        const { from, to } = setYearlyRange(Yref);
        fromDate = formatDate(from);
        toDate = formatDate(to);
        range = "yearly";
        setRange("custom");
        setRangeLevel("yearly");
        break;

      case "custom":
        range = "custom";
        setRange("custom");
        setRangeLevel("custom");
        return [];

      default:
        return [];
    }

    if (new Date(toDate) > new Date()) {
      toDate = formatDate(new Date());
    }

    setFromDate(fromDate);
    setToDate(toDate);
    return [fromDate, toDate, range];
  };

  function convertDailyDeemedData(data) {
    const formattedData = [
      { name: "Today Energy", data: [] },
      { name: "Irradiation", data: [] },
      { name: "Deemed Generation", data: [] },
      { name: "Set Point", data: [] },
      { name: "Potential Generation", data: [] },
    ];
    if (data.length === 0) return formattedData;
    data.forEach((point) => {
      const timestamp = new Date(point.timeStamp).getTime();

      formattedData[0].data.push([timestamp, point.todayEnergy]);
      formattedData[1].data.push([timestamp, point.irradiation]);
      formattedData[2].data.push([timestamp, point.deemedGeneration]);
      formattedData[3].data.push([timestamp, point.setPoint]);
      formattedData[4].data.push([timestamp, point.potentialGeneration]);
    });

    return formattedData;
  }

  function StringCurrentEqupMapTransform(equipmentData, inputEqu) {
    // Find the equipment object that matches the inputEqu
    const matchedEquipment = equipmentData.find((equ) => equ.equ === inputEqu);

    // If a match is found, return the desired object structure
    if (matchedEquipment) {
      return {
        [inputEqu]: matchedEquipment.params,
      };
    } else {
      // Return an empty object if no match is found
      return {};
    }
  }

  const handleApplyChanges = async (
    value,
    isTimeCall = true,
    flag,
    chart,
    invName,
    timer,
    daily_gen_type,
    based
  ) => {
    setLoading(true);
    setApply(true);
    setFromTabSwitch(false);
    setCustomView();
    setDatalogValue([site, equipment, equipmentParam]);

    const [fromDate, toDate, range] = calculateDateRange(
      flag === "timeValue" ? value : timevalue,
      value?.ref
    );
    // console.log(value);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    const GraphName = chart
      ? chart
      : selectedOption
      ? selectedOption
      : chartSite;

    let equipArray = [];
    if (GraphName === "Energy Performance") {
      equipArray =
        selectedEquipments.length > 0 && typeof selectedEquipments !== "string"
          ? selectedEquipments
          : equipments
              .filter((data) => {
                return data.equipmentCategory.includes("Inverter");
              })
              .sort((a_name, b_name) => {
                const a = a_name.displayName;
                const b = b_name.displayName;
                if (a?.includes("-") && b?.includes("-")) {
                  return +a.split("-").pop() - +b.split("-").pop();
                } else if (a?.includes("_") && b?.includes("_")) {
                  return +a.split("_").pop() - +b.split("_").pop();
                }
                return a.localeCompare(b);
              })
              .map((datav) => {
                return datav.displayName;
              });
    } else if (GraphName === "String Current") {
      equipArray = invName
        ? invName
        : typeof selectedEquipments === "string"
        ? selectedEquipments
        : equipments
            .sort((a_name, b_name) => {
              const a = a_name.displayName;
              const b = b_name.displayName;
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            })
            .filter((data) => {
              return data.equipmentCategory
                .toLocaleLowerCase()
                .includes("inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })[0];
      const equipLoggMap = StringCurrentEqupMapTransform(
        equpAndParams,
        equipArray
      );

      setEqupParamMapValue(equipLoggMap);
    } else if (GraphName === "Active Power") {
      equipArray =
        selectedEquipments.length > 0 && typeof selectedEquipments !== "string"
          ? selectedEquipments
          : equipments
              .sort((a_name, b_name) => {
                const a = a_name.displayName;
                const b = b_name.displayName;
                if (a?.includes("-") && b?.includes("-")) {
                  return +a.split("-").pop() - +b.split("-").pop();
                } else if (a?.includes("_") && b?.includes("_")) {
                  return +a.split("_").pop() - +b.split("_").pop();
                }
                return a.localeCompare(b);
              })
              .filter((data) => {
                return data.equipmentCategory
                  .toLocaleLowerCase()
                  .includes("inverter");
              })
              .map((datav) => {
                return datav.displayName;
              });
      const equipLoggMap = StringCurrentEqupMapTransform(
        equpAndParams,
        equipArray
      );

      setEqupParamMapValue(equipLoggMap);
    } else {
      Object.keys(checkedItems).forEach((key) => {
        if (checkedItems[key].checked) {
          equipArray.push(key);
        }
      });
    }

    let filteredObjects = equipments?.filter((obj) => {
      return typeof equipArray === "string"
        ? obj.displayName === equipArray
        : equipArray.includes(obj.displayName);
    });
    // console.log(filteredObjects, equipArray, equipments);

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    const LoggerIds = filteredObjects?.map((data) => {
      return data.dataLoggerId;
    });
    const equipParamwithUnits = await fetchParams({
      dataloggerId: [...new Set(LoggerIds)],
    });
    // Extract and flatten all params arrays
    let allParams;
    if (GraphName === "String Current") {
      allParams = equipParamwithUnits
        .filter((data) => {
          return data.stdParameterName.toLowerCase()?.includes("inputcurrent");
        })
        .flatMap((data) => data.stdParameterName);
    } else if (GraphName === "Active Power") {
      allParams = equipParamwithUnits
        .filter((data) => {
          return data.stdParameterName.toLowerCase() === "activepower";
        })
        .flatMap((data) => data.stdParameterName);
    } else {
      allParams = Object.values(checkedItems).flatMap((item) => item.params);
    }
    // console.log(equipArray, allParams);

    // Create a Set to filter unique values
    const uniqueParams = [...new Set(allParams)];

    let equipmentParameter = uniqueParams.map((data) => {
      return GraphName === "String Current" ? data : data.split("(")[0];
    });
    // console.log(equipmentParameter);

    let equipmentParams = equipParamwithUnits.filter((data) => {
      return uniqueParams?.includes(`${data.stdParameterName}(${data.stdUom})`);
    });
    setAxisData(equipmentParams);

    const rangeValue =
      (fromDate === toDate && !isTimeCall) ||
      (timevalue === "custom" && FromDate === ToDate && isTimeCall) ||
      (FromDate === ToDate && isTimeCall)
        ? "daily"
        : "custom";

    const fromDataRanger = value !== null && typeof value === "object";
    if (
      flag !== "disableReset" &&
      !fromDataRanger &&
      flag !== "timer" &&
      flag !== "dailyGenChartType"
    ) {
      setDate(moment().format("YYYY-MM-DD"));
    }

    // console.log(timevalue);

    const from = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : fromDate
      : isTimeCall || flag === "disableReset"
      ? FromDate
      : fromDate;

    const to = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : toDate
      : isTimeCall || flag === "disableReset"
      ? ToDate
      : toDate;
    console.log({
      FromDate,
      ToDate,
      fromDate,
      toDate,
      from,
      to,
    });

    const timeInterval = flag === "timer" ? timer : timerValue;
    const chartType =
      flag === "timeValue" && value !== "Daily"
        ? "custom"
        : daily_gen_type
        ? daily_gen_type
        : dailyChartType;

    let EiraDataModel = {
      fromDate: from,
      equipmentIds: equipmentIds,
      parameters: equipmentParameter,
      range: fromDataRanger && timevalue === "Daily" ? "daily" : rangeValue,
      timeperiod: flag === "timeValue" ? value : timevalue,
      siteId: siteIdFilter[0]?.siteId,
      toDate: to,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyFlag: 0,
      intervalMins: timeInterval,
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      GraphType: GraphName,
    };

    if ((customChart || flag === "dailyGenChartType") && flag !== "timeValue") {
      EiraDataModel.range = chartType;
    }

    if (
      ((rangeLevel === "yearly" && flag === "ref") || range === "yearly") &&
      (GraphName === "Daily Generation" ||
        GraphName === "Actual Vs Expected" ||
        GraphName === "DG PV Grid Management")
    ) {
      EiraDataModel.range = "yearly";
    }

    setDataModel(EiraDataModel);
    // setDailyChartType(EiraDataModel.range);
    if (
      GraphName === "Specific Yield" ||
      GraphName === "Specific Yield - Heatmap" ||
      GraphName === "Specific Yield Vs Irradiation"
    ) {
      const EquipmentForSpecificYield =
        transformDataForSpecificYield(equipments);
      EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
      let filteredObjects = equipments?.filter((obj) =>
        ["Weather Station", "Energy Meter"]?.includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      EiraDataModel.equipmentIds = equipmentIds;
    }

    if (
      GraphName === "Daily Generation" ||
      GraphName === "Deemed Generation" ||
      GraphName === "Actual Vs Expected" ||
      GraphName === "Specific Yield Vs Irradiation"
    ) {
      let filteredObjects = equipments?.filter((obj) =>
        ["Weather Station", "Energy Meter"]?.includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      EiraDataModel.equipmentIds = equipmentIds;
    }

    setChartFromDate(from);
    setChartToDate(to);
    setFromDate(from);
    setToDate(to);

    if (GraphName === "Daily Generation") {
      delete EiraDataModel.parameters;
      if (siteIdFilter[0].energyGenBasedOn != 1) {
        delete EiraDataModel.equipmentIds;
      }
      try {
        let responseData = await fetchDailyGenerationEira(EiraDataModel);
        setChartName("Daily Generation");
        setDatalogValue(responseData);
        setTableData(responseData);
        let seriesData = [];
        const irradiationSeries = {
          name: "irradiation",
          data: [],
        };
        const todayEnergySeries = {
          name: "todayenergy",
          data: [],
        };
        responseData.forEach((dataPoint) => {
          const timeStamp = new Date(dataPoint.timeStamp).getTime();
          irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
          todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
        });
        seriesData.push(irradiationSeries);
        seriesData.push(todayEnergySeries);
        setMultichartData(seriesData);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Energy Performance") {
      delete EiraDataModel.parameters;
      try {
        let responseData = await fetchEnergyPerformanceEira(EiraDataModel);
        setChartName("Energy Performance");
        setLoading(false);
        let barChartData = responseData;
        setTableData(responseData);
        if (from === to) {
          const equipmentMap = {};
          equipments.forEach((equipment) => {
            equipmentMap[equipment.equipmentId] = equipment.displayName;
          });
          const seriesData = [];
          equipments.forEach((equipment) => {
            const series = {
              name: equipment.displayName,
              data: [],
            };
            const equipmentData = responseData.filter(
              (data) => data.equipmentId === equipment.equipmentId
            );
            equipmentData.forEach((data) => {
              const timeStamp = new Date(data.timeStamp).getTime();
              const value =
                data.todayEnergy !== null ? roundedUp(data.todayEnergy) : 0;
              series.data.push([timeStamp, value]);
            });
            seriesData.push(series);
          });
          let finalArray = seriesData?.filter((value) => {
            return value.data.length > 0;
          });
          setMultichartData(finalArray);
        } else {
          const convertedData = barChartData.reduce((result, item) => {
            const found = result.find(
              (x) => x.name === item.equipmentId.toString()
            );
            if (found) {
              found.data.push([
                new Date(item.timeStamp).getTime(),
                roundedUp(item.todayEnergy),
              ]);
            } else {
              result.push({
                name: item.equipmentId.toString(),
                data: [
                  [
                    new Date(item.timeStamp).getTime(),
                    roundedUp(item.todayEnergy),
                  ],
                ],
              });
            }
            return result;
          }, []);
          let converteddata = convertedData;
          let dataMap = new Map(
            equipments.map((item) => [
              String(item.equipmentId),
              item.displayName,
            ])
          );
          converteddata.forEach((item) => {
            if (dataMap.has(item.name)) {
              item.name = dataMap.get(item.name);
            }
          });
          // console.log(convertedData);

          setBarchartData(convertedData);
          setTableData(responseData);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Parameter Comparision") {
      try {
        let responseData = [];
        responseData = await fetchParameterComparisonEira(EiraDataModel);
        setChartName("Parameter Comparision");
        setTableData(responseData);

        const equipLoggMap = await createEquipmentMap(checkedItems);

        function doesFieldExist(equipmentName, fieldName) {
          const params = equipLoggMap[equipmentName];

          if (params && params?.includes(fieldName)) {
            return true;
          }
          return false;
        }

        const equipmentMap = {};
        const dataFields = equipmentParameter;
        const seriesData = [];

        dataFields.forEach(async (field) => {
          responseData.forEach(async (data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;
            const timeStamp = new Date(timeStampData).getTime();
            const value =
              data[field] !== null ? Number(roundedUp(data[field])) : 0;
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            // console.log(seriesName);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };

              if (doesFieldExist(equipmentName[0]?.displayName, field)) {
                seriesData.push(newSeries);
              }

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });
              // console.log(filteredArray);

              setMultichartData(filteredArray);
              return filteredArray;
            }
          });
        });
        // console.log(dataFields);

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "String Current" || GraphName === "Active Power") {
      try {
        let responseData = [];
        responseData = await fetchParameterComparisonEira(EiraDataModel);
        setChartName("Parameter Comparision");
        setTableData(responseData);
        // console.log(equipmentParameter);

        const dataFields = equipmentParameter;
        const seriesData = [];
        // console.log(equipments);

        dataFields.forEach(async (field) => {
          responseData.forEach(async (data) => {
            // console.log(field);

            let equipmentName = equipments?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            // console.log(equipmentName);

            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;

            const timeStamp = new Date(timeStampData).getTime();

            // console.log(timeStamp);

            const value =
              data[field] !== null ? Number(roundedUp(data[field])) : 0;
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            // console.log(value);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };
              seriesData.push(newSeries);
              // console.log(seriesData);

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });

              setMultichartData(filteredArray);
              return filteredArray;
            }
          });
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (
      GraphName === "Specific Yield" &&
      (timevalue === "today" ||
        timevalue === "yesterday" ||
        fromDate === toDate ||
        EiraDataModel.range === "daily")
    ) {
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield");
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // Transform the initialData array
          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp || item.timeStamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        const result = transformToChartData(responseData);
        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield") {
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield");
        const transformedData = responseData.map((item) => {
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
          };
        });

        setLoading(false);
        setTableData(transformedData);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield - Heatmap") {
      delete EiraDataModel.parameters;
      delete EiraDataModel.equipmentIds;
      EiraDataModel.range = "daily";
      try {
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        setChartName("Specific Yield - Heatmap");
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp || item.timeStamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        const result = transformToChartData(responseData);
        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "DG PV Grid Management") {
      delete EiraDataModel.parameters;
      delete EiraDataModel.equipmentIds;
      //comment this
      // EiraDataModel.range = "daily";
      setChartName("DG PV Grid Management");
      try {
        let responseData = await fetchPvDgGridEira(EiraDataModel);
        let result;

        result = {
          dgMeter: responseData.dgMeter
            ? removeAfterTime([...responseData.dgMeter], "19:00:00")
            : [],
          gridMeter: responseData.gridMeter
            ? removeAfterTime([...responseData.gridMeter], "19:00:00")
            : [],
          solarMeter: responseData.solarMeter
            ? removeAfterTime([...responseData.solarMeter], "19:00:00")
            : [],
        };

        // result = {
        //   dgMeter: responseData.dgMeter,
        //   gridMeter: responseData.gridMeter,
        //   solarMeter: responseData.solarMeter,
        // };

        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Actual Vs Expected") {
      if (EiraDataModel.range !== "yearly") {
        EiraDataModel.range = "custom";
      }

      let responseData = await ActualVsExpected(EiraDataModel);

      setLoading(false);
      setTableData(responseData);
      setChartName(EiraDataModel?.GraphType);
    } else if (GraphName === "Deemed Generation") {
      if (
        timevalue.toLowerCase() === "today" ||
        timevalue.toLocaleLowerCase() === "yesterday" ||
        fromDate === toDate
      ) {
        try {
          let responseData = await DeemedGenrator(EiraDataModel);
          responseData = checkArray(responseData);
          setChartName("Deemed Generation");
          const result = convertDailyDeemedData(responseData);
          setTableData(responseData);
          setMultichartData(result);
          setChartName(EiraDataModel?.GraphType);
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      } else {
        try {
          //remove below after testing
          // EiraDataModel.range = "daily";
          // setMultichartData([]);
          //remove above after testing
          let responseData = await DeemedGenrator(EiraDataModel);
          responseData = checkArray(responseData);
          setLoading(false);
          setTableData(responseData);
          setChartName(EiraDataModel?.GraphType);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }
    } else if (GraphName === "Set Point") {
      try {
        let responseData = await GetSetPoint(EiraDataModel);
        setLoading(false);
        //check if all data is null
        const isNull = responseData.every((item) => {
          return item.avgSetpoint == null;
        });
        setTableData(isNull ? [] : responseData);
        setMultichartData([]);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else if (GraphName === "Specific Yield Vs Irradiation") {
      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipments.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            irradiation: roundedUp(item.irradiation),
            timeStamp: item.timestamp || item.timeStamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : "Unknown",
          };
        });

        return transformedData;
      }

      const basedOnFlag = flag === "basedOn" ? based : basedOn;
      try {
        const requestPoint =
          basedOnFlag === "Site"
            ? GetSpecificYieldVsIrradiationSite
            : GetSpecificYieldVsIrradiationEqp;
        let responseData = await requestPoint(EiraDataModel);
        const result = transformToChartData(responseData);
        setLoading(false);
        setTableData(result);
        setChartName(EiraDataModel?.GraphType);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };
  /*------------------------------KEY Metrics Moniter  ----------------*/
  const datePickerStyle = {
    "& .MuiInputBase-input": {
      fontSize: "1.2rem",
      padding: "4px",
      width: "11vw",
    },
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getEquipCap = async (id) => {
    let data = await fetchEquipDropdownBySite(id);
    setEquipments(data);
    return data;
  };

  const handleTabChange = async (site, timevalue, range, GraphType) => {
    setChartSite(GraphType);
    setRange(range);
    setDate(moment().format("YYYY-MM-DD"));
    setChartDropdown(true);
    setTimeValue(timevalue);
    setChartName("");
    setSelectedOption("");
    setEquipmentName([]);
    setEquipmentParamName([]);
    setTableData([]);
    setFromTabSwitch(true);
    setLoading(true);
    let data = siteIdName;
    let equip;
    let siteIdFilter;
    try {
      if (siteIdName.length === 0) {
        data = await getSiteCall();
      }
      siteIdFilter = data?.filter((data) => {
        return data.siteName === site;
      });
      equip = await getEquipCap(siteIdFilter[0].siteId);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }

    let date = new Date();
    let fromDate = new Date();
    let responseDate,
      toDate,
      ApplyFromDate,
      ApplyToDate,
      rangeValue,
      timeApiValue = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Daily";
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Yesterday";
        break;
      case "Last 7 days":
        fromDate.setDate(fromDate.getDate() - 6);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setRange("custom");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "custom";
        timeApiValue = "Last 7 days";
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = formatDate(startDate);
        ApplyToDate = formatDate(endDate);
        rangeValue = "custom";
        timeApiValue = "This Month";
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        let dataValue = formatDate(firstDayOfLastMonth);
        let dataValueEnd = formatDate(lastDayOfLastMonth);
        ApplyFromDate = dataValue;
        ApplyToDate = dataValueEnd;
        rangeValue = "custom";
        timeApiValue = "Last Month";
        break;
      default:
        break;
    }
    let rangeData =
      GraphType === "Specific Yield"
        ? range
        : (GraphType === "Daily Generation" && rangeValue === "daily") ||
          (GraphType === "Deemed Generation" && rangeValue === "daily")
        ? "daily"
        : "custom";

    let equipCap = [];
    if (
      chartName === "Specific Yield" ||
      chartName === "Specific Yield - Heatmap"
    ) {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equip);
      equipCap = EquipmentForSpecificYield;
    }

    let meter = [];
    const equipList = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
    let filteredObjects = equipList.filter((obj) =>
      ["Weather Station", "Energy Meter"]?.includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    meter = equipmentIds;

    let EiraDataModel = {
      fromDate: ApplyFromDate,
      GraphType: GraphType,
      equipmentIds: meter,
      range: rangeData,
      siteId: siteIdFilter[0]?.siteId,
      toDate: ApplyToDate,
      timeperiod: timeApiValue,
      energyFlag: 0,
      intervalMins: timerValue,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIdAndCapacity: equipCap,
    };
    fetchApplyChange(EiraDataModel);
    setDataModel(EiraDataModel);
    setChartName(EiraDataModel?.GraphType);
    setCustomView(EiraDataModel);
  };

  /*------------------------------------- Handle Key Metrics Monitor ------------------------------ */
  const fetchApplyChange = async (EiraDataModel) => {
    if (EiraDataModel !== undefined) {
      setLoading(true);
      setApply(false);
      setChartFromDate(EiraDataModel?.fromDate);
      setChartToDate(EiraDataModel?.toDate);
      if (
        EiraDataModel?.GraphType === "Specific Yield" &&
        EiraDataModel?.timeperiod === "Daily"
      ) {
        const EquipmentForSpecificYield =
          transformDataForSpecificYield(equipments);
        EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
        EiraDataModel.range = "daily";
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        function transformToChartData(initialData) {
          const equipmentMap = new Map();
          equipments.forEach((item) => {
            equipmentMap.set(item.equipmentId, item);
          });

          // Transform the initialData array
          const transformedData = initialData.map((item) => {
            const equipment = equipmentMap.get(item.equipmentId);
            return {
              todayEnergy: roundedUp(item.todayEnergy),
              timeStamp: item.timestamp,
              specificYield: roundedUp(item.specificYield),
              equipmentid: item.equipmentId,
              equipmentName: equipment ? equipment.displayName : "Unknown",
            };
          });

          return transformedData;
        }
        setLoading(false);
        setDatalogValue(responseData);
        setTableData(transformToChartData(responseData));
      } else if (EiraDataModel?.GraphType === "Specific Yield") {
        const EquipmentForSpecificYield =
          transformDataForSpecificYield(equipments);
        EiraDataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
        let responseData = await fetchSpecificYieldEira(EiraDataModel);
        const transformedData = responseData.map((item) => {
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
          };
        });
        setLoading(false);
        setTableData(transformedData);
      } else if (EiraDataModel?.GraphType === "Actual Vs Expected") {
        // let responseData = await ExpectedActual(EiraDataModel);
        setTableData([]);
        setLoading(false);
      } else if (EiraDataModel?.GraphType === "DG PV Grid Management") {
        delete EiraDataModel.parameters;
        delete EiraDataModel.equipmentIds;
        let responseData = await fetchPvDgGridEira(EiraDataModel);
        let result;

        result = {
          dgMeter: responseData.dgMeter
            ? removeAfterTime([...responseData.dgMeter], "19:00:00")
            : [],
          gridMeter: responseData.gridMeter
            ? removeAfterTime([...responseData.gridMeter], "19:00:00")
            : [],
          solarMeter: responseData.solarMeter
            ? removeAfterTime([...responseData.solarMeter], "19:00:00")
            : [],
        };
        setLoading(false);
        setTableData(result);
      } else if (EiraDataModel?.GraphType === "Deemed Generation") {
        if (
          EiraDataModel?.timeperiod.toLowerCase() === "today" ||
          EiraDataModel?.timeperiod.toLocaleLowerCase() === "yesterday" ||
          EiraDataModel?.fromDate === EiraDataModel?.toDate
        ) {
          let responseData = await DeemedGenrator(EiraDataModel);
          const result = convertDailyDeemedData(responseData);
          setTableData(responseData);
          setMultichartData(result);
          setLoading(false);
        } else {
          delete EiraDataModel.parameters;
          delete EiraDataModel.equipmentIds;
          // EiraDataModel.range="daily"

          let responseData = await DeemedGenrator(EiraDataModel);

          setLoading(false);
          setTableData(responseData[0]);
        }
      } else if (EiraDataModel?.GraphType === "Daily Generation") {
        let responseData = await fetchDailyGenerationEira(EiraDataModel);

        setLoading(false);
        setDatalogValue(responseData);
        setTableData(responseData);
        if (EiraDataModel?.range === "daily") {
          let seriesData = [];
          const irradiationSeries = {
            name: "irradiation",
            data: [],
          };
          const todayEnergySeries = {
            name: "todayenergy",
            data: [],
          };
          responseData.forEach((dataPoint) => {
            const timeStamp = new Date(dataPoint.timeStamp).getTime();
            irradiationSeries.data.push([
              timeStamp,
              dataPoint.irradiation || 0,
            ]);
            todayEnergySeries.data.push([
              timeStamp,
              dataPoint.todayEnergy || 0,
            ]);
          });
          seriesData.push(irradiationSeries);
          seriesData.push(todayEnergySeries);
          setMultichartData(seriesData);
        }
      }
    }
  };

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  const handleClickMenuItem = (event, flag) => {
    if (flag === "Time") {
      setOpenMenu(true);
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorTimerEl(event.currentTarget);
    }
  };

  const [openBasedOn, setOpenBasedOn] = useState(false);
  const [basedOnAnchor, setBasedOnAnchor] = useState(null);
  const handledailyMenuItem = (event, flag) => {
    if (flag === "dailychart") {
      setChartAnchor(event.currentTarget);
      setOpennMenu(true);
    } else if (flag === "basedon") {
      setBasedOnAnchor(event.currentTarget);
      setOpenBasedOn(true);
    }
  };

  const handleCloseBasedOn = () => {
    setOpenBasedOn(false);
    setBasedOnAnchor(null);
  };

  const hadleBasedOn = async (data) => {
    setBasedOn(data.label);
    setOpenBasedOn(false);
    // setDailyChartType('daily');
    await handleApplyChanges(
      null,
      true,
      "basedOn",
      chartName,
      null,
      timerValue,
      null,
      data.label
    );
  };

  const iconForBasedOn = useMemo(() => {
    const based = basedOn;
    const styleForIcon = {
      color: CheckForLight() ? "black" : "white",
      opacity: chartName === "Specific Yield Vs Irradiation" ? 1 : 0.5,
      pointerEvents:
        chartName === "Specific Yield Vs Irradiation" ? "auto" : "none",
    };

    switch (based.toLocaleLowerCase()) {
      case "site":
        return <SolarPowerIcon style={styleForIcon} />;
      case "equipment":
        return <ConstructionIcon style={styleForIcon} />;
      default:
    }
  }, [basedOn, chartName]);

  const handleMenuItemClick = async (value, flag) => {
    if (flag === "Time") {
      setTimeValue(value);
      if (value !== "custom") {
        if (value === "Daily") {
          setDate(moment().format("YYYY-MM-DD"));
        }
        setCustomChart(false);
        setRangeState([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        handleApplyChanges(
          value,
          false,
          "timeValue",
          null,
          null,
          value === "Daily" ? "daily" : dailyChartType
        );
        handleCloseMenu();
      }
      if (value === "custom") {
        openCalender();
        setTableData([]);
      }
    } else if (flag === "dailychart") {
      setDailyChartType(value);
      setCustomChart(true);
      handleCloseeMenu();
      if (chartName !== "Specific Yield Vs Irradiation") {
        await handleApplyChanges(
          null,
          true,
          "dailyGenChartType",
          chartName,
          null,
          timerValue,
          value
        );
      }
    } else {
      setTimerValue(Number(value));
      await handleApplyChanges(
        null,
        true,
        "timer",
        chartName,
        null,
        Number(value)
      );
      handleCloseTimer();
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const handleCloseeMenu = () => {
    setOpennMenu(false);
    setChartAnchor(null);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };

  const RangeText =
    timevalue === "custom"
      ? "Custom Range"
      : timevalue === "Last 7 days"
      ? convertLast7DaysFlagToDateRange(sdaysFlag, 2024)
      : timevalue === "Weekly"
      ? convertWeekFlagToDateRange(WeeklyFlag, 2024)
      : timevalue === "Monthly"
      ? convertMonthFlag(MonthlyFlag)
      : timevalue === "Yearly"
      ? YearlyFlag
      : moment(date).format("DD/MM/YYYY");

  const analyticsMetrics = () => {
    const handleCheckboxChange = async (event, name) => {
      setButtonLock(false);
      if (selectedOption === "String Current") {
        event.target.checked
          ? setSelectedEquipments(name)
          : setSelectedEquipments([]);
        await handleApplyChanges(null, false, "selectedEquipments", null, name);
        return;
      }
      if (event.target.checked) {
        setSelectedEquipments((prev) => [...prev, name]);
      } else {
        setSelectedEquipments((prev) => prev.filter((item) => item !== name));
      }
    };
    const isDisabled =
      selectedOption === "Daily Generation" ||
      selectedOption === "Specific Yield" ||
      selectedOption === "Deemed Generation" ||
      selectedOption === "Specific Yield - Heatmap" ||
      selectedOption === "DG PV Grid Management" ||
      selectedOption === "Set Point" ||
      selectedOption === "Actual Vs Expected" ||
      selectedOption === "Specific Yield Vs Irradiation" ||
      selectedOption === "";

    return (
      <>
        <div
          style={{
            marginLeft: "2%",
            marginRight: "5%",
            marginTop: "5%",
          }}
        >
          {/* <Typography
            style={{
              fontSize: '1rem',
              '@media (max-width: 600px)': {
                fontSize: '0.875rem',
              },
            }}
          >
            Metric Points
          </Typography> */}
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "23vh",
              padding: "8px",
              borderRadius: "5px",
              "@media (max-width: 600px)": {
                width: "100%",
                marginLeft: "0%",
              },
            }}
          >
            <List
              style={{
                paddingTop: 0,
                "@media (max-width: 600px)": {
                  paddingTop: "5px",
                },
              }}
            >
              {chartType.map((option) => (
                <ListItem
                  key={option.label}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions === option.label}
                        onChange={(event) =>
                          handleChartCheckboxChange(event, option.label)
                        }
                        disabled={!site}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                          cursor: "default",
                          visibility: "hidden",
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "12rem",
                            marginRight: 35,
                            cursor: "pointer",
                            fontSize: "14px",
                            color:
                              selectedOptions === option.label
                                ? "#1976d2"
                                : "inherit",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                          }}
                        >
                          {option.label}
                        </span>
                      </Box>
                    }
                    sx={{
                      fontSize: 15,
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>

        <div
          style={{
            marginLeft: "2%",
            marginRight: "5%",
            marginTop: "8%",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              letterSpacing: "0.00938em",
              fontWeight: 400,
              color: isDisabled ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.87)",
              "@media (max-width: 600px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            Equipments
          </Typography>
          <Box
            sx={{
              padding: "8px",
              marginLeft: "10%",
              height: "18vh",
              overflowY: isDisabled ? "hidden" : "auto",
              overflowX: "hidden",
              // border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: "5px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <List
              style={{
                paddingTop: 0,
                "@media (max-width: 600px)": {
                  paddingTop: "5px",
                },
              }}
            >
              {equipmentData.map((name) => (
                <ListItem
                  key={name}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          isDisabled
                            ? false
                            : typeof selectedEquipments === "string"
                            ? selectedEquipments === name
                            : selectedEquipments?.includes(name)
                        }
                        onChange={(event) => handleCheckboxChange(event, name)}
                        disabled={isDisabled}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                          cursor: "default",
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "12rem",
                            marginRight: 35,
                            cursor: "pointer",
                            fontSize: "14px",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                          }}
                        >
                          {name}
                        </span>
                      </Box>
                    }
                    sx={{
                      fontSize: 15,
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      </>
    );
  };

  const analyticsEquipment = () => {
    // Group the equipment by category

    const groupedByCategory = groupByCategory(equpAndParams);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          padding: 10,
        }}
      >
        <div style={{ width: "95%", textAlign: "left" }}>
          {/* <Typography>Components</Typography> */}
        </div>
        <div
          style={{
            // border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: "5px",
            width: "95%",
            marginTop: "2%",
          }}
        >
          <div
            style={{
              // marginLeft: '6%',
              marginTop: "5%",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              height: "48vh",
              flexDirection: "column",
            }}
          >
            {componentLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100%",
                }}
              >
                <FadeLoader color="#000440" loading={true} />
              </div>
            ) : equpAndParams.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                <typography>No configuration found</typography>
              </div>
            ) : (
              Object.entries(groupedByCategory)
                .sort()
                .map(([category, equipmentList]) => (
                  <div key={category} style={{ marginBottom: "10px" }}>
                    {/* Add marginBottom for spacing */}
                    <div
                      onClick={() => toggleCategoryListOpen(category)}
                      className="categoryItem"
                    >
                      {openCategoryLists[category] ? (
                        <BookmarkOutlinedIcon sx={{ fontSize: 18 }} />
                      ) : (
                        <BookmarkBorderIcon
                          sx={{ color: "rgb(113, 118, 111)", fontSize: 18 }}
                        />
                      )}
                      <Typography
                        style={{
                          marginRight: "8px",
                          width: "12rem",
                          cursor: "pointer",
                          fontSize: "14px",
                          "@media (max-width: 600px)": {
                            width: "100%",
                            marginRight: "0",
                            textAlign: "center",
                          },
                          userSelect: "none",
                        }}
                      >
                        {category}
                      </Typography>
                    </div>
                    {openCategoryLists[category] && (
                      <List
                        sx={{
                          marginLeft: "4px",
                          borderLeft: "1px solid gray",
                          paddingTop: "0px",
                        }}
                      >
                        {equipmentList
                          .sort((a, b) => {
                            if (a.equ?.includes("-") && b.equ?.includes("-")) {
                              return (
                                +a.equ.split("-").pop() -
                                +b.equ.split("-").pop()
                              );
                            } else if (
                              a.equ?.includes("_") &&
                              b.equ?.includes("_")
                            ) {
                              return (
                                +a.equ.split("_").pop() -
                                +b.equ.split("_").pop()
                              );
                            }
                            return a.equ.localeCompare(b.equ);
                          })
                          .map(({ equ, params }) => (
                            <div key={equ} className="equipmentItem">
                              <FormControlLabel
                                className="equipmentLabel"
                                control={
                                  <Checkbox
                                    checked={
                                      checkedItems[equ]?.checked || false
                                    }
                                    onChange={handleCheckboxChange(equ)}
                                    onClick={() => toggleEquipmentListOpen(equ)}
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 14 },
                                      cursor: "default",
                                      visibility: "hidden",
                                      marginLeft: "-8%",
                                    }}
                                  />
                                }
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    {openEquipmentLists[equ] ? (
                                      <BookmarkOutlinedIcon
                                        sx={{ fontSize: 18 }}
                                      />
                                    ) : (
                                      <BookmarkBorderIcon
                                        sx={{
                                          color: "rgb(113, 118, 111)",
                                          fontSize: 18,
                                        }}
                                      />
                                    )}
                                    <span
                                      style={{
                                        marginRight: "8px",
                                        width: "12rem",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        color: checkedItems[equ]?.checked
                                          ? "#1976d2"
                                          : "inherit",
                                        "@media (max-width: 600px)": {
                                          width: "100%",
                                          marginRight: "0",
                                          textAlign: "center",
                                        },
                                      }}
                                    >
                                      {equ}
                                    </span>
                                  </Box>
                                }
                                sx={{ fontSize: 14 }}
                              />

                              {openEquipmentLists[equ] && (
                                <List
                                  sx={{
                                    marginLeft: "4px",
                                    borderLeft: "1px solid gray",
                                  }}
                                >
                                  {params.map((param) => (
                                    <ListItemButton
                                      key={param}
                                      onClick={handleParamToggle(equ, param)}
                                      sx={{
                                        fontSize: 11,
                                        padding: "0 0 0 8px",
                                        "& .MuiListItemText-root": {
                                          fontSize: "12px",
                                        },
                                      }}
                                    >
                                      <Radio
                                        edge="start"
                                        checked={
                                          checkedItems[equ]?.params?.indexOf(
                                            param
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 12,
                                          },
                                        }}
                                      />
                                      <ListItemText
                                        primary={
                                          <div style={{ fontSize: "12px" }}>
                                            {param}
                                          </div>
                                        }
                                      />
                                    </ListItemButton>
                                  ))}
                                </List>
                              )}
                            </div>
                          ))}
                      </List>
                    )}
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Paper
          elevation={0}
          style={{
            width: "99%",
            borderRadius: "2px 2px 0px 0px",
            userSelect: "none",
            height: "5vh",
            display: "flex",
            alignItems: "center",
            // marginLeft: '2.5%',
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <CustomBreadcrumbs
                paths={paths || []}
                separatorSize="18px"
                fontSize="14px"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <div className="monitoring-graph" style={{ flexDirection: "row" }}>
        <div style={{ margin: "0px 0px 10px 0px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              marginTop: "10px",
              backgroundColor: "background.paper",
              "& > :not(style)": {
                m: 1,
                width: "20vw",
                minHeight: "70vh",
                height: "fit-content",
              },
              // overflowY: "scroll",
            }}
          >
            <Paper elevation={0} height="100%">
              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // marginLeft: '7%',
                    // marginRight: '5%',
                    marginBottom: "5%",
                  }}
                >
                  <Typography>Site</Typography>
                  <div>
                    <Tooltip
                      title={site}
                      disableFocusListener
                      enterDelay={200}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                      arrow
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        value={site}
                        disabled={params.name}
                        options={siteIdName.map((option) => option.siteName)}
                        onChange={(data, event) => handleSite(data, event)}
                        sx={{ width: "18vw" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Site ..."
                          />
                        )}
                        classes={{ option: "autocomplete" }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Tabs value={panelIndex} onChange={handleChangePanel}>
                  <Tab
                    label="Key Metrics"
                    key={1}
                    value="1"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      backgroundColor:
                        panelIndex === "1" ? "#1976d229" : "transparent",
                      transition: "background-color 0.3s ease-in-out", // Added transition effect
                    }}
                  />
                  <Tab
                    label="Equipments"
                    key={2}
                    value="2"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      backgroundColor:
                        panelIndex === "2" ? "#1976d229" : "transparent",
                      transition: "background-color 0.3s ease-in-out", // Added transition effect
                    }}
                  />
                </Tabs>
              </Box>

              {panelIndex === "1" ? (
                <div>{analyticsMetrics()}</div>
              ) : (
                <div
                  style={{
                    overflowY: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {analyticsEquipment()}
                </div>
              )}
            </Paper>
          </Box>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ marginLeft: "1%", width: "70vw" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "13vh",
                },
              }}
            >
              <Paper elevation={0} style={{ height: "100%" }}>
                <div style={{ width: "auto", padding: "10px" }}>
                  <div>
                    <div className="graph">
                      <div>
                        <div style={{ marginTop: 10 }}>
                          <Stack spacing={1} direction="row">
                            <div>
                              {/* <Typography
                                style={{
                                  ...typoStyle,
                                  color: CheckForLight() ? 'black' : 'white',
                                }}
                              >
                                Based on
                              </Typography> */}
                              {/* <Autocomplete
                                disablePortal
                                disableClearable
                                size="small"
                                width={'5vw'}
                                disabled={
                                  chartName !== 'Specific Yield Vs Irradiation'
                                }
                                options={basedOnOptions}
                                value={basedOn}
                                onChange={(event, data) => {
                                  hadleBasedOn(data);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              /> */}
                            </div>
                            <div className="monitor-text">
                              <Typography
                                style={{
                                  ...typoStyle,
                                  color: CheckForLight() ? "black" : "white",
                                }}
                              >
                                Range
                              </Typography>
                              <div>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  borderRadius="4px"
                                  sx={{ width: "28vw" }}
                                  justifyContent="space-between"
                                  p="0"
                                  style={{
                                    border: CheckForLight()
                                      ? "1px solid #c4c4c4"
                                      : "1px solid #494949",
                                  }}
                                >
                                  <Tooltip title="Based on">
                                    <IconButton
                                      onClick={(event) =>
                                        handledailyMenuItem(event, "basedon")
                                      }
                                      disabled={
                                        chartName ===
                                        "Specific Yield Vs Irradiation"
                                          ? false
                                          : true
                                      }
                                    >
                                      {iconForBasedOn}
                                    </IconButton>
                                  </Tooltip>
                                  <Menu
                                    anchorEl={basedOnAnchor}
                                    open={openBasedOn}
                                    onClose={handleCloseBasedOn}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {basedOnOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          hadleBasedOn(option)
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <Tooltip title="Previous">
                                    <IconButton
                                      disabled={timevalue === "custom"}
                                      onClick={() => handleChangeDate("left")}
                                    >
                                      <ChevronLeftIcon
                                        sx={{
                                          color:
                                            timevalue === "custom"
                                              ? "rgb(113, 118, 111)"
                                              : CheckForLight()
                                              ? "black"
                                              : "white",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                  <Stack
                                    direction="row"
                                    style={{
                                      cursor: "pointer",
                                      flex: "1",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      mt="1px"
                                      ml="2.5px"
                                      style={{
                                        color: CheckForLight()
                                          ? "black"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                      }}
                                    >
                                      {RangeText}
                                    </Typography>
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    disabled={
                                      moment(date).isSame(
                                        moment().startOf("day")
                                      ) || timevalue === "custom"
                                    }
                                    onClick={() => handleChangeDate("right")}
                                  >
                                    <ChevronRightIcon
                                      sx={{
                                        color:
                                          moment(date).isSame(
                                            moment().startOf("day")
                                          ) || timevalue === "custom"
                                            ? "rgb(113, 118, 111)"
                                            : CheckForLight()
                                            ? "black"
                                            : "white",
                                      }}
                                    />
                                  </IconButton>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickMenuItem(event, "Timer")
                                    }
                                    disabled={chartName !== "Daily Generation"}
                                    sx={{
                                      width: "40px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={timerValue}
                                      color="default"
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                    >
                                      <TimerOutlinedIcon
                                        sx={{
                                          color: CheckForLight()
                                            ? "black"
                                            : "white",
                                          opacity:
                                            chartName !== "Daily Generation"
                                              ? 0.5
                                              : 1,
                                          pointerEvents:
                                            chartName !== "Daily Generation"
                                              ? "none"
                                              : "auto",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </Badge>
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorTimerEl}
                                    open={Boolean(anchorTimerEl)}
                                    onClose={handleCloseTimer}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {timerOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={() =>
                                          handleMenuItemClick(
                                            option.value,
                                            "Timer"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handledailyMenuItem(event, "dailychart")
                                    }
                                    disabled={
                                      (chartName === "Daily Generation" &&
                                        rangeLevel !== "yearly") ||
                                      (chartName === "Specific Yield" &&
                                        timevalue !== "Daily") ||
                                      chartName ===
                                        "Specific Yield Vs Irradiation"
                                        ? false
                                        : true
                                    }
                                  >
                                    <BarChartOutlinedIcon
                                      style={{
                                        color: CheckForLight()
                                          ? "black"
                                          : "white",
                                        opacity:
                                          (chartName === "Daily Generation" &&
                                            rangeLevel !== "yearly") ||
                                          (chartName === "Specific Yield" &&
                                            timevalue !== "Daily") ||
                                          chartName ===
                                            "Specific Yield Vs Irradiation"
                                            ? 1
                                            : 0.5,
                                        pointerEvents:
                                          (chartName === "Daily Generation" &&
                                            rangeLevel !== "yearly") ||
                                          (chartName === "Specific Yield" &&
                                            timevalue !== "Daily") ||
                                          chartName ===
                                            "Specific Yield Vs Irradiation"
                                            ? "auto"
                                            : "none",
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    anchorEl={chartAnchor}
                                    open={menuOpenn}
                                    onClose={handleCloseeMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {dailychartType.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "dailychart"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickMenuItem(event, "Time")
                                    }
                                  >
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        color: CheckForLight()
                                          ? "black"
                                          : "white",
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {optionTime.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "Time"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Box>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "fit-content",
                                marginTop: "23px",
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                borderRadius="4px"
                                sx={{ width: "fit-content", height: "43px" }}
                                justifyContent="space-between"
                                p="0"
                                style={{
                                  border: CheckForLight()
                                    ? "1px solid #c4c4c4"
                                    : "1px solid #494949",
                                }}
                              >
                                <IconButton
                                  disabled={timevalue !== "custom"}
                                  onClick={(e) =>
                                    openCalender(e, "fromCalender")
                                  }
                                >
                                  <CalendarMonthOutlinedIcon
                                    sx={{
                                      color:
                                        timevalue !== "custom"
                                          ? "rgb(113, 118, 111)"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                    }}
                                  />
                                </IconButton>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    From:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(FromDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    // width: '12vw',
                                    marginRight: "10px",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    To:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(ToDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                              </Box>
                              <div>
                                <Popover
                                  id={calenderId}
                                  open={isCalendarOpen}
                                  anchorEl={calenderAnchor}
                                  onClose={closeCalender}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  sx={{ mt: 1 }}
                                >
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) => handleTimeChange(item)}
                                    months={2}
                                    ranges={RangeState}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    dateDisplayFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    direction="horizontal"
                                  />
                                </Popover>
                              </div>
                            </div>

                            <div
                              style={{
                                marginTop: "2%",
                                marginLeft: "1%",
                                marginRight: "1%",
                                display: "flex",
                                // justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                  marginTop: "4%",
                                  fontSize: "1rem",
                                }}
                                onClick={() => {
                                  handleApplyChanges(
                                    null,
                                    timevalue === "custom" ? true : false,
                                    "disableReset"
                                  );
                                }}
                                startIcon={<DownloadDoneOutlinedIcon />}
                                disabled={
                                  (chartName === "Energy Performance" &&
                                    site !== "" &&
                                    selectedEquipments.length > 0) ||
                                  (chartName === "Parameter Comparision" &&
                                    site !== "" &&
                                    !optionalCharts &&
                                    ((Object.keys(checkedItems).length > 0 &&
                                      Object.values(checkedItems).some(
                                        (x) => x.checked
                                      )) ||
                                      selectedEquipments !== "")) ||
                                  ((chartName === "Daily Generation" ||
                                    chartName === "Specific Yield" ||
                                    chartName === "Specific Yield - Heatmap" ||
                                    chartName === "Actual Vs Expected" ||
                                    chartName === "DG PV Grid Management" ||
                                    chartName === "Deemed Generation" ||
                                    chartName === "Set Point" ||
                                    optionalCharts) &&
                                    site !== "" &&
                                    timevalue === "custom")
                                    ? false
                                    : true
                                }
                              >
                                Apply
                              </Button>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    <div>
                      <div>
                        {loading ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "60vh",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                height: "60vh",
                                overflowX: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  //  marginTop: "15%",
                                }}
                              >
                                <FadeLoader color="#000440" loading={true} />
                              </div>
                            </CardContent>
                          </Card>
                        ) : datalog?.length > 0 ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "10%",
                              overflowX: "scroll",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              {chartName === "Parameter Comparision" ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  multiChartData={multiChartData}
                                  name={"paramter"}
                                  timeValue={timevalue}
                                  axisData={axisData}
                                  widgetName={`${chartName}-${site}`}
                                  loggerMap={equpParamMap}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName ===
                                "Specific Yield Vs Irradiation" ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={
                                    fromApply ? dataModel : customView
                                  }
                                  dailyChartType={dailyChartType}
                                  WidgetName={`${chartName}-${site}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  basedOn={basedOn}
                                  from={"Vanalytics"}
                                />
                              ) : (chartName === "Specific Yield" &&
                                  (dataModel.range === "custom" ||
                                    timevalue === "Daily")) ||
                                chartName === "Actual Vs Expected" ? (
                                <CombinedChart
                                  tableData={tableData}
                                  customView={
                                    fromApply ? dataModel : customView
                                  }
                                  WidgetName={`${chartName}-${site}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "Specific Yield - Heatmap" ? (
                                <HeatmapChart
                                  tableData={tableData}
                                  customView={customView}
                                  WidgetName={`${chartName}-${site}`}
                                  chartName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName === "DG PV Grid Management" &&
                                chartFromDate === chartToDate ? (
                                <AreaChart
                                  tableData={tableData}
                                  chartName={`${chartName}-${site}`}
                                  WidgetName={`${chartName}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : // : chartName === "Deemed Generation" ? (
                              //   <Apexchart
                              //     chartName={chartName}
                              //     tableData={tableData}
                              //     timeValue={timevalue}
                              //     multiChartData={multiChartData}
                              //     axisData={axisData}
                              //     key="1"
                              //     name="chartData"
                              //     widgetName={`${chartName}-${site}`}
                              //     fallBack={fallBack}
                              //     from={"Vanalytics"}
                              //   />
                              // )
                              chartName === "Deemed Generation" &&
                                chartFromDate !== chartToDate ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  customView={dataModel}
                                  barchartdata={barchartdata}
                                  widgetName={`${chartName}-${site}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : (chartFromDate === chartToDate &&
                                  chartName !== "Daily Generation") ||
                                chartName === "Deemed Generation" ||
                                chartName === "Set Point" ||
                                (chartName === "Daily Generation" &&
                                  dataModel.range === "daily") ||
                                (chartName === "Specific Yield" &&
                                  timevalue !== "Daily" &&
                                  dailyChartType === "daily") ? (
                                <Apexchart
                                  chartName={chartName}
                                  tableData={tableData}
                                  timeValue={timevalue}
                                  multiChartData={multiChartData}
                                  axisData={axisData}
                                  key="1"
                                  name="chartData"
                                  widgetName={`${chartName}-${site}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : chartName !== "Deemed Generation" ||
                                chartName === "Daily Generation" ? (
                                <BarChart
                                  tableData={tableData}
                                  chartName={chartName}
                                  customView={dataModel}
                                  barchartdata={barchartdata}
                                  widgetName={`${chartName}-${site}`}
                                  fallBack={fallBack}
                                  from={"Vanalytics"}
                                />
                              ) : (
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "50vh",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "1.5rem",
                                      textTransform: "capitalize",
                                      lineHeight: "14.52px !important",
                                      color: " rgb(113, 118, 111)",
                                    }}
                                  >
                                    No data found in the database
                                  </Typography>
                                </CardContent>
                              )}
                            </CardContent>
                          </Card>
                        ) : (
                          fallBack
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
